import { StyleConfig, MessageRecord } from '../../source';
import Feature from 'ol/Feature';
import { TextPlacement } from 'ol/style/Text';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { labelFont } from '.';
import { fromRecord } from '../../locale';
import RenderFeature from 'ol/render/Feature';
import Geometry from 'ol/geom/Geometry';

export const getLabelStyleFn = (
    config: StyleConfig,
    placement = 'point' as TextPlacement
) => {
    let labelStyle: Text;
    let propName: MessageRecord;
    let resLimit: number;

    if (config.label) {
        const label = config.label;
        propName = label.propName;
        resLimit = label.resLimit;
        labelStyle = new Text({
            placement,
            overflow: true,
            font: labelFont(label.size),
            textAlign: label.align,
            textBaseline: label.baseline,
            offsetX: label.xOffset,
            offsetY: label.yOffset,
            fill: new Fill({
                color: label.color,
            }),
            stroke: new Stroke({
                width: 1,
                color: 'white',
            }),
        });
    }

    return (
        feature: Feature<Geometry> | RenderFeature,
        zoom: number, // it used to be a resolution but we changed it all to zooms... (nw)
        styles: Style[]
    ): Style[] => {
        if (resLimit && zoom > resLimit && labelStyle && propName) {
            const props = feature.getProperties();
            const baseText: string = props[fromRecord(propName)];
            if (baseText) {
                const text = baseText
                    .toString()
                    .trim()
                    .split(' ')
                    .reduce((acc, w, idx) => {
                        if (idx % 2 > 0) {
                            return `${acc} ${w}`;
                        }
                        return `${acc}\n${w}`;
                    }, '');
                labelStyle.setText(text);
                return styles.concat(new Style({ text: labelStyle }));
            }
        }
        return styles;
    };
};
