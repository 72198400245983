/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import tr, { fromRecord } from 'sdi/locale';
import { A, BUTTON, DIV, H2, H3 } from 'sdi/components/elements';
import { IMapInfo } from 'sdi/source';
import { uniqId } from 'sdi/util';

import { getMapInfoOption } from '../../queries/app';
import { setLayout } from '../../events/app';
import { setPrintRequest } from '../../events/map';
import { PrintProps } from '../print';
import { applySpec, getResolution } from '../print/template';
import renderCustom from '../print/custom';
import { AppLayout } from '../../shape/types';
import { getAppUrl } from 'sdi/app';

const logger = debug('sdi:tool-print');

const renderButton = (label: string, props: PrintProps) =>
    BUTTON(
        {
            className: props.orientation,
            'aria-label': `${props.orientation}: ${label}`,
            onClick: () => {
                const resolution = getResolution(props.template);
                applySpec(props.template)('map', spec => spec.rect).map(
                    ({ width, height }) => {
                        setLayout(AppLayout.Print);
                        const id = uniqId();
                        setPrintRequest({
                            id,
                            width,
                            height,
                            resolution,
                            props,
                        });
                    }
                );
            },
        },
        label
    );

const choiceA4 = () =>
    DIV(
        'print-format',
        renderButton('A4', {
            template: 'a4/landscape',
            format: 'a4',
            orientation: 'landscape',
        }),
        renderButton('A4', {
            template: 'a4/portrait',
            format: 'a4',
            orientation: 'portrait',
        })
    );
const choiceA3 = () =>
    DIV(
        'print-format',
        renderButton('A3', {
            template: 'a3/landscape',
            format: 'a3',
            orientation: 'landscape',
        }),
        renderButton('A3', {
            template: 'a3/portrait',
            format: 'a3',
            orientation: 'portrait',
        })
    );

const choiceA0 = () =>
    DIV(
        'print-format',
        renderButton('A0', {
            template: 'a0/landscape',
            format: 'a0',
            orientation: 'landscape',
        }),
        renderButton('A0', {
            template: 'a0/portrait',
            format: 'a0',
            orientation: 'portrait',
        })
    );

const renderUrl = (name: string) =>
    getAppUrl(name).map(url =>
        DIV(
            'helptext print-info',
            tr.view('helptext:printWarning'),
            A(
                { className: 'contact-link', href: fromRecord(url.url) },
                fromRecord(url.label)
            )
        )
    );

const renderBody = (mapInfo: IMapInfo) =>
    DIV(
        'tool-body',
        renderCustom(mapInfo),
        DIV(
            'print-block',
            DIV('a4', H3({}, tr.view('printSmallFormat')), choiceA4()),
            DIV('a3', H3({}, tr.view('printMediumFormat')), choiceA3()),
            DIV('a0', H3({}, tr.view('printBigFormat')), choiceA0())
        ),
        renderUrl('contact')
    );

const render = () =>
    getMapInfoOption().fold(DIV({}, 'Print: Somethin missing'), mapInfo =>
        DIV(
            'sidebar-main',
            DIV(
                'tool print',
                H2({}, tr.view('printMap')),
                DIV({}, tr.view('helptext:printMapTool')),
                renderBody(mapInfo)
            )
        )
    );

export default render;

logger('loaded');
