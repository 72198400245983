/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as proj4 from 'proj4';
import * as format from 'ol/format';
import { Option, some, none } from 'fp-ts/lib/Option';
import Projection from 'ol/proj/Projection';
import RenderFeature from 'ol/render/Feature';
import { addProjection } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { Layer } from 'ol/layer';
// import { FilterFunction } from 'ol/interaction/Select';
import { Either } from 'fp-ts/lib/Either';
import Style from 'ol/style/Style';
import { FeatureLike } from 'ol/Feature';
import Source from 'ol/source/Source';
import LayerRenderer from 'ol/renderer/Layer';

import {
    IMapBaseLayer,
    IMapInfo,
    FeatureCollection,
    GeometryType,
    Feature,
    DirectGeometryObject,
    MessageRecord,
    ILayerInfo,
} from '../source';
import { Getter, Setter } from '../shape';
import { Undefined } from '../util';
import { fst, Pair, pair, snd } from '../lib';

export const formatGeoJSON = new format.GeoJSON();

proj4.defs(
    'EPSG:31370',
    '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,-0.3366,0.457,-1.8422,-1.2747 +units=m +no_defs'
);
// proj4.defs('EPSG:31370',
// '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs');
register(proj4); // This have to be after proj4.defs -
export const EPSG31370 = new Projection({
    code: 'EPSG:31370',
    extent: [14697.3, 22635.8, 291071.84, 246456.18],
});

addProjection(EPSG31370);

export type FilterFunction = (
    f: FeatureLike,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layer: Layer<Source, LayerRenderer<any>>
) => boolean;

export const toLonLat = (geom: DirectGeometryObject) => {
    const { forward } = proj4('EPSG:31370', 'EPSG:4326');
    switch (geom.type) {
        case 'Point':
            return {
                ...geom,
                coordinates: forward(geom.coordinates),
            };
        case 'LineString':
            return {
                ...geom,
                coordinates: geom.coordinates.map(c => forward(c)),
            };
        case 'Polygon':
            return {
                ...geom,
                coordinates: geom.coordinates.map(ring =>
                    ring.map(c => forward(c))
                ),
            };
        case 'MultiPoint':
            return {
                ...geom,
                coordinates: geom.coordinates.map(c => forward(c)),
            };
        case 'MultiLineString':
            return {
                ...geom,
                coordinates: geom.coordinates.map(line =>
                    line.map(c => forward(c))
                ),
            };
        case 'MultiPolygon':
            return {
                ...geom,
                coordinates: geom.coordinates.map(poly =>
                    poly.map(ring => ring.map(c => forward(c)))
                ),
            };
    }
};

export type Coord2D = [number, number];
export const tryCoord2D = (c: Coordinate | undefined): Option<Coord2D> => {
    if (c !== undefined && c.length > 1) {
        return some([c[0], c[1]]);
    }
    return none;
};

// export type FeatureCollectionOrNull = FeatureCollection | null;
export type FetchData = () => Either<string, Option<FeatureCollection>>;
export type SetScaleLine = (count: number, unit: string, width: number) => void;

export interface IMapScale {
    count: number;
    unit: string;
    width: number;
}

export type ViewDirt = 'none' | 'geo' | 'geo/feature' | 'geo/extent' | 'style';

export interface IMapViewData {
    dirty: ViewDirt;
    srs: string;
    center: Coordinate;
    rotation: number;
    zoom: number;
    feature: Feature | null;
    extent: Extent | null;
}

export interface IGeoSelect {
    selected: string | null;
}

export interface TrackerCoordinate {
    coord: Coordinate;
    accuracy: number;
}

export interface IGeoTracker {
    track: TrackerCoordinate[];
}

export interface TrackerOptions {
    updateTrack(t: TrackerCoordinate): void;
    resetTrack(): void;
    setCenter(c: Coordinate): void;
}

export type IGeoMeasureType = 'Polygon' | 'LineString';

export interface IGeoMeasure {
    geometryType: IGeoMeasureType;
    coordinates: Coordinate[];
}

export interface MeasureOptions {
    updateMeasureCoordinates(c: Coordinate[]): void;
    stopMeasuring(): void;
}

export interface ExtractFeature {
    layerId: string;
    featureId: string | number;
}

export interface ExtractOptions {
    setCollection(c: ExtractFeature[]): void;
}

export interface PositionOptions {
    setPosition(p: Coordinate): void;
    stopPosition(p: Option<Coordinate>): void;
}

export interface SingleClickOptions {
    setPosition(p: Coordinate, isActive?: boolean): void;
}

export interface IMark {
    started: boolean;
    endTime: number;
    coordinates: Coordinate;
}

export interface MarkOptions {
    endMark(): void;
    startMark(): void;
}

export const defaultMark = (): IMark => ({
    started: false,
    endTime: 0,
    coordinates: [0, 0],
});

export type MapEditableMode = 'none' | 'select' | 'create' | 'modify';
export type MapEditableSelected = string | number | null;

export interface IGeoCreate {
    geometryType: GeometryType;
}

export interface IGeoModify {
    selected: MapEditableSelected;
    geometryType: GeometryType;
    centerOnSelected: boolean;
}

export interface FeaturePath {
    layerId: string | null;
    featureId: number | string | null;
}

export interface FeaturePathInstance {
    layer: ILayerInfo;
    feature: Feature;
}

export type FeaturePathGetter = Getter<FeaturePath>;

export const getFeaturePath = ({ layerId, featureId }: FeaturePath) => {
    if (layerId !== null && featureId !== null) {
        return some({ layerId, featureId });
    }
    return none;
};

export const nullFeaturePath = (): FeaturePath => ({
    layerId: null,
    featureId: null,
});

export interface IPosition {
    coordinates: Coordinate;
    after: (c: Coordinate) => void;
}

export interface InteractionBase<L extends string, T> {
    label: L;
    state: T;
}

// export type InteractionSelect = InteractionBase<'select', IGeoSelect>;
// export type InteractionCreate = InteractionBase<'create', IGeoCreate>;
// export type InteractionModify = InteractionBase<'modify', IGeoModify>;
// export type InteractionTrack = InteractionBase<'track', IGeoTracker>;
// export type InteractionMeasure = InteractionBase<'measure', IGeoMeasure>;
// export type InteractionExtract = InteractionBase<'extract', ExtractFeature[]>;
// export type InteractionMark = InteractionBase<'mark', IMark>;
// export type InteractionPrint = InteractionBase<'print', null>;
// export type InteractionPosition = InteractionBase<'position', IPosition>;
// export type InteractionSingleClick = InteractionBase<'singleclick', null>;

export interface InteractionSelect {
    label: 'select';
    state: IGeoSelect;
}
export interface InteractionEnter {
    label: 'enter';
    state: IGeoSelect;
}
export interface InteractionCreate {
    label: 'create';
    state: IGeoCreate;
}
export interface InteractionModify {
    label: 'modify';
    state: IGeoModify;
}
export interface InteractionTrack {
    label: 'track';
    state: IGeoTracker;
}
export interface InteractionMeasure {
    label: 'measure';
    state: IGeoMeasure;
}
export interface InteractionExtract {
    label: 'extract';
    state: ExtractFeature[];
}
export interface InteractionMark {
    label: 'mark';
    state: IMark;
}
export interface InteractionPrint {
    label: 'print';
    state: null;
}
export interface InteractionPosition {
    label: 'position';
    state: IPosition;
}
export interface InteractionSingleClick {
    label: 'singleclick';
    state: null;
}

interface InteractionMap {
    select: InteractionSelect;
    enter: InteractionEnter;
    create: InteractionCreate;
    modify: InteractionModify;
    track: InteractionTrack;
    measure: InteractionMeasure;
    extract: InteractionExtract;
    mark: InteractionMark;
    print: InteractionPrint;
    position: InteractionPosition;
    singleclick: InteractionSingleClick;
}

export type InteractionLabel = keyof InteractionMap;
export type Interaction = InteractionMap[InteractionLabel];

export const defaultInteraction = (): Interaction => ({
    label: 'select',
    state: { selected: null },
});

export const singleClickInteraction = (): Interaction => ({
    label: 'singleclick',
    state: null,
});

export type InteractionGetter = Getter<Interaction>;
export type InteractionSetter = Setter<Interaction>;

const noop: () => void = () => void 0;

/**
 *
 * withInteraction('select', (i) => do(i), () => doNothing());
 *
 */
export const withInteraction =
    <T extends Interaction>(
        label: InteractionLabel,
        w: (i: T) => void,
        wo = noop
    ) =>
    (i: Interaction) => {
        switch (i.label) {
            case 'select':
                label === 'select' ? w(i as T) : wo();
                break;
            case 'enter':
                label === 'enter' ? w(i as T) : wo();
                break;
            case 'create':
                label === 'create' ? w(i as T) : wo();
                break;
            case 'modify':
                label === 'modify' ? w(i as T) : wo();
                break;
            case 'track':
                label === 'track' ? w(i as T) : wo();
                break;
            case 'measure':
                label === 'measure' ? w(i as T) : wo();
                break;
            case 'extract':
                label === 'extract' ? w(i as T) : wo();
                break;
            case 'mark':
                label === 'mark' ? w(i as T) : wo();
                break;
            case 'print':
                label === 'print' ? w(i as T) : wo();
                break;
            case 'position':
                label === 'position' ? w(i as T) : wo();
                break;
            case 'singleclick':
                label === 'singleclick' ? w(i as T) : wo();
                break;
        }
    };

export interface EditOptions {
    getCurrentLayerId(): string;
    getGeometryType(lid: string): GeometryType;
    getStyle?: (feature: RenderFeature) => Style | Style[];
    getSnapLayer?: () => string;

    // editFeature(fid: string | number): void;
    addFeature(f: Feature): void; // create
    setGeometry(geom: DirectGeometryObject): void; // modify
}

export interface SingleSelectOptions {
    readonly tag: 'single';
    selectFeature(lid: string, id: string | number): void;
    clearSelection(): void;
    getSelected: FeaturePathGetter;
    filter?: FilterFunction;
}

export const singleSelectOptions = (
    options: Pick<
        SingleSelectOptions,
        'getSelected' | 'selectFeature' | 'clearSelection' | 'filter'
    >
): SingleSelectOptions => ({
    ...options,
    tag: 'single',
});

export interface MultiSelectOptions {
    readonly tag: 'multi';
    getSelected: () => FeaturePath[] | Readonly<FeaturePath[]>;
    selectFeatures: (ps: FeaturePath[]) => void;
    filter?: FilterFunction;
}

export const multiSelectOptions = (
    options: Pick<
        MultiSelectOptions,
        'getSelected' | 'selectFeatures' | 'filter'
    >
): MultiSelectOptions => ({
    ...options,
    tag: 'multi',
});

export type SelectOptions = SingleSelectOptions | MultiSelectOptions;

export interface IMapOptions {
    element: HTMLElement | null;
    getBaseLayer(): Undefined<IMapBaseLayer>;
    getView(): IMapViewData;
    getMapInfo(): IMapInfo | null;

    updateView(v: IViewEvent): void;
    setScaleLine: SetScaleLine;
    setLoading?: (ms: MessageRecord[]) => void;
}

export interface IViewEvent {
    dirty?: ViewDirt;
    center?: Coordinate;
    rotation?: number;
    zoom?: number;
    feature?: Feature;
    extent?: Extent;
}

export interface PrintRequest<T> {
    id: string | null;
    width: number;
    height: number;
    resolution: number;
    props: T | null;
}
export const defaultPrintRequest = (): PrintRequest<null> => ({
    id: null,
    width: 0,
    height: 0,
    resolution: 0,
    props: null,
});

export type PrintResponseStatus = 'none' | 'start' | 'end' | 'error' | 'done';

export interface PrintResponse<T> {
    id: string | null;
    status: PrintResponseStatus;
    data: string;
    extent: [number, number, number, number];
    props: T | null;
}
export const defaultPrintResponse = (): PrintResponse<null> => ({
    id: null,
    data: '',
    extent: [0, 0, 0, 0],
    status: 'none',
    props: null,
});

export interface PrintOptions<T> {
    getRequest(): PrintRequest<T>;
    setResponse(r: PrintResponse<T>): void;
}

// round to 2 significant digit
const roundTo2 = (num: number) => {
    const significant_nb = -Math.floor(Math.log10(Math.abs(num))) + 1;
    const d = Math.pow(10, significant_nb);
    return Math.round(num * d) / d;
};

/*
 * scale: denominator of the ratio (ex: '10 000' if scale is '1:10 000')
 * calculations come from https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Resolution_and_Scale
 * In Lambert 72, the first tile (at zoom zéro) is 268.40708 km in a tile of 256px ->  1048.4652 m/px
 * 39.37 is the number of inches per meters
 * ppi set at 96 px/in
 */
// const scaleToZoom = (scale: number) => {
//     const pixelRatio = window.devicePixelRatio;
//     const ppi = 96 * pixelRatio;
//     return Math.log2(1048.4652 * ppi * 39.37 / scale);
// }
export const zoomToScale = (z: number) => {
    // const z = parseInt(zoom);
    // const pixelRatio = window.devicePixelRatio;
    const pixelRatio = 1;
    const ppi = 96 * pixelRatio;
    const scale = (ppi * 39.37 * 1048.4652) / Math.pow(2, z);
    return roundTo2(scale);
};
// export const calcAllScales = () =>
//     Object.keys(Svalues).map(z => zoomToScale(z))

type ZoomLevel =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30;
const zoomLevelList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];

// Calculated with commented function just above - for Lambert 72 and dpi = 96px.
type Scale =
    | 4500000 // TODO : check this value
    | 2000000
    | 1000000
    | 550000
    | 250000
    | 150000
    | 70000
    | 35000
    | 20000
    | 8500
    | 4500
    | 2000
    | 1000
    | 550
    | 300
    | 150
    | 65
    | 35
    | 15
    | 8
    | 4
    | 2
    | 1
    | 0.5
    | 0.3
    | 0.1
    | 0.05
    | 0.03
    | 0.02
    | 0.008
    | 0.004;
export const scaleList = [
    4500000, 2000000, 1000000, 550000, 250000, 150000, 70000, 35000, 20000,
    8500, 4500, 2000, 1000, 550, 300, 150, 65, 35, 15, 8, 4, 2, 1, 0.5, 0.3,
    0.1, 0.05, 0.03, 0.02, 0.008, 0.004,
];
export const reasonableScaleList = scaleList.slice(4, 16);

export const tryZoom = (zoom: number) =>
    zoomLevelList.indexOf(zoom) >= 0 ? some(zoom as ZoomLevel) : none;
export const tryScale = (scale: number) =>
    scaleList.indexOf(scale) >= 0 ? some(scale as Scale) : none;

const ScaleLambert72Values: Pair<ZoomLevel, Scale>[] = [
    pair(0, 4500000),
    pair(1, 2000000),
    pair(2, 1000000),
    pair(3, 550000),
    pair(4, 250000),
    pair(5, 150000),
    pair(6, 70000),
    pair(7, 35000),
    pair(8, 20000),
    pair(9, 8500),
    pair(10, 4500),
    pair(11, 2000),
    pair(12, 1000),
    pair(13, 550),
    pair(14, 300),
    pair(15, 150),
    pair(16, 65),
    pair(17, 35),
    pair(18, 15),
    pair(19, 8),
    pair(20, 4),
    pair(21, 2),
    pair(22, 1),
    pair(23, 0.5),
    pair(24, 0.3),
    pair(25, 0.1),
    pair(26, 0.05),
    pair(27, 0.03),
    pair(28, 0.02),
    pair(29, 0.008),
    pair(30, 0.004),
];

export const zoomToRoundScale = (z: ZoomLevel) => {
    const rs = ScaleLambert72Values.find(p => fst(p) === z);
    if (rs === undefined) {
        throw new Error("Zoom don't match with a scale");
    }
    return snd(rs);
};
export const scaleToZoom = (s: Scale) => {
    const z = ScaleLambert72Values.find(p => snd(p) === s);
    if (z === undefined) {
        throw new Error("Scale don't match with a zoom");
    }
    return fst(z);
};

// export const minScale = () => snd(ScaleLambert72Values[0]);
// export const maxScale = () => snd(ScaleLambert72Values[ScaleLambert72Values.length - 1]);

export * from './map';
export * from './events';
export * from './queries';
