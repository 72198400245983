/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import Style from 'ol/style/Style';
import * as render from 'ol/render';
// import { once } from '../../util';
import CanvasImmediateRenderer from 'ol/render/canvas/Immediate';
import { FeatureLike } from 'ol/Feature';
import { isFontLoaded, labelFontName, pictoFontName } from '../../app';

export { default as pointStyle } from './point';
export { default as lineStyle } from './line';
export { default as polygonStyle } from './polygon';

export type StyleFn = (a: FeatureLike, b: number) => Style[];

export interface IOLContext {
    canvas: HTMLCanvasElement;
    canvasContext: CanvasRenderingContext2D;
    olContext: CanvasImmediateRenderer;
}

export const getContext = (
    width: number,
    height: number
): IOLContext | null => {
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) {
        return null;
    }
    const olContext = render.toContext(canvasContext, {
        size: [height, width],
    });
    return { canvas, canvasContext, olContext };
};

// export const markerFont = (sz: number) => `${sz}px ForkAwesome`;
export const markerFont = (sz: number) => {
    if (isFontLoaded('picto')) {
        return `${sz}px ${pictoFontName}`;
    }
    return `${sz}px sans`;
};
// export const labelFont = (sz: number) => `bold ${sz}px open_sans`;
export const labelFont = (sz: number) => {
    if (isFontLoaded('label')) {
        return `${sz}px ${labelFontName}`;
    }
    return `${sz}px sans`;
};
export const fontSizeExtractRegexp = new RegExp('.*?(\\d+)px.*');
export const fontSizeReplaceRegexp = new RegExp('(.*?)\\d+px(.*)');

/**
 * Here is a little hack to ensure loading of relevant fonts.
 * The bug is marker fonts loaded too late point layer
 * is visible at application start. And after that a blank canvas
 * lies in openlayers text cache.
 *
 * we considered FontFace API but a) people at Bruxelles Environnement
 * run on old Edge with no support for it and b) Typescript pretends
 * it does not exist.
 */
// const ensureMapFonts = once(() => {
//     const doIt = () => {
//         const marker = document.createElement('div');
//         const label = document.createElement('div');
//         marker.style.font = markerFont(10);
//         label.style.font = labelFont(10);
//         marker.innerHTML = 'abc';
//         label.innerHTML = 'abc';
//         document.body.appendChild(marker);
//         document.body.appendChild(label);
//         window.setTimeout(() => {
//             document.body.removeChild(marker);
//             document.body.removeChild(label);
//         }, 1);
//     };
//     if (document.readyState === 'loading') {
//         document.addEventListener('DOMContentLoaded', doIt);
//     }
//     else {
//         doIt();
//     }
// });
// ensureMapFonts();
