/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { BUTTON, DIV, H2, NodeOrOptional, SPAN } from '../elements';
import { buttonQueries } from './queries';
import { buttonEvents } from './events';
import {
    ButtonQuerySet,
    ButtonEventSet,
    ButtonName,
    ButtonGetter,
    ButtonSetter,
    ButtonConfig,
    ButtonSize,
} from '.';
import { IconName, nameToString } from './names';
import { tr, Translated } from '../../locale';
import { buttonTooltip, Tooltip } from '../tooltip';
import { none, Option } from 'fp-ts/lib/Option';

const makeClassName = (config: ButtonConfig, runTimeClass: string) => {
    switch (config.type) {
        case 'Icon':
            return `btn btn-${config.name} btn-${config.size} icon-only ${runTimeClass}`;
        case 'Label':
            return `btn btn-${config.name}  btn-${config.size}  label-only ${runTimeClass}`;
        case 'LabelAndIcon':
            return `btn btn-${config.name}  btn-${config.size} label-and-icon ${runTimeClass}`;
    }
};

const buttonOld = (queries: ButtonQuerySet, events: ButtonEventSet) => {
    const make =
        (config: ButtonConfig) =>
        (action: () => void, runTimeClass = '') => {
            const onClick = (e: React.MouseEvent<Element>) => {
                e.stopPropagation();
                action();
            };
            const className = makeClassName(config, runTimeClass);
            const attrs = config.id
                .map(id => ({ className, id, onClick }))
                .getOrElse({ className, onClick, id: '' });

            switch (config.type) {
                case 'Icon':
                    return buttonTooltip(
                        config.tooltip,
                        attrs,
                        SPAN({ className: 'icon' }, nameToString(config.icon))
                    );
                case 'Label':
                    return BUTTON(
                        attrs,
                        SPAN({ className: 'btn-label' }, config.label())
                    );
                case 'LabelAndIcon':
                    return BUTTON(
                        attrs,
                        SPAN({ className: 'btn-label' }, config.label()),
                        SPAN({ className: 'icon' }, nameToString(config.icon))
                    );
            }
        };

    const noId: Option<string> = none;

    const makeIcon = (
        name: ButtonName,
        size: ButtonSize,
        icon: IconName,
        tooltip: Tooltip,
        id = noId
    ) =>
        make({
            name,
            type: 'Icon',
            size,
            icon,
            tooltip,
            id,
        } as ButtonConfig);

    const makeLabel = (
        name: ButtonName,
        size: ButtonSize,
        label: () => Translated,
        id = noId
    ) => make({ name, type: 'Label', size, label, id } as ButtonConfig);

    const makeLabelAndIcon = (
        name: ButtonName,
        size: ButtonSize,
        icon: IconName,
        label: () => Translated,
        id = noId
    ) =>
        make({
            name,
            type: 'LabelAndIcon',
            size,
            label,
            icon,
            id,
        } as ButtonConfig);

    const makeRemove = (
        key: string,
        size = 3 as ButtonSize,
        label: () => Translated,
        confirmMsg: (() => Translated) | (() => NodeOrOptional)
    ) => {
        setTimeout(() => {
            if (!queries.hasKey(key)) {
                events.setStep(key, 'initial');
            }
        }, 1);

        const initialButton = () => {
            return makeLabel(
                'remove',
                size,
                label
            )(() => {
                events.setStep(key, 'active');
            });
        };

        const cancel = makeLabel('cancel', 2, () => tr.core('cancel'));
        const confirm = makeLabel('confirm', 1, () => tr.core('confirm'));

        const activeButton = (action: () => void) => {
            return DIV(
                { className: 'remove-confirm' },
                DIV(
                    { className: 'remove-confirm-box' },
                    DIV(
                        { className: 'remove-confirm-msg' },
                        H2({}, tr.core('confirmDelete')),
                        DIV({}, confirmMsg())
                    ),
                    DIV(
                        { className: 'remove-confirm-btns' },
                        cancel(() => {
                            events.setStep(key, 'initial');
                        }),
                        confirm(() => {
                            events.setStep(key, 'initial');
                            action();
                        })
                    )
                )
            );
        };

        return (action: () => void) => {
            if (queries.isActive(key)) {
                return activeButton(action);
            }
            return initialButton();
        };
    };

    const initRemove = (key: string, delay = 1) => {
        setTimeout(() => {
            try {
                if (!queries.hasKey(key)) {
                    events.setStep(key, 'initial');
                }
            } catch {
                initRemove(key, delay * 2);
            }
        }, delay);
    };

    const makeRemoveLabelAndIcon = (
        key: string,
        size = 3 as ButtonSize,
        icon: IconName,
        label: () => Translated,
        confirmMsg: () => Translated
    ) => {
        initRemove(key);

        const initialButton = () => {
            return makeLabelAndIcon(
                'remove',
                size,
                icon,
                label
            )(() => {
                events.setStep(key, 'active');
            });
        };

        const cancel = makeLabel('cancel', 2, () => tr.core('cancel'));
        const confirm = makeLabel('confirm', 1, () => tr.core('confirm'));

        const activeButton = (action: () => void) => {
            return DIV(
                { className: 'remove-confirm' },
                DIV(
                    { className: 'remove-confirm-box' },
                    DIV(
                        { className: 'remove-confirm-msg' },
                        H2({}, tr.core('confirmDelete')),
                        DIV({}, confirmMsg())
                    ),
                    DIV(
                        { className: 'remove-confirm-btns' },
                        cancel(() => {
                            events.setStep(key, 'initial');
                        }),
                        confirm(() => {
                            events.setStep(key, 'initial');
                            action();
                        })
                    )
                )
            );
        };

        return (action: () => void) => {
            if (queries.isActive(key)) {
                return activeButton(action);
            }
            return initialButton();
        };
    };

    const makeRemoveIcon = (
        key: string,
        size = 3 as ButtonSize,
        icon: IconName,
        confirmMsg: () => Translated,
        tooltip: Tooltip
    ) => {
        initRemove(key);

        const initialButton = () => {
            return makeIcon(
                'remove',
                size,
                icon,
                tooltip
            )(() => {
                events.setStep(key, 'active');
            });
        };

        const cancel = makeLabel('cancel', 2, () => tr.core('cancel'));
        const confirm = makeLabel('confirm', 1, () => tr.core('confirm'));

        const activeButton = (action: () => void) => {
            return DIV(
                { className: 'remove-confirm' },
                DIV(
                    { className: 'remove-confirm-box' },
                    DIV(
                        { className: 'remove-confirm-msg' },
                        H2({}, tr.core('confirmDelete')),
                        DIV({}, confirmMsg())
                    ),
                    DIV(
                        { className: 'remove-confirm-btns' },
                        cancel(() => {
                            events.setStep(key, 'initial');
                        }),
                        confirm(() => {
                            events.setStep(key, 'initial');
                            action();
                        })
                    )
                )
            );
        };

        return (action: () => void) => {
            if (queries.isActive(key)) {
                return activeButton(action);
            }
            return initialButton();
        };
    };

    return {
        make,
        makeIcon,
        makeLabel,
        makeLabelAndIcon,
        makeRemove,
        makeRemoveIcon,
        makeRemoveLabelAndIcon,
    };
};

export const factory = (getter: ButtonGetter, setter: ButtonSetter) => {
    const qs = buttonQueries(getter);
    const es = buttonEvents(setter, qs);
    return buttonOld(qs, es);
};

export const button = () => {
    const make =
        (config: ButtonConfig) =>
        (action: () => void, runTimeClass = '') => {
            const onClick = (e: React.MouseEvent<Element>) => {
                e.stopPropagation();
                action();
            };
            const className = makeClassName(config, runTimeClass);
            const attrs = config.id
                .map(id => ({ className, id, onClick }))
                .getOrElse({ className, onClick, id: '' });

            switch (config.type) {
                case 'Icon':
                    return buttonTooltip(
                        config.tooltip,
                        attrs,
                        SPAN({ className: 'icon' }, nameToString(config.icon))
                    );
                case 'Label':
                    return BUTTON(
                        attrs,
                        SPAN({ className: 'btn-label' }, config.label())
                    );
                case 'LabelAndIcon':
                    return BUTTON(
                        attrs,
                        SPAN({ className: 'btn-label' }, config.label()),
                        SPAN({ className: 'icon' }, nameToString(config.icon))
                    );
            }
        };

    const noId: Option<string> = none;

    const makeIcon = (
        name: ButtonName,
        size: ButtonSize,
        icon: IconName,
        tooltip: Tooltip,
        id = noId
    ) =>
        make({
            type: 'Icon',
            tooltip,
            size,
            icon,
            name,
            id,
        });

    const makeLabel = (
        name: ButtonName,
        size: ButtonSize,
        label: () => Translated,
        id = noId
    ) => make({ name, type: 'Label', size, label, id });

    const makeLabelAndIcon = (
        name: ButtonName,
        size: ButtonSize,
        icon: IconName,
        label: () => Translated,
        id = noId
    ) => make({ name, type: 'LabelAndIcon', size, label, icon, id });

    return {
        make,
        makeIcon,
        makeLabel,
        makeLabelAndIcon,
    };
};
