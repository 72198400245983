import * as debug from 'debug';
import { DIV, SPAN } from '../elements';
import type {
    TableDataRow,
    TableState,
    TableDataCell,
    RowSelectHandler,
    TableData,
    SetFilterFn,
    CellHighlighterFn,
} from './index';
import {
    StreamingField,
    streamFieldType,
    streamFieldName,
    PropertyTypeDescriptor,
} from '../../source';
import { fromNullable, Option, some, none } from 'fp-ts/lib/Option';
import { TableSort, SortDirection } from './sort';
import tr, {
    formatNumber,
    formatDate,
    fromRecord,
    Translated,
} from '../../locale';
import { buttonTooltipBottom } from '../tooltip';
import { makeIcon, makeLabel } from '../button';
import { Filter, renderFilter } from './filter';
import {
    tryBoolean,
    tryNumber,
    tryString,
    datetime8601,
    ensureArray,
} from '../../util';
import { findTerm, getAlias } from '../../app';

const logger = debug('sdi:table2');

type Width = [number, string];

const cellWidths = (fields: StreamingField[], columnAdjust: number[]) => {
    const adjust =
        columnAdjust.length === fields.length
            ? columnAdjust
            : Array(fields.length).fill(0);
    return fields.map<Width>(([name, _], i) => [
        Math.max(3, Math.max(13, name.length * 0.7) * (1 + adjust[i])),
        'em',
    ]);
};

const rowWidth = (fields: StreamingField[], columnAdjust: number[]) =>
    cellWidths(fields, columnAdjust).reduce<Width>(
        (acc, w) => [acc[0] + w[0], w[1]],
        [0, '']
    );

const cwString = (cw: Width) => `${cw[0]}${cw[1]}`;

const getType = (fields: StreamingField[], idx: number) =>
    fromNullable(fields[idx]).map(streamFieldType);

export const formatCell = (
    data: TableDataCell,
    dataType: PropertyTypeDescriptor
) => {
    return ensureArray(data)
        .map(data => {
            const defaultReturn = `${data}` as Translated;
            switch (dataType) {
                case 'boolean':
                    return tryBoolean(data)
                        .map<string>(b =>
                            b ? tr.core('true') : tr.core('false')
                        )
                        .getOrElse(defaultReturn);
                case 'number':
                    return tryNumber(data)
                        .map(formatNumber)
                        .getOrElse(defaultReturn);
                case 'string':
                    return tryString(data).getOrElse(defaultReturn);
                case 'date':
                    return tryString(data)
                        .chain(s => tryNumber(Date.parse(s)))
                        .map(n => formatDate(new Date(n)))
                        .getOrElse(defaultReturn);
                case 'datetime': {
                    return tryNumber(data).foldL(
                        () =>
                            tryString(data)
                                .chain(s => tryNumber(Date.parse(s)))
                                .map(n => datetime8601(new Date(n)))
                                .getOrElse(defaultReturn),
                        n => datetime8601(new Date(n))
                    );
                }
                case 'term':
                    return tryNumber(data)
                        .chain(findTerm)
                        .map<string>(t => fromRecord(t.name))
                        .getOrElse(defaultReturn);
            }
        })
        .join('; ');
};

const renderCell =
    (
        fields: StreamingField[],
        widths: Width[],
        row: TableDataRow,
        highlighter?: CellHighlighterFn
    ) =>
    (data: TableDataCell, idx: number) => {
        const dt = getType(fields, idx).getOrElse('string');
        const hClass =
            highlighter && highlighter(row.from, idx) ? 'cell-highlight' : '';
        return DIV(
            {
                key: `cell-${idx}`,
                title: `${data}`,
                className: `table-cell data-type-${dt} ${hClass}`,
                style: {
                    width: cwString(widths[idx]),
                },
            },
            formatCell(data, dt)
        );
    };

const renderRowNum = (rowNum: number, key: string | number) =>
    DIV(
        {
            key: `row-num-${key}`,
            className: 'table-row-num',
            style: {
                display: 'none',
            },
        },
        (rowNum + 1).toString()
    );

const selectRow =
    (rowNum: number, row: TableDataRow, handler: RowSelectHandler) => () =>
        handler(row, rowNum);

const renderRow =
    (
        fields: StreamingField[],
        offset: number,
        widths: Width[],
        handler: RowSelectHandler,
        selected: number,
        highlighter?: CellHighlighterFn
    ) =>
    (row: TableDataRow, idx: number) => {
        const rowNum = offset + idx;
        const selectedClass = selected === rowNum ? 'active' : '';
        const evenOdd = rowNum % 2 > 0 ? 'odd table-row' : 'even table-row';
        return DIV(
            {
                key: `row-${row.from}`,
                className: `${evenOdd} ${selectedClass}`,
                onClick: selectRow(rowNum, row, handler),
            },
            renderRowNum(rowNum, row.from),
            row.cells.map(renderCell(fields, widths, row, highlighter))
        );
    };

const renderTableHeaderCell = (
    idx: number,
    width: Width,
    colName: string,
    optSort: Option<TableSort>,
    ty: PropertyTypeDescriptor,
    onSort: (i: number, dir: SortDirection) => void,
    setColumnAdjust: (colNb: number, adjust: number) => void,
    onInitFilter: (i: number, ty: PropertyTypeDescriptor) => void
) => {
    const [className, newSortDirection] = optSort
        .chain(sort => (sort.col === idx ? some(sort) : none))
        .fold<[string, SortDirection]>(
            [`table-cell table-header-cell data-type-${ty}`, 'ASC'],
            sort =>
                sort.direction === 'ASC'
                    ? [
                          `table-cell table-header-cell data-type-${ty}  sorted sorted-asc`,
                          'DESC',
                      ]
                    : [
                          `table-cell table-header-cell data-type-${ty}  sorted sorted-desc`,
                          'ASC',
                      ]
        );

    const adjustPlusButton = makeIcon('add', 3, 'plus', {
        position: 'right',
        text: () => tr.core('increaseColumnSize'),
    });
    const adjustMinButton = makeIcon('add', 3, 'minus', {
        position: 'right',
        text: () => tr.core('reduceColumnSize'),
    });
    const initFilter = makeIcon('filter', 3, 'filter', {
        position: 'bottom-left',
        text: () => tr.core('filter'),
    });

    return DIV(
        {
            className,
            style: { width: cwString(width) },
            key: `header-cell-${idx}`,
            onClick: () => onSort(idx, newSortDirection),
        },
        // divTooltipBottomRight(
        //     tr.core('adjustColumnSize'),
        //     { className: 'adjust-col' },
        //     ),
        DIV(
            {
                className: 'adjust-col',
            },
            adjustPlusButton(() => setColumnAdjust(idx, 0.3)),
            adjustMinButton(() => setColumnAdjust(idx, -0.3))
        ),
        buttonTooltipBottom(
            `${tr.core('sort')} ${colName}` as Translated,
            { className: 'label-col' },
            SPAN({}, colName)
        ),
        // divTooltipBottom(
        //     tr.core('filter'),
        //     {},
        //     ),
        initFilter(() => onInitFilter(idx, ty))
    );
};

export const renderTableHeader = (
    fields: StreamingField[],
    state: TableState,
    onSort: (i: number, dir: SortDirection) => void,
    setColumnAdjust: (colNb: number, adjust: number) => void,
    onInitFilter: (i: number, ty: PropertyTypeDescriptor) => void
) => {
    const widths = cellWidths(fields, state.columnAdjust);
    const elems = fields.map((f, idx) =>
        renderTableHeaderCell(
            idx,
            widths[idx],
            getAlias(streamFieldName(f)),
            state.sort,
            streamFieldType(f),
            onSort,
            setColumnAdjust,
            onInitFilter
        )
    );

    return DIV(
        {
            className: 'table-header',
            key: 'table-header',
            style: {
                width: cwString(rowWidth(fields, state.columnAdjust)),
            },
        },
        ...elems
    );
};

export const renderTableBody =
    (handler: RowSelectHandler) =>
    (
        data: TableData,
        fields: StreamingField[],
        { rowHeight, position, window, selected, columnAdjust }: TableState,
        highlighter?: CellHighlighterFn
    ) => {
        const widths = cellWidths(fields, columnAdjust);
        const width = cwString(rowWidth(fields, columnAdjust));
        logger(`sizer => ${data.total * rowHeight}`);
        return DIV(
            {
                key: 'table-body',
                className: `table-body ${data.total}`,
                style: { width },
            },
            DIV(
                {
                    className: 'table-body-sizer',
                    style: {
                        minHeight: data.total * rowHeight,
                    },
                },
                DIV(
                    {
                        className: 'table-body-fragment',
                        style: {
                            position: 'relative',
                            top: position.y,
                            width,
                        },
                        key: `table-body-fragment|${window.offset}`,
                    },
                    data.rows.map(
                        renderRow(
                            fields,
                            window.offset,
                            widths,
                            handler,
                            selected,
                            highlighter
                        )
                    )
                )
            )
        );
    };

export const renderFilters = (
    filters: Filter[],
    keys: string[],
    setFilter: SetFilterFn,
    clearFilters: () => void
) =>
    DIV(
        { className: 'table-search' },
        DIV(
            { className: 'filter--wrapper' },
            ...filters.map((f, i) => renderFilter(f, i, keys, setFilter))
        ),
        makeLabel('clear', 2, () => tr.core('reset'))(clearFilters)
    );

logger('loaded');
