/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import setupNl from './nl';
import setupFr from './fr';
import { messages } from './message-db';
import { getLang, getApiUrl } from '../app';
import IntlMessageFormat from 'intl-messageformat';
import { MessageRecord, MessageRecordLang, fetchIO } from '../source';
import { fromNullable } from 'fp-ts/lib/Option';
import { EditedRecord, EditedRecordArrayIO } from '../source/io/io';
import { Collection } from '../util';

const logger = debug('sdi:locale');

type MessageDB = typeof messages;
export type MessageKey = keyof MessageDB;

interface TranslatedBrand {
    readonly Translated: unique symbol;
}

export type Translated = string & TranslatedBrand;

// tslint:disable-next-line: variable-name
export const EmptyString: Translated = '' as Translated;

setupNl();
setupFr();

const rEsc = new RegExp('\\\\', 'g');

export interface Parameters {
    [p: string]: string | number | Date;
}

export type FileOrNull = File | null;

export type FileRecord = {
    [k in MessageRecordLang]: FileOrNull;
};

export const rec = (
    r: MessageRecord,
    c: MessageRecordLang,
    def = ''
): Translated => {
    const result = r[c];
    if (result) {
        return result as Translated;
    }
    return def as Translated;
};

export const optRec = (r: MessageRecord) => (c: MessageRecordLang) =>
    fromNullable(r[c]);

export const fromRecordTemplated = <T>(r: { [k in MessageRecordLang]: T }) => {
    const lc = getLang();
    const result: T | undefined = r[lc];
    if (result) {
        return result;
    }
    throw Error('oops');
};

export const fromRecord = (
    r: MessageRecord,
    params?: Parameters
): Translated => {
    const lc = getLang();
    const template = rec(r, lc).replace(rEsc, '\\\\');
    if (params) {
        const imf = new IntlMessageFormat(template, lc);
        return imf.format(params) as Translated;
    }
    return template as Translated;
};

export const updateRecordRaw = (r: MessageRecord, value: string) => {
    const lc = getLang();
    const n = { ...r };
    n[lc] = value;
    return n;
};

export const fromRecordRaw = (r: MessageRecord) => {
    const lc = getLang();
    return r[lc] || '';
};

/**
 * After seeing perfs really going south, it appeaered that formatDate
 * had its part in it.
 * Note: uncached -> ~5ms; cached -> ~0.1ms
 * TODO explore native implementations
 */
type Cache = Collection<Collection<string>, MessageRecordLang>;

const FORMAT_CACHE: Cache = {};

const lookupCache = (
    c: Cache,
    l: MessageRecordLang,
    k: { toString: () => string }
) => {
    if (l in c) {
        const ks = k.toString();
        return c[l][ks] ?? null;
    }
    return null;
};

const updateCache = (
    c: Cache,
    l: MessageRecordLang,
    k: { toString: () => string },
    v: string
) => {
    if (!(l in c)) {
        c[l] = {};
    }
    c[l][k.toString()] = v;

    return v;
};

export const formatDate = (d: Date) => {
    const lc = getLang();
    const cached = lookupCache(FORMAT_CACHE, lc, d);
    if (cached !== null) {
        return cached as Translated;
    }
    const imf = new IntlMessageFormat(`{date_option, date}`, lc);
    return updateCache(
        FORMAT_CACHE,
        lc,
        d,
        imf.format({ date_option: d })
    ) as Translated;
};

export const formatNumber = (n: number) => {
    if (Number.isNaN(n)) {
        return '' as Translated;
    }
    const lc = getLang();
    const cached = lookupCache(FORMAT_CACHE, lc, n);
    if (cached !== null) {
        return cached as Translated;
    }
    const imf = new IntlMessageFormat(`{number_option, number}`, lc);
    return updateCache(
        FORMAT_CACHE,
        lc,
        n,
        imf.format({ number_option: n })
    ) as Translated;
};

export const fromFileRecord = (r: FileRecord) =>
    fromRecordTemplated<FileOrNull>(r);

export const updateFileRecord = (r: FileRecord, f: FileOrNull) => {
    const lc = getLang();
    const n: FileRecord = { ...r };
    n[lc] = f;
    return n;
};

export const formatMessage = (msg: MessageRecord, params?: Parameters) => {
    const lc = getLang();
    const template = rec(msg, lc);
    if (params) {
        const imf = new IntlMessageFormat(template, lc);
        return imf.format(params) as Translated;
    } else if (msg.parameters !== undefined) {
        const imf = new IntlMessageFormat(template, lc);
        return imf.format(msg.parameters) as Translated;
    }
    return template;
};

const getMessage = (k: MessageKey, params?: Parameters) =>
    formatMessage(messages[k], params);

interface EditedRecords {
    [col: string]: {
        [key: string]: MessageRecord;
    };
}

const DEBUG = (() => {
    try {
        const loc = document.location;
        const params = new URL(loc.href).searchParams;
        return params.has('debug');
    } catch (e) {
        logger(`could not load URL parameters: ${e}`);
        return false;
    }
})();

export class MessageStore {
    private editedStarted = false;
    private editedRecords: EditedRecords = {};
    private debug = DEBUG;

    private update_records(edited: EditedRecord[]) {
        edited.forEach(ed => {
            const parts = ed.key.split('/');
            if (parts.length > 1) {
                const collectionName = parts[0];
                const keyParts = parts.slice(1);
                const key = keyParts.join('/').toLowerCase();

                if (!(collectionName in this.editedRecords)) {
                    this.editedRecords[collectionName] = {};
                }
                this.editedRecords[collectionName][key] = ed.record;
            }
        });
    }

    getEdited(col: string, key: string, or: () => Translated) {
        const lkey = key.toLowerCase();
        if (this.debug) {
            return `TR:[ ${col}/${key} ]` as Translated;
        }
        if (col in this.editedRecords && lkey in this.editedRecords[col]) {
            return fromRecord(this.editedRecords[col][lkey]);
        }
        return or();
    }

    init_edited() {
        if (!this.editedStarted) {
            this.editedStarted = true;
            fetchIO(EditedRecordArrayIO, getApiUrl('edited'))
                .then(edited => this.update_records(edited))
                .catch(err => logger(`failed at getting edited: ${err}`));
        }
    }

    core(k: MessageKey, params?: Parameters) {
        return this.getEdited('core', k, () => getMessage(k, params));
    }
}

export const concat = (...ts: string[]) =>
    toTranslated(ts.reduce((acc, t) => acc + t, ''));

export const toTranslated = (t: string) => t as Translated;

export const tr = new MessageStore();

export default tr;

logger('loaded');
