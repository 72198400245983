import { DIV, NodeOrOptional, SPAN } from '../elements';
import tr, { formatDate } from '../../locale';
import { renderSelect } from './select';
import { some, fromNullable, Option } from 'fp-ts/lib/Option';
import { __forceRefreshState } from '../../app';
import { makeLabel, makeIcon } from '../button';
import { padStart, setoidDate } from '../../util';
import { Tooltip } from '../tooltip';
import { setoidNumber } from 'fp-ts/lib/Setoid';

const { setInputMode, setDisplayMode, getValue, setValue, resetValue, fold } =
    (() => {
        type OCState = 'input' | 'display';
        const state: { [k: string]: OCState } = {};
        const values: { [k: string]: Date } = {};

        const setInputMode = (key: string) => (state[key] = 'input');

        const setDisplayMode = (key: string) => (state[key] = 'display');

        const fold = (
            key: string,
            input: () => NodeOrOptional,
            display: () => NodeOrOptional
        ) => {
            if (key in state && state[key] === 'input') {
                return input();
            } else {
                return display();
            }
        };

        const getValue = (key: string) => fromNullable(values[key]);

        const setValue = (key: string, f: (d: Option<Date>) => Date) =>
            (values[key] = f(getValue(key)));

        const resetValue = (key: string) => delete values[key];

        return {
            setInputMode,
            setDisplayMode,
            getValue,
            setValue,
            resetValue,
            fold,
        };
    })();

const makeArray = (start: number, end: number) =>
    new Array(end + 1 - start).fill(0).map((_, index) => start + index);

// const renderYear = (y: number) => DIV({ className: 'input-date-year' }, y.toString());
const renderYear = (y: number) => y.toString();

const renderYears = (
    key: string,
    d: Date,
    start: number,
    end: number,
    selected: number
) => {
    const selectYear = renderSelect(
        `${key}-year`,
        renderYear,
        y => {
            setValue(key, optDate =>
                optDate.foldL(
                    () => new Date(d.setFullYear(y)),
                    date => new Date(date.setFullYear(y))
                )
            );
            __forceRefreshState();
        },
        setoidNumber
    );

    // renderSelect(
    //     setoidNumber,
    //     renderYear,
    //     (y) => {
    //     setValue(key, (optDate) => optDate.foldL(
    //         () => new Date(d.setFullYear(y)),
    //         date => new Date(date.setFullYear(y))),
    //     );
    //     __forceRefreshState();
    // },
    //     `${key}-year`);
    return DIV(
        { className: 'input-date-year-list' },
        selectYear(makeArray(start, end).reverse(), some(selected))
    );
};

const monthName = (m: number) => {
    switch (m) {
        case 0:
            return tr.core('january');
        case 1:
            return tr.core('february');
        case 2:
            return tr.core('march');
        case 3:
            return tr.core('april');
        case 4:
            return tr.core('may');
        case 5:
            return tr.core('june');
        case 6:
            return tr.core('july');
        case 7:
            return tr.core('august');
        case 8:
            return tr.core('september');
        case 9:
            return tr.core('october');
        case 10:
            return tr.core('november');
        case 11:
            return tr.core('december');
        default:
            return '~';
    }
};

// const dayName = (d: Date) => {
//     switch (d.getDay()) {
//         case 0: return tr.core('monday');
//         case 1: return tr.core('tuesday');
//         case 2: return tr.core('wendesday');
//         case 3: return tr.core('thursday');
//         case 4: return tr.core('friday');
//         case 5: return tr.core('saturday');
//         case 6: return tr.core('sunday');
//         default: return '~';
//     }
// };

// const renderMonth = (m: number) => DIV({
//     className: 'input-date-month',
//     title: padStart(m.toString(), 2, '0'),
// }, monthName(m));
const renderMonth = (m: number) => monthName(m);

const renderMonths = (key: string, d: Date, selected: number) => {
    // const selectMonth = renderSelect(setoidNumber, renderMonth, (m) => {
    //     setValue(key, (optDate) => optDate.foldL(
    //         () => new Date(d.setMonth(m)),
    //         date => new Date(date.setMonth(m))),
    //     );
    //     __forceRefreshState();
    // },
    //     `${key}-month`);
    const selectMonth = renderSelect(
        `${key}-month`,
        renderMonth,
        m => {
            setValue(key, optDate =>
                optDate.foldL(
                    () => new Date(d.setMonth(m)),
                    date => new Date(date.setMonth(m))
                )
            );
            __forceRefreshState();
        },
        setoidNumber
    );
    return DIV(
        { className: 'input-date-month-list' },
        selectMonth(makeArray(0, 11), some(selected))
    );
};

const numberOfDays = (year: number, month: number) => {
    const d = new Date();
    d.setFullYear(year);
    d.setMonth(month);
    for (let i = 1; i < 33; i += 1) {
        d.setDate(i);
        if (d.getMonth() !== month) {
            return i - 1;
        }
    }
    return 0; // just for the types thing
};

const renderDay = (dateObject: Date) => {
    // const name = dayName(dateObject);
    const date = padStart(dateObject.getDate().toString(), 2, '0');
    // return SPAN({ className: 'input-date-day', title: name }, date);
    return date;
};

const renderDays = (
    key: string,
    selected: Date,
    year: number,
    month: number
) => {
    const n = numberOfDays(year, month);
    const days = makeArray(1, n).map(i => new Date(year, month, i));
    // const selectDay = renderSelect<Date>(
    //     { equals: (a, b) => a.getDate() === b.getDate() },
    //     renderDay,
    //     (d) => {
    //         setValue(key, () => d);
    //         __forceRefreshState();
    //     },
    //     `${key}-day`,
    // );
    const selectDay = renderSelect(
        `${key}-day`,
        renderDay,
        d => {
            setValue(key, () => d);
            __forceRefreshState();
        },
        setoidDate
    );

    return DIV(
        { className: 'input-date-day-list' },
        selectDay(days, some(selected))
    );
};

const renderPicker = (
    key: string,
    selected: Date,
    dispatch: DateDispatch,
    min: Date,
    max: Date
) => {
    const baseValue = getValue(key).getOrElse(selected);
    const year = baseValue.getFullYear();
    const month = baseValue.getMonth();
    const startYear = min.getFullYear();
    const endYear = max.getFullYear();
    const okButton = makeLabel('validate', 1, () => tr.core('validate'));
    const cancelButton = makeLabel('cancel', 2, () => tr.core('cancel'));
    const action = (f: () => void) => () => {
        f();
        resetValue(key);
        setDisplayMode(key);
        __forceRefreshState();
    };
    return DIV(
        {
            className: 'input-date-picker',
            onClick: () => setInputMode(key),
        },
        renderDays(key, baseValue, year, month),
        renderMonths(key, baseValue, month),
        renderYears(key, baseValue, startYear, endYear, year),
        DIV(
            {
                className: 'input-date-buttons',
            },
            cancelButton(action(() => void 0)),
            okButton(action(() => getValue(key).map(dispatch)))
        )
    );
};

const renderString = (key: string, d: Date) => {
    const tooltip: Tooltip = { position: 'right', text: tr.core('edit') };
    const edit = makeIcon('edit', 3, 'calendar-alt', tooltip);
    const action = () => {
        setInputMode(key);
        __forceRefreshState();
    };
    return DIV(
        {
            className: 'input-date-format',
        },
        SPAN({}, formatDate(d)),
        edit(action)
    );
};

type DateDispatch = (d: Date) => void;

export const inputDate =
    (key: string) =>
    (min: Date, max: Date, selected: Date, dispatch: DateDispatch) => {
        return DIV(
            {
                className: 'input-date',
            },
            fold(
                key,
                () => renderPicker(key, selected, dispatch, min, max),
                () => renderString(key, selected)
            )
        );
    };

export default inputDate;
