/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { none, some } from 'fp-ts/lib/Option';
import { query } from 'sdi/shape';

const splitURL = (rid: string) => {
    const splitStr = rid.split('://', 2);
    if (splitStr.length === 2) {
        const domAndPath = splitStr[1].split('/', 2);
        if (domAndPath.length === 2) {
            return some({
                module: splitStr[0],
                dom: domAndPath[0],
                path: domAndPath[1],
            });
        }
    }
    return none;
};
// const getModule = (rid: string) =>
//     splitURL(rid).fold('', domAndPath => domAndPath.module);
// export const getDomain = (rid: string) =>
//     splitURL(rid).fold('', domAndPath => domAndPath.dom);
export const getPath = (rid: string) =>
    splitURL(rid).fold('', domAndPath => domAndPath.path);

const queries = {
    expandImage() {
        return query('component/legend/expand-image');
    },

    displayWMSLegend() {
        return query('component/legend/show-wms-legend');
    },

    currentPage() {
        return query('component/legend').currentPage;
    },

    toolsWebservices() {
        return query('component/legend/webservices');
    },

    toolsGeocoder() {
        return query('component/legend/geocoder');
    },

    toolsPositioner() {
        return query('component/legend/positioner');
    },

    shareWithView() {
        return query('component/legend/share').withView;
    },
};

export default queries;
