import { DIV, H2 } from 'sdi/components/elements';
import { makeLabel } from 'sdi/components/button';
import tr from 'sdi/locale';
import { inputText, attrOptions, inputColor } from 'sdi/components/input';
import { getBookmarkColor, getBookmarkName } from 'view/src/queries/bookmark';
import { setBookmarkColor, setBookmarkName } from 'view/src/events/bookmark';
import { setFocusId } from 'sdi/app';

const closeModalButton = makeLabel('close', 2, () => tr.core('close'));

const header = () => H2({}, tr.view('editBookmark'));

const footer = (close: () => void) =>
    DIV(
        {},
        closeModalButton(() => {
            setFocusId('bookmark-edit-button');
            close();
        })
    );

const body = () =>
    DIV(
        'bookmark-modal',
        inputText(
            attrOptions(
                'bookmark-modal-input',
                () => getBookmarkName().getOrElse(''),
                setBookmarkName,
                {
                    id: 'bookmark-modal-input',
                }
            )
        ),
        inputColor(getBookmarkColor, setBookmarkColor)
    );

export const render = { header, footer, body };
