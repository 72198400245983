import { SyntheticLayerInfo } from '../app';
import { right } from 'fp-ts/lib/Either';
import { some, Option } from 'fp-ts/lib/Option';
import { FetchData } from '../map';
import {
    Feature,
    GeometryType,
    ILayerInfo,
    Inspire,
    StyleConfig,
} from '../source';

export const clientLayer = (
    id: string,
    geometryType: GeometryType,
    style: StyleConfig,
    features: Feature[]
) => {
    const metadata: Inspire = {
        id,
        geometryType,
        resourceTitle: { fr: id, nl: id },
        resourceAbstract: { fr: id, nl: id },
        uniqueResourceIdentifier: id,
        topicCategory: [],
        keywords: [],
        geographicBoundingBox: { west: 0.0, north: 0.0, east: 0.0, south: 0.0 },
        temporalReference: { creation: 'NOW', revision: 'NOW' },
        responsibleOrganisation: [],
        metadataPointOfContact: [],
        metadataDate: 'NOW',
        published: false,
        dataStreamUrl: null,
        maintenanceFrequency: 'unknown',
    };

    const layer: ILayerInfo = {
        id,
        legend: null,
        group: null,
        metadataId: id,
        featureViewOptions: { type: 'default' },
        layerInfoExtra: null,
        visible: true,
        visibleLegend: false,
        opacitySelector: false,
        style,
    };

    const info = (): Option<SyntheticLayerInfo> =>
        some({
            name: { fr: id, nl: id },
            info: layer,
            metadata,
        });

    const data: FetchData = () =>
        right(some({ id, type: 'FeatureCollection', features }));

    return { style, data, info, layer };
};

export default clientLayer;
