import * as debug from 'debug';
import { IO } from 'fp-ts/lib/IO';

const logger = debug('sdi:app/rect');

// TODO: use https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver as we no longer support IE

export interface RectFn {
    (t: ClientRect, e: Element): void;
}

interface Sub {
    el: Element;
    fn: RectFn;
    prev: ClientRect | null;
}

let subs: Sub[] = [];

export const rect = (fn: RectFn) => (el: Element | null) => {
    if (el) {
        const subIndex = subs.findIndex(s => s.el === el);
        if (subIndex >= 0) {
            subs[subIndex] = (() => {
                const [s, r] = runSub({ el, fn, prev: null });
                r.run();
                return s;
            })();
        } else {
            subs.push({ el, fn, prev: null });
        }
    }
};

// TODO - contemplate if it's worth watching only for size of rect.
const eqRect = (a: ClientRect, b: ClientRect) =>
    a.bottom === b.bottom &&
    a.height === b.height &&
    a.left === b.left &&
    a.right === b.right &&
    a.top === b.top &&
    a.width === b.width;

type RS = [Sub, IO<void>];

const runSub = ({ el, fn, prev }: Sub): RS => {
    const rect = el.getBoundingClientRect();
    if (null === prev || !eqRect(prev, rect)) {
        return [{ el, fn, prev: rect }, new IO(() => fn(rect, el))];
    }
    return [{ el, fn, prev: rect }, new IO<void>(() => void 0)];
};

const isAttached = (el: Element): boolean => {
    if (el.parentElement === document.body) {
        return true;
    } else if (el.parentElement !== null) {
        return isAttached(el.parentElement);
    }
    return false;
};

const filterStillThere = (xs: Sub[]) =>
    xs.filter(s => s.el !== null && isAttached(s.el));

const run = () => {
    // logger(`run ${subs.length}`);
    subs = filterStillThere(subs)
        .map(runSub)
        .map(rs => {
            rs[1].run();
            return rs[0];
        });
};

window.setInterval(run, 250);

logger('loaded');
