import * as debug from 'debug';

import { fromNullable } from 'fp-ts/lib/Option';

import { queryK, dispatchK, assign } from 'sdi/shape';
import { scopeOption } from 'sdi/lib';

import { clearMap, loadMap, setCurrentFeatureById, setLayout } from './app';
import { viewEvents } from './map';
import { AppLayout } from '../shape/types';
import { getRootUrl } from 'sdi/app';
import { prepareGeometryForHarvesting } from './harvest';
import { getMapList, getMaps } from '../queries/mapnavigator';
import { tryNumber } from 'sdi/util';
import { getCurrentLayer, getCurrentMap } from '../queries/app';

const logger = debug('sdi:route');
const hasHistory =
    typeof window !== 'undefined' && window.history && window.history.pushState;

type historyStateKind = 'home' | 'map';
interface HistoryState {
    kind: historyStateKind;
    route: string[];
}

const getRoute = queryK('app/route');
const setRoute = dispatchK('app/route');

const cleanRoute = () =>
    getRoute().reduce((acc, s) => {
        if (s.length > 0) {
            return acc.concat([s]);
        }
        return acc;
    }, [] as string[]);

const getNumber = (s?: string) => {
    if (s) {
        const n = parseFloat(s);
        if (!Number.isNaN(n)) {
            return n;
        }
    }
    return null;
};

const setMapView = () => {
    const r = cleanRoute();
    scopeOption()
        .let('lat', fromNullable(getNumber(r[1])))
        .let('lon', fromNullable(getNumber(r[2])))
        .let('zoom', fromNullable(getNumber(r[3])))
        .map(({ lat, lon, zoom }) => {
            viewEvents.updateMapView({
                dirty: 'geo',
                center: [lat, lon],
                zoom,
            });
        });
};

export const navigate = () => {
    const r = cleanRoute();
    if (r.length > 0) {
        const currentMapId = getCurrentMap();
        const mapId = r[0];
        if (mapId !== currentMapId) {
            assign('app/current-map', mapId);
            loadMap();
        }

        if (r[1] === 'feature') {
            const layerId: string = r[2];
            const featureId = fromNullable(r[3]).chain(tryNumber);
            setLayout(AppLayout.MapAndFeature);
            featureId.map(fid => setCurrentFeatureById(layerId, fid));
        } else {
            prepareGeometryForHarvesting();
            setMapView();
            setLayout(AppLayout.MapAndInfo);
        }
    } else {
        if (getMapList(getMaps()).length > 0) {
            clearMap();
            setLayout(AppLayout.MapNavigatorFS);
        }
    }
};

const pushMap = (mid: string) => {
    if (hasHistory) {
        const s: HistoryState = {
            kind: 'map',
            route: [mid],
        };

        window.history.pushState(s, `View - ${mid}`, getRootUrl(`view/${mid}`));
    }
};

const pushMapFeature = (mid: string, lid: string, fid: string | number) => {
    if (hasHistory) {
        const s: HistoryState = {
            kind: 'map',
            route: [mid, 'feature', lid, `${fid}`],
        };
        window.history.pushState(
            s,
            `View - ${mid}`,
            getRootUrl(`view/${mid}/feature/${lid}/${fid}`)
        );
    }
};

const pushHome = () => {
    if (hasHistory) {
        const s: HistoryState = {
            kind: 'home',
            route: [],
        };

        window.history.pushState(s, `View - Atlas`, getRootUrl('view/'));
    }
};

export const navigateHome = () => {
    setRoute(() => []);
    navigate();
    pushHome();
};

export const navigateMap = (mid: string) => {
    fromNullable(getCurrentLayer()).foldL(
        () => setRoute(() => [mid]),
        lid => setRoute(() => [mid, 'feature', lid])
    );
    navigate();
    pushMap(mid);
};

export const navigateMapFeature = (
    mid: string,
    lid: string,
    fid: string | number
) => {
    setRoute(() => [mid, 'feature', lid, `${fid}`]);
    navigate();
    pushMapFeature(mid, lid, fid);
};

(function () {
    if (hasHistory) {
        window.onpopstate = (event: PopStateEvent) => {
            const s = event.state as HistoryState;
            switch (s.kind) {
                case 'home':
                    setRoute(() => s.route);
                    break;
                case 'map':
                    setRoute(() => s.route);
                    break;
            }
            navigate();
        };
    }
})();

logger('loaded');
