/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import { IMapInfo, ILayerInfo, LayerGroup } from 'sdi/source';
import { fromRecord } from 'sdi/locale';
import { scopeOption } from 'sdi/lib';
import { Box, makeText, Layout } from 'sdi/print/context';

import legendItem from './legend-item';
import { Spec, TemplateName, applySpec } from '../template';
import { withoutBookmarks } from 'view/src/queries/bookmark';

const logger = debug('sdi:print/legend');

interface Group {
    g: LayerGroup | null;
    layers: ILayerInfo[];
}

const groupItems = (layers: ILayerInfo[]) =>
    withoutBookmarks(layers)
        .slice()
        .reverse()
        .reduce<Group[]>((acc, info) => {
            const ln = acc.length;
            if (ln === 0) {
                return [
                    {
                        g: info.group,
                        layers: [info],
                    },
                ];
            }
            const prevGroup = acc[ln - 1];
            const cg = info.group;
            const pg = prevGroup.g;
            // Cases:
            // info.group == null && prevGroup.g == null => append
            // info.group != null && prevGroup.g != null && info.group.id == prevGroup.id => append
            if (
                (cg === null && pg === null) ||
                (cg !== null && pg !== null && cg.id === pg.id)
            ) {
                prevGroup.layers.push(info);
                return acc;
            }
            // info.group == null && prevGroup.g != null => new
            // info.group != null && prevGroup.g == null => new
            // info.group != null && prevGroup.g != null && info.group.id != prevGroup.id => new

            return acc.concat({
                g: cg,
                layers: [info],
            });
        }, []);

// const groupMargin = 10;

// const boxHeight = (bs: Box[]) =>
//     bs.reduce<number>((acc, b) => acc + b.height, 0);

type SpecConfig = {
    legend: Spec;
    legendItem: Spec;
};

const renderGroups = (spec: SpecConfig, groups: Group[]): Box => {
    const theLayout: Layout = {
        direction: 'vertical',
        items: [],
    };

    groups.map(group => {
        const layers = group.layers.filter(l => l.visible === true);
        if (layers.length > 0) {
            if (group.g !== null) {
                const gnfs = spec.legendItem.fontSize * 1.4;
                const gnh = Math.max(spec.legendItem.rect.height * 1.4, gnfs);
                const groupName = makeText(
                    fromRecord(group.g.name),
                    gnfs,
                    spec.legendItem.color,
                    'left',
                    'center'
                );
                const groupNameBox: Box = {
                    x: 0,
                    y: 0,
                    width: spec.legendItem.rect.width,
                    height: gnh,
                    children: [groupName],
                };
                theLayout.items.push(groupNameBox);
            } else {
                // const emptyGroupBox: Box = {
                //     x: 0,
                //     y: 0,
                //     width: spec.legendItem.rect.width,
                //     height: spec.legendItem.rect.height,
                //     children: [],
                // };
                // theLayout.items.push(emptyGroupBox);
            }
            layers.map(info => {
                const box = legendItem(spec.legendItem, info);

                // not-simple legend can be empty, resulting in a 0x0 box
                box.width = Math.max(box.width, spec.legendItem.rect.width);
                box.height = Math.max(box.height, spec.legendItem.rect.height);
                box.name = `layer-${info.id}`;

                const label = info.legend;
                if (label && fromRecord(label).trim().length > 0) {
                    const lfs = spec.legendItem.fontSize * 1.2;
                    const lc = makeText(
                        fromRecord(label),
                        lfs,
                        spec.legendItem.color,
                        'left',
                        'center'
                    );
                    const lcBox: Box = {
                        x: 0,
                        y: 0,
                        width: box.width,
                        height: lfs,
                        children: [lc],
                        name: `label-${info.id}`,
                    };
                    const ll: Layout = {
                        direction: 'vertical',
                        items: [lcBox, box],
                    };
                    const categoryBox = {
                        x: 0,
                        y: 0,
                        width: box.width,
                        height: box.height + lfs,
                        children: [ll],
                        name: fromRecord(label),
                    };

                    theLayout.items.push(categoryBox);
                } else {
                    theLayout.items.push(box);
                }
            });
        }
    });
    const boxed: Box = {
        ...spec.legend.rect,
        name: 'legend',
        children: [theLayout],
    };

    return boxed;
};

export const renderLegend = (tn: TemplateName, mapInfo: IMapInfo) => {
    const withSpec = applySpec(tn);
    return scopeOption()
        .let(
            'legend',
            withSpec('legend', s => s)
        )
        .let(
            'legendItem',
            withSpec('legendItem', s => s)
        )
        .map(scope => renderGroups(scope, groupItems(mapInfo.layers)));
};

logger('loaded');
