import { getGeometry, getHarvests } from 'view/src/queries/harvest';
import {
    DIV,
    TD,
    TH,
    TR,
    TABLE,
    THEAD,
    TBODY,
    SPAN,
    H2,
} from 'sdi/components/elements';
import { HarvestedLayer, HarvestedLayerLoaded } from './index';
import {
    DirectGeometryObject,
    Feature,
    FeatureViewConfig,
    FeatureViewOptions,
    getMessageRecord,
    withoutWidget,
    withQueryString,
} from 'sdi/source';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import {
    getCurrentName,
    getDatasetMetadataOption,
    getMapOption,
} from 'view/src/queries/app';
import { fromRecord } from 'sdi/locale';
import { getAlias, getLang } from 'sdi/app';
import { getLayerPropertiesKeys } from 'sdi/util';
import {
    exportCSVLink,
    ExportForm,
    exportXLSXLink,
} from 'sdi/components/export';
import { nameToString } from 'sdi/components/button/names';
// import mapInfo from '../map-info';

export const getRessourceTitle = (metadataId: string) =>
    getDatasetMetadataOption(metadataId).fold(metadataId, md =>
        fromRecord(getMessageRecord(md.resourceTitle))
    );
const renderTitle = (mid: string) =>
    DIV({ className: 'layer-title' }, getRessourceTitle(mid));

// const extractCSVBtn = makeLabelAndIcon('load', 1, 'download', () =>
//     tr.core('exportCSV')
// );
// const renderExtractCSVBtn = (layer: HarvestedLayerLoaded) =>
//     extractCSVBtn(() => extractAsCSV(layer));

const roundCoords = (coords: number[]) => coords.map(c => Math.round(c));
const roundPolygonCoords = (coords: number[][][]) =>
    coords.map(list => list.map(roundCoords));

const roundGeomCoords = (g: DirectGeometryObject) => {
    switch (g.type) {
        case 'Point':
            return roundCoords(g.coordinates);
        case 'MultiPoint':
            return g.coordinates.map(roundCoords);
        case 'LineString':
            return g.coordinates.map(roundCoords);
        case 'MultiLineString':
            return roundPolygonCoords(g.coordinates);
        case 'Polygon':
            return roundPolygonCoords(g.coordinates);
        case 'MultiPolygon':
            return g.coordinates.map(c => roundPolygonCoords(c));
    }
};

// round the coordinates before stringify geometry. May ever need a geometry simplification?
const stringifyGeom = (geom: DirectGeometryObject) =>
    JSON.stringify({
        ...geom,
        coordinates: roundGeomCoords(geom),
    });

const getExportLink =
    (
        uniqueResourceIdentifier: string,
        geom: DirectGeometryObject,
        fields: string[]
    ) =>
    (form: ExportForm) =>
        withQueryString(uniqueResourceIdentifier, {
            form,
            lang: getLang(),
            geom: stringifyGeom(geom),
            field: fields,
        });

const renderExportCSV = (loaded: HarvestedLayerLoaded) =>
    getDatasetMetadataOption(loaded.metadataId).chain(
        ({ uniqueResourceIdentifier }) =>
            getGeometry().map(geom =>
                DIV(
                    'btn btn-3 label-and-icon export-link__wrapper',
                    SPAN(
                        'btn-label',
                        exportCSVLink(
                            getExportLink(
                                uniqueResourceIdentifier,
                                geom,
                                getConfigLayerKeys(loaded)
                            ),
                            getCurrentName().getOrElse('')
                        )
                    ),
                    SPAN('icon', nameToString('download'))
                )
            )
    );

const renderExportXLSX = (loaded: HarvestedLayerLoaded) =>
    getDatasetMetadataOption(loaded.metadataId).chain(
        ({ uniqueResourceIdentifier }) =>
            getGeometry().map(geom =>
                DIV(
                    'btn btn-3 label-and-icon export-link__wrapper',
                    SPAN(
                        'btn-label',
                        exportXLSXLink(
                            getExportLink(
                                uniqueResourceIdentifier,
                                geom,
                                getConfigLayerKeys(loaded)
                            ),
                            getCurrentName().getOrElse('')
                        )
                    ),
                    SPAN('icon', nameToString('download'))
                )
            )
    );

const fromConfig = fromPredicate<FeatureViewOptions>(
    options => options.type === 'config'
);
const getConfig = (mapId: string, metadataId: string) => {
    const layerInfo = getMapOption(mapId).chain(mi =>
        fromNullable(mi.layers.find(l => l.metadataId === metadataId))
    );

    return layerInfo
        .chain(info => fromNullable(info.featureViewOptions))
        .chain(fromConfig)
        .getOrElse({ type: 'config', rows: [] } as FeatureViewConfig);
};

const getRows = (harvested: HarvestedLayerLoaded) => {
    const lc = getLang();
    const config = getConfig(harvested.mapId, harvested.metadataId);
    if (config.type === 'config') {
        return config.rows.filter(withoutWidget).filter(r => r.lang === lc);
    } else {
        return [];
    }
};
export const getConfigLayerKeys = (harvested: HarvestedLayerLoaded) => {
    const configKeys = getRows(harvested);
    if (configKeys.length > 0) {
        return configKeys.map(row => row.propName);
    }
    return getLayerPropertiesKeys(harvested.data);
};

const renderTable = (h: HarvestedLayerLoaded) => {
    const features = h.data.features;
    if (features.length === 0) {
        return DIV({});
    }
    const first = features[0];
    const props = first.properties;

    if (props === null) {
        return DIV({});
    }

    // const keys = Object.keys(props);
    const keys = getConfigLayerKeys(h);
    const header = TR({}, ...keys.map(k => TH({}, getAlias(k))));

    const renderRow = (f: Feature) => {
        const data = f.properties || {};
        const cells = keys.map(k =>
            TD(
                {},
                fromNullable(data[k]).fold('-', v => `${v}`)
            )
        );
        return TR({}, ...cells);
    };
    const rows = features.map(renderRow);

    return DIV(
        { className: 'result-table' },
        DIV('table-actions', renderExportCSV(h), renderExportXLSX(h)),
        H2('', renderTitle(h.metadataId)),
        TABLE(
            { className: 'results' },
            // CAPTION({}, renderTitle(h.metadataId)),
            THEAD(
                {},
                // TR({}, TD({ colSpan: 10 }, renderTitle(h.metadataId))),
                header
            ),
            TBODY({}, ...rows)
        )
    );
};

// const renderMapInfo =
//     (info: IMapInfo) =>
//         DIV({}, renderTitle(fromRecord(info.title)));

const renderHarvest = (h: HarvestedLayer) => {
    switch (h.tag) {
        case 'initial':
            return DIV({ key: h.metadataId }, 'initial');
        case 'loading':
            return DIV({ key: h.metadataId }, 'loading');
        case 'error':
            return DIV({ key: h.metadataId }, h.error);
        case 'loaded':
            return DIV({ key: h.metadataId }, renderTable(h));
    }
};

export const renderResults = () =>
    DIV(
        { className: 'results-wrapper' },
        getHarvests()
            .map(h => h) // To override the readonly but we need to find a better solution
            .sort((a, b) => {
                if (a.groupId <= b.groupId && a.id < b.id) {
                    return -1;
                }
                return 1;
            })
            .map(h => renderHarvest(h))
    );
