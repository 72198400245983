import { fromCompare, unsafeCompare } from 'fp-ts/lib/Ord';
import { sort } from 'fp-ts/lib/Array';

type SortableValue = string | number | boolean;

// type Sortable<T, K extends keyof T> = {
//     [k in K]: SortableValue;
// } & T

// export function pickSort<T, K extends keyof T, S = Sortable<T, K>>(k: K) {
//     const o = fromCompare((x: S, y: S) => unsafeCompare(x[k], y[k]));
//     return sort(o);
// }

export function pickSortL<T>(pick: (x: T) => SortableValue) {
    const o = fromCompare((x: T, y: T) => unsafeCompare(pick(x), pick(y)));
    return sort(o);
}
