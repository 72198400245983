/**
 * DETAILS/SUMMARY are widely used, but while they provide ane easy
 * way to implement collapsable elements (no-js), forcing users
 * to click again on the trigger to close them is ... less than ideal.
 * Here at least we provide an easy ESCAPE.
 */
export const closeDetailsOnEscape = () => {
    document.addEventListener('keyup', ke => {
        if (ke.key === 'Escape') {
            document
                .querySelectorAll('details')
                .forEach(detail => (detail.open = false));
        }
    });
};
