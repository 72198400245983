/*
 *  Copyright (C) 2019 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { i, nullable, TypeOf } from '../io';
import * as io from 'io-ts';
import { MessageRecordLangIO } from '../message';

export const ActivityTokenIO = i({
    token: io.string,
});

export type ActivityToken = TypeOf<typeof ActivityTokenIO>;

export const BaseActivityIO = i({
    token: io.string,
    lang: io.string,
    datetime: io.number,
    namespace: io.string,
});

// (go-to-) visit action
export const VisitDataIO = i(
    {
        action: io.literal('visit'),
        parameter: i({}),
    },
    'VisitDataIO'
);
export type VisitData = io.TypeOf<typeof VisitDataIO>;

export const VisitIO = io.intersection(
    [BaseActivityIO, VisitDataIO],
    'VisitIO'
);
export type Visit = io.TypeOf<typeof VisitIO>;

// click the checkboxes

export const CheckboxDataIO = i(
    {
        action: io.literal('checked'),
        parameter: i({
            id: io.string,
        }),
    },
    'CheckboxDataIO'
);

export type CheckboxData = io.TypeOf<typeof CheckboxDataIO>;

export const ClickedCheckboxIO = io.intersection(
    [BaseActivityIO, CheckboxDataIO],
    'ClickIO'
);

export type Checkbox = io.TypeOf<typeof CheckboxDataIO>;

// click the buttons/switch

export const ButtonDataIO = i(
    {
        action: io.literal('clicked'),
        parameter: i({
            id: io.string,
            select: nullable(io.string),
        }),
    },
    'ButtonDataIO'
);

export type ButtonData = io.TypeOf<typeof ButtonDataIO>;

export const ClickedButtonIO = io.intersection(
    [BaseActivityIO, ButtonDataIO],
    'ClickIO'
);

export type Click = io.TypeOf<typeof ButtonDataIO>;

// (go-to-) link action
export const LinkDataIO = i(
    {
        action: io.literal('link'),
        parameter: i({
            link: io.string,
        }),
    },
    'LinkDataIO'
);
export type LinkData = io.TypeOf<typeof LinkDataIO>;

export const LinkIO = io.intersection([BaseActivityIO, LinkDataIO], 'LinkIO');
export type Link = io.TypeOf<typeof LinkIO>;

// (select-) lang action
export const LangDataIO = i(
    {
        action: io.literal('lang-choice'),
        parameter: i({
            lang: MessageRecordLangIO,
        }),
    },
    'LangDataIO'
);
export type LangData = io.TypeOf<typeof LangDataIO>;

export const LangActionIO = io.intersection(
    [BaseActivityIO, LangDataIO],
    'LangActionIO'
);
export type LangAction = io.TypeOf<typeof LangActionIO>;

// export data action
export const ExportDataIO = i(
    {
        action: io.literal('export'),
        parameter: io.intersection([
            i({
                fileName: io.string,

                // extention: io.string,
            }),
            io.partial({ key: io.string }),
        ]),
    },
    'ExportDataIO'
);
export type ExportData = io.TypeOf<typeof ExportDataIO>;

export const ExportActionIO = io.intersection(
    [BaseActivityIO, ExportDataIO],
    'ExportActionIO'
);
export type ExportAction = io.TypeOf<typeof ExportActionIO>;

//  action example

// const OtherActionDataIO = i({
//     action: io.literal('other'),
//     parameter: i({
//         id: io.string,
//         foo: io.number,
//     })
// }, 'xxx')

// const OtherActionIO = io.intersection([
//     BaseActionIO,
//     OtherActionDataIO
// ], 'OtherActionIO')
// export type OtherAction = io.TypeOf<typeof OtherActionIO>;

// export const otherAction  = (id: string, foo: number) : OtherAction => ({
//     action : 'other',
//     parameter: {id, foo}
// })
