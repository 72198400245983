import { DIV, FIELDSET, INPUT, LABEL, LEGEND, SPAN } from '../elements';
import { AllHTMLAttributes, ReactNode } from 'react';

type InputAttributes = AllHTMLAttributes<HTMLInputElement>;

export const withChecked = (c: boolean, attrs: InputAttributes) =>
    c ? { ...attrs, checked: true } : attrs;

const activeClass = (a: boolean) => (a ? 'selected' : 'unselected');
const beforeSelectedClass = (b: boolean) => (b ? 'before-selected' : '');

//radio button for a list of options (text will be next to the button if style 'radio' is selected)
export const renderRadioIn =
    <T>(
        name: string,
        renderItem: (i: T) => ReactNode,
        select: (i: T) => void,
        style: 'radio' | 'switch' = 'switch',
        label?: string
    ) =>
    (options: T[], selected: T | null, runtimeClassName = '') => {
        let selectedIndex = options.length;
        return FIELDSET(
            `${style}__fieldset`,
            LEGEND(`${style}__legend`, label),

            DIV(
                {
                    className: `${runtimeClassName} ${style}__body length-${options.length}`,
                },
                options.map((o, i) => {
                    const itemSelected = o === selected;
                    if (itemSelected) {
                        selectedIndex = i;
                    }
                    return DIV(
                        {
                            className: `${style}__item item-${
                                i + 1
                            } ${activeClass(
                                itemSelected
                            )} ${beforeSelectedClass(i < selectedIndex)}`,
                            key: `${style}-${name}-${i}`,
                            onClick: () => select(o),
                        },
                        LABEL(
                            {
                                // for: i.toString(),
                                // htmlFor: 'first'
                            },
                            INPUT(
                                withChecked(itemSelected, {
                                    // id: 'first',
                                    type: 'radio',
                                    name,
                                    value: i,
                                    'aria-checked': itemSelected,
                                })
                            ),
                            SPAN(
                                'radio__control',
                                SPAN('radio__control-inner')
                            ),
                            renderItem(o)
                        )
                    );
                })
            )
        );
    };

//radio button that looks like a switch button (between two options) - the text is not in the button
export const renderRadioOut =
    <T>(
        name: string,
        renderItem: (i: T) => ReactNode,
        select: (i: T) => void,
        label?: string
    ) =>
    (options: [T, T], selected: T, runtimeClassName = '') => {
        const firstSelected = options[0] === selected;
        const secondSelected = options[1] === selected;
        const toggleClass = firstSelected
            ? 'first-selected'
            : 'second-selected';
        return FIELDSET(
            'switch-out__fieldset',
            LEGEND('switch__legend', label),
            DIV(
                {
                    className: `${runtimeClassName} switch__body`,
                },

                DIV(
                    {
                        className: `switch__item first ${activeClass(
                            firstSelected
                        )}`,
                        onClick: () => select(options[0]),
                    },
                    LABEL(
                        {
                            // "htmlFor": `first`,
                        },
                        INPUT(
                            withChecked(firstSelected, {
                                // id: 'first',
                                type: 'radio',
                                name,
                                value: 'first',
                                'aria-checked': firstSelected,
                            })
                        ),
                        renderItem(options[0])
                    )
                ),
                DIV(
                    {
                        className: `toggle__wrapper ${toggleClass}`,
                        onClick: () =>
                            firstSelected
                                ? select(options[1])
                                : select(options[0]),
                    },
                    DIV('toggle__inner')
                ),
                DIV(
                    {
                        className: `switch__item second ${activeClass(
                            secondSelected
                        )}`,
                        onClick: () => select(options[1]),
                    },
                    LABEL(
                        {
                            // "htmlFor": `second`,
                        },
                        INPUT(
                            withChecked(secondSelected, {
                                // id: 'first',
                                type: 'radio',
                                name,
                                value: 'second',
                                'aria-checked': secondSelected,
                            })
                        ),
                        renderItem(options[1])
                    )
                )
            )
        );
    };
