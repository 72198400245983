/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    PointStyleConfigSimple,
    PointStyleConfigDiscrete,
    PointStyleConfig,
    PointStyleConfigContinuous,
} from '../../source';
import Feature from 'ol/Feature';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import { StyleFn, markerFont } from '.';
import { getLabelStyleFn } from './label';
import { findLow, withIndex } from './select';
import { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';

// const backgroundFill = new Fill({
//     color: 'rgba(255,255,255,0.1)',
// });

const pointStyleSimple = (config: PointStyleConfigSimple): StyleFn => {
    const labelStyle = getLabelStyleFn(config);
    let markerStyle: Text;

    if (config.marker) {
        const marker = config.marker;
        markerStyle = new Text({
            font: markerFont(marker.size),
            text: String.fromCodePoint(marker.codePoint),
            fill: new Fill({
                color: marker.color,
            }),
            textAlign: 'center',
            // backgroundFill,
        });
    }

    return (feature: FeatureLike, resolution: number) => {
        const styles: Style[] = [];
        if (markerStyle) {
            styles.push(new Style({ text: markerStyle }));
        }
        return labelStyle(feature, resolution, styles);
    };
};





const pointStyleDiscrete = (config: PointStyleConfigDiscrete) => {
    const labelStyle = getLabelStyleFn(config);
    const groups = config.groups;
    const groupStyles = groups.reduce<Style[]>((acc, group) => {
        const { marker } = group;
        acc.push(new Style({
            text: new Text({
                font: markerFont(marker.size),
                text: String.fromCodePoint(marker.codePoint),
                fill: new Fill({
                    color: marker.color,
                }),
                textAlign: 'center',
                // backgroundFill,
            }),
        }));
        return acc;
    }, []);

    return (feature: Feature<Geometry>, resolution: number) => {
        const styles: Style[] = [];
        const props = feature.getProperties();
        const value = props[config.propName];
        withIndex(value, groups)
            .map(idx => styles.push(groupStyles[idx]))

        return labelStyle(feature, resolution, styles);
    };
};

type StyleReg = { [k: number]: Style };

const pointStyleContinuous = (config: PointStyleConfigContinuous) => {
    const labelStyle = getLabelStyleFn(config);
    const intervals = config.intervals;
    const intervalStyles = intervals.reduce<StyleReg>((acc, itv) => {
        const { marker } = itv;
        acc[itv.low] = new Style({
            text: new Text({
                font: markerFont(marker.size),
                text: String.fromCodePoint(marker.codePoint),
                fill: new Fill({
                    color: marker.color,
                }),
                textAlign: 'center',
                // backgroundFill,
            }),
        });
        return acc;
    }, {});


    return (feature: Feature<Geometry>, resolution: number) => {
        const styles: Style[] = [];
        const props = feature.getProperties();

        findLow(props[config.propName], intervals)
            .map(low => styles.push(intervalStyles[low]));

        return labelStyle(feature, resolution, styles);
    };
};

const pointStyle = (config: PointStyleConfig): StyleFn => {
    switch (config.kind) {
        case 'point-simple':
            return pointStyleSimple(config);
        case 'point-continuous':
            return pointStyleContinuous(config);
        case 'point-discrete':
            return pointStyleDiscrete(config);
    }
};

export default pointStyle;
