import { makeRecord } from "sdi/source";
import { fromRecord } from "sdi/locale";

export function attribution() {
  return `Infrastructures rurales productives en Mauritanie`;
}

export function credits() {
  return fromRecord(
    makeRecord(
      "Fond de plan: © OpenStreetMap",
      "Map background: © OpenStreetMap"
    )
  );
}
