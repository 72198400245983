/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import * as interaction from 'ol/interaction';
import * as condition from 'ol/events/condition';

// import { fontSizeExtractRegexp, fontSizeReplaceRegexp } from '../style';
import {
    withInteraction,
    SelectOptions,
    InteractionSelect,
    FeaturePath,
    SingleSelectOptions,
    MultiSelectOptions,
} from '..';
import { ensureArray, isNotNullNorUndefined } from '../../util';
import { fromNullable, none, some, Option } from 'fp-ts/lib/Option';
import Geometry from 'ol/geom/Geometry';
import { VectorLayer } from '../map';

const logger = debug('sdi:map/select');

// const fontSizeIncrement = (s: string) => {
//     const result = fontSizeExtractRegexp.exec(s);
//     if (!result) {
//         return s;
//     }
//     if (result.length !== 2) {
//         return s;
//     }
//     const ret = parseFloat(result[1]) * 1.3;
//     if (isNaN(ret)) {
//         return s;
//     }
//     return s.replace(fontSizeReplaceRegexp,
//         (_m: string, p1: string, p2: string) => (
//             `${p1} ${ret.toFixed(1)}px ${p2}`
//         ));
// };

// const getSelectionStyleForPoint = (style: olStyle.Style) => {
//     const text = style.getText();
//     if (text && text.getText()) {
//         return (new olStyle.Style({
//             text: new olStyle.Text({
//                 font: fontSizeIncrement(text.getFont()),
//                 text: text.getText(),
//                 textAlign: text.getTextAlign(),
//                 textBaseline: text.getTextBaseline(),
//                 offsetX: text.getOffsetX(),
//                 offsetY: text.getOffsetY(),
//                 fill: new olStyle.Fill({
//                     color: '#3FB2FF',
//                 }),
//                 stroke: new olStyle.Stroke({
//                     width: 2,
//                     color: 'white',
//                 }),
//             }),
//         }));
//     }
//     return (new olStyle.Style());
// };

const getSelectionStyleForPolygon = (style: Style[] | null) => {
    const selectStyle = [
        new Style({
            stroke: new Stroke({
                width: 7,
                color: 'white',
            }),
        }),
        new Style({
            stroke: new Stroke({
                width: 2,
                color: '#3FB2FF',
            }),
        }),
    ];

    return fromNullable(style)
        .map(s => s.concat(selectStyle))
        .getOrElse(selectStyle);
};

const getSelectionStyleForPoint = (style: Style[] | null) => {
    const selectStyle = new Style({
        image: new Circle({
            radius: 12,
            fill: new Fill({
                color: '#3FB2FF',
            }),
            stroke: new Stroke({
                width: 2,
                color: 'white',
            }),
        }),
    });
    // Get the label from original style to keep it on selection
    // !fragile: we take for granted that the label-text is the last element in the style array
    // (icons are also considered as label and we don't want to keep them)
    return fromNullable(style)
        .chain(s => {
            if (s.length > 1) {
                return some(
                    s.reduce((acc, val) => {
                        acc.setText(val.getText());
                        return acc;
                    }, selectStyle)
                );
            }
            return none as Option<Style>;
        })
        .getOrElse(selectStyle);
};
const getSelectionStyleForLine = (style: Style[] | null) => {
    const selectStyle = [
        new Style({
            stroke: new Stroke({
                width: 4,
                color: 'white',
            }),
        }),
        new Style({
            stroke: new Stroke({
                width: 2,
                color: '#3FB2FF',
            }),
        }),
    ];
    return fromNullable(style)
        .map(s => s.concat(selectStyle))
        .getOrElse(selectStyle);
};

const getStylesForFeature = (
    layers: Collection<VectorLayer>,
    f: Feature,
    res: number
): Style[] | null => {
    // const fn = f.getStyleFunction();
    // if (fn) {
    //     return ensureArray<Style>(fn.call(f, res));
    // }
    // const fs = f.getStyle();
    // if (fs) {
    //     if (typeof fs === 'function') {
    //         return ensureArray<Style>(fs.call(f, res));
    //     }
    //     return ensureArray(fs);
    // }

    const layerRef = fromNullable(f.get('lid')).chain(lid =>
        fromNullable(layers.getArray().find(l => l.get('id') === lid))
    );

    return layerRef
        .map(layer => {
            const layerFn = layer.getStyleFunction();
            if (layerFn) {
                const s = layerFn(f, res);
                if (s && ensureArray(s).length > 0) {
                    return ensureArray(s);
                }
            }

            const layerFs = layer.getStyle();
            if (layerFs) {
                if (typeof layerFs === 'function') {
                    const s = layerFs(f, res);
                    if (s) {
                        const styleArray = ensureArray(s);
                        if (styleArray.length > 0) {
                            return styleArray;
                        }
                    }
                } else {
                    return ensureArray(layerFs);
                }
            }
            return null;
        })
        .getOrElse(null);
};
// const selectionStyle =
//     (layers: Collection<layer.Vector>) =>
//         (f: Feature, res: number) => {
//             const geometryType = f.getGeometry().getType();
//             if (geometryType === 'Point') {
//                 const styles = getStylesForFeature(layers, f, res);
//                 if (styles) {
//                     return styles.map(getSelectionStyleForPoint);
//                 }
//             }
//             else if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
//                 return [
//                     new olStyle.Style({
//                         stroke: new olStyle.Stroke({
//                             width: 4,
//                             color: 'white',
//                         }),
//                     }),
//                     new olStyle.Style({
//                         stroke: new olStyle.Stroke({
//                             width: 2,
//                             color: '#3FB2FF',
//                         }),
//                     }),
//                 ];
//             }

//             return [new olStyle.Style({
//                 fill: new olStyle.Fill({
//                     color: '#3FB2FF',
//                 }),
//                 stroke: new olStyle.Stroke({
//                     width: 2,
//                     color: 'white',
//                 }),
//             })];
//         };

const selectionStyle =
    (layers: Collection<VectorLayer>) =>
    (f: Feature<Geometry>, res: number) => {
        const geom = f.getGeometry();
        const styles = getStylesForFeature(layers, f, res);

        if (geom !== undefined) {
            const geometryType = geom.getType();
            if (geometryType === 'Point' || geometryType === 'MultiPoint') {
                return getSelectionStyleForPoint(styles);
            } else if (
                geometryType === 'LineString' ||
                geometryType === 'MultiLineString'
            ) {
                return getSelectionStyleForLine(styles);
            }
            return getSelectionStyleForPolygon(styles);
        }
        logger(`GEOM: ${geom}`);
        return [];
    };

const initSingle = (
    options: SingleSelectOptions,
    selectedFeature: Collection<Feature<Geometry>>,
    layers: Collection<VectorLayer>
) => {
    const selecteHandler = () => {
        logger('selectInteraction.on select');
        if (selectedFeature.getLength() > 0) {
            const f = selectedFeature.item(0);
            const lid = f.get('lid') as string;
            fromNullable(f.getId()).map(fid => options.selectFeature(lid, fid));
            selectedFeature.clear();
            selectedFeature.push(f);
        } else {
            options.clearSelection();
        }
    };
    const selectInteraction = new interaction.Select({
        style: selectionStyle(layers),
        features: selectedFeature,
        condition: condition.click,
        multi: false,
        hitTolerance: 3,
        filter: options.filter,
    });
    selectInteraction.on('select', selecteHandler);

    const syncSelection = () => {
        const { featureId, layerId } = options.getSelected();
        selectedFeature.clear();
        if (layerId !== null && featureId !== null) {
            fromNullable(
                layers.getArray().find(layer => layer.get('id') === layerId)
            )
                .map(layer => layer.getSource())
                .chain(source =>
                    fromNullable(source?.getFeatureById(featureId))
                )
                .map(feature => selectedFeature.push(feature));
        }
    };

    return { selectInteraction, syncSelection };
};

const initMulti = (
    options: MultiSelectOptions,
    selectedFeature: Collection<Feature<Geometry>>,
    layers: Collection<VectorLayer>
) => {
    const selecteHandler = () => {
        const optSelect = options.selectFeatures;
        const ps: FeaturePath[] = selectedFeature.getArray().map(f => ({
            layerId: fromNullable(f.get('lid')).toNullable(),
            featureId: fromNullable(f.getId()).getOrElse('__None__'),
        }));
        optSelect(ps);
    };
    const selectInteraction = new interaction.Select({
        style: selectionStyle(layers),
        features: selectedFeature,
        condition: condition.click,
        multi: true,
        hitTolerance: 3,
        filter: options.filter,
    });
    selectInteraction.on('select', selecteHandler);

    const selectFeature = ({ layerId, featureId }: FeaturePath) => {
        if (layerId !== null && featureId !== null) {
            const ls = layers.getArray();
            for (let i = 0; i < ls.length; i += 1) {
                const l = ls[i];
                const lid = l.get('id');
                if (lid === layerId) {
                    const f = l.getSource()?.getFeatureById(featureId);
                    if (isNotNullNorUndefined(f)) {
                        selectedFeature.push(f);
                    }
                }
            }
        }
    };

    const syncSelection = () => {
        const stateSelection = options.getSelected();
        selectedFeature.clear();
        stateSelection.forEach(selectFeature);
    };

    return { selectInteraction, syncSelection };
};

export const select = (
    options: SelectOptions,
    layers: Collection<VectorLayer>
) => {
    const selectedFeature = new Collection<Feature<Geometry>>();

    const { selectInteraction, syncSelection } = (() => {
        switch (options.tag) {
            case 'single':
                return initSingle(options, selectedFeature, layers);
            case 'multi':
                return initMulti(options, selectedFeature, layers);
        }
    })();

    const init = (map: Map) => {
        map.addInteraction(selectInteraction);
    };

    const update = withInteraction<InteractionSelect>(
        'select',
        () => {
            syncSelection();
            selectInteraction.setActive(true);
        },
        () => {
            syncSelection();
            selectInteraction.setActive(false);
        }
    );

    return { init, update };
};

logger('loaded');
