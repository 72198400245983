import { assign, assignK, dispatch, observe, query } from '../shape';
import {
    getRoot,
    getAppManifest,
    getApiUrl,
    pictoFontName,
    labelFontName,
} from './queries';
import {
    MessageRecordLang,
    fetchIO,
    appDisplayName,
    fetchAlias,
    TermListIO,
    logoutUser,
} from '../source';
import { ActivityTokenIO } from '../source/io/activity/base';
import { activityURLs } from '../activity';
import { fromRecord } from '../locale';
import { Nullable, updateCollection } from '../util';
import { DomainListIO } from '../source/io/domain';

// here a bit of a hack, because...
let currentAppname: null | string = null;

export const tryLogout = () =>
    logoutUser(getApiUrl('auth/logout')).then(() =>
        window.location.assign(getRoot())
    );

export const setApplicationTitle = (codename: string) =>
    getAppManifest(codename)
        .chain(appDisplayName)
        .map(fromRecord)
        .map(name => {
            currentAppname = codename;
            const head = document.head;
            const title = document.createElement('title');
            title.appendChild(document.createTextNode(name));
            head.querySelectorAll('title').forEach(t => head.removeChild(t));
            head.appendChild(title);
        });

export const setLang = (lang: MessageRecordLang) => {
    document.body.parentElement?.setAttribute('lang', lang);
    dispatch('app/lang', () => lang);
    if (currentAppname !== null) {
        setApplicationTitle(currentAppname);
    }
};

export const setRoute = (frags: string[]) =>
    assign(
        'app/route',
        frags.map(frag => frag.trim()).filter(frag => frag.length > 0)
    );

export const observeLang = (f: (l: MessageRecordLang) => void) =>
    observe('app/lang', f);

export const navigateRoot = () => window.location.assign(getRoot());

export const setActivityToken = () =>
    fetchIO(ActivityTokenIO, activityURLs.token).then(tok =>
        dispatch('app/activityToken', () => tok.token)
    );

export const loadAlias = (url: string) => {
    fetchAlias(url).then(alias => {
        dispatch('data/alias', () => alias);
    });
};

export const loadTerms = () =>
    fetchIO(TermListIO, getApiUrl('geodata/angled/r/term')).then(
        assignK('data/terms')
    );

export const loadDomains = () =>
    fetchIO(DomainListIO, getApiUrl('geodata/angled/r/domain')).then(
        assignK('data/domains')
    );

export const loadTermsAndDomains = () => {
    loadTerms().then(loadDomains);
};

export const selectAppList = () =>
    dispatch('app/appselect/visible', () => true);
export const deselectAppList = () =>
    dispatch('app/appselect/visible', () => false);

// tslint:disable-next-line: variable-name
export const __forceRefreshState = () =>
    dispatch('app/force-refresh', n => n + 1);

export const setFocusId = (id: Nullable<string>) =>
    dispatch('app/focus-id', () => id);

export const setCollapsible = (name: string, render: boolean) =>
    dispatch('app/collapsible', s => updateCollection(s, name, render));

// Collapse all wrappers except the selected one
export const setOneCollapsible = (name: string) =>
    dispatch('app/collapsible', col => {
        col = {};
        col[name] = true;
        return col;
    });

export const clearCollapsible = () => dispatch('app/collapsible', () => ({}));

export const setSelectOpen = (id: string) => assign('app/select/open/id', id);

export const setReadMore = (name: string, render: boolean) =>
    dispatch('app/collapsible', s => updateCollection(s, name, render));

export const loadFonts = () => {
    const { picto, label } = query('app/fonts');

    const pictoFF = new FontFace(pictoFontName, `url(${picto.url})`);
    pictoFF.load().then(face => {
        document.fonts.add(face);
        dispatch('app/fonts', fonts => ({
            ...fonts,
            picto: {
                url: picto.url,
                loaded: true,
            },
        }));
    });

    const labelFF = new FontFace(labelFontName, `url(${label.url})`);
    labelFF.load().then(face => {
        document.fonts.add(face);
        dispatch('app/fonts', fonts => ({
            ...fonts,
            label: {
                url: label.url,
                loaded: true,
            },
        }));
    });
};
