import { fromNullable, none, Option, some } from "fp-ts/lib/Option";
import { Coordinate } from "ol/coordinate";
import { getLang } from "../app";
import { query } from "../shape";
import { ServiceDescription, ServiceResponse } from "./io";
import { RemoteResource } from "../source";


const remoteGeocoderToOption = <D, E>(rr: RemoteResource<D, E>) => {
    switch (rr.tag) {
        case 'success':
            return some(rr.data);
        case 'error':
            return some({results: [], error: rr.error});
        case 'loading':
        default:
            return none;
    }
};

export const getServiceResponse = (): Option<ServiceResponse> =>
    fromNullable(query('app/geocoder').response).chain(r => remoteGeocoderToOption(r));

const coordSearchService: ServiceDescription = {
    code: 'coordinate',
    name: {
        fr: 'Coordonnées',
        nl: 'Coördinaten'
    },
    placeholder: {
        fr: 'X Y',
        nl: 'X Y',
    },
}

export const getAllServices = (withCoord: boolean): ServiceDescription[] =>
    withCoord ? query('app/geocoder').serviceList.concat(coordSearchService) : query('app/geocoder').serviceList;

export const getAllServicesCodes = (withCoord: boolean) =>
    getAllServices(withCoord).map(s => s.code);

export const getServiceName = (serviceCode: string) =>
    fromNullable(getAllServices(true).find(s => s['code'] === serviceCode))
        .chain(s => fromNullable(s['name']))
        .chain(n => fromNullable(n[getLang()]))
        .getOrElse('');

export const getServicePlaceholder = (serviceCode: string) =>
    fromNullable(getAllServices(true).find(s => s['code'] === serviceCode))
        .chain(s => fromNullable(s['placeholder']))
        .chain(n => fromNullable(n[getLang()]))
        .getOrElse('');

export const getSelectedService = () =>
    fromNullable(query('app/geocoder').selected);

export const getTerm = () =>
    query('app/geocoder/input');

export const getSearchCoordinates = (): Option<Coordinate> =>
    fromNullable(query('app/geocoder/input/coord')).map(c => c as Coordinate);
