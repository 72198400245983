import * as io from 'io-ts';
import { nullable } from '../source/io/io';
import { MessageRecordIO, MessageRecordLangIO } from '../source/io/message';



export const ServiceKeysIO = io.interface({
    key: io.string,
    label: MessageRecordIO
})

export const ServiceDescriptionIO = io.interface({
    code: io.string,
    name: MessageRecordIO,
    placeholder: nullable(MessageRecordIO)
})
export type ServiceDescription = io.TypeOf<typeof ServiceDescriptionIO>;


const ServiceRequestIO = io.interface({
    service: io.string,
    input: io.string,
    lang: MessageRecordLangIO,
    // proj: io.string,
});
export type ServiceRequest = io.TypeOf<typeof ServiceRequestIO>;

const ServiceResultIO = io.interface({
    coord: io.interface({ y: io.number, x: io.number }),
    message: io.union([MessageRecordIO, io.string]),
});
export type ServiceResult = io.TypeOf<typeof ServiceResultIO>;

export const ServiceResponseIO = io.interface({
    results: io.array(ServiceResultIO),
    error: nullable(io.string),
});
export type ServiceResponse = io.TypeOf<typeof ServiceResponseIO>;
