import tr, { fromRecord } from 'sdi/locale';

import {
    DIV,
    H2,
    NODISPLAY,
    BUTTON,
    NODISPLAYBUTTON,
} from 'sdi/components/elements';
import { navigateMap } from '../events/route';
import { getLinks } from '../queries/map';

export const renderRelated = () => {
    const links = getLinks('forward')
        .fold([NODISPLAYBUTTON()], ls =>
            ls
                .filter(l => l.status === 'published')
                .map(l =>
                    BUTTON(
                        {
                            className: 'link forward',
                            onClick: () => navigateMap(l.id || '###'),
                        },
                        fromRecord(l.title)
                    )
                )
        )
        .concat(
            getLinks('backward').fold([NODISPLAYBUTTON()], ls =>
                ls
                    .filter(l => l.status === 'published')
                    .map(l =>
                        BUTTON(
                            {
                                className: 'link backward',
                                onClick: () => navigateMap(l.id || '###'),
                            },
                            fromRecord(l.title)
                        )
                    )
            )
        );

    if (links.length > 0) {
        return DIV(
            { className: 'related-maps' },
            H2({}, tr.view('relatedMapsLabel')),
            ...links
        );
    }

    return NODISPLAY();
};
