/*
*  Copyright (C) 2019 Atelier Cartographique <contact@atelier-cartographique.be>
*
*  This program is free software: you can redistribute it and/or modify
*  it under the terms of the GNU General Public License as published by
*  the Free Software Foundation, version 3 of the License.
*
*  This program is distributed in the hope that it will be useful,
*  but WITHOUT ANY WARRANTY; without even the implied warranty of
*  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
*  GNU General Public License for more details.
*
*  You should have received a copy of the GNU General Public License
*  along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/

import { AllHTMLAttributes } from 'react';
import { Translated } from '../locale';
import { BUTTON, DIV, ElementFactory, HTMLTagName, SPAN } from './elements';

declare module 'react' {
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        'data-tooltip'?: string;
        'data-tooltip-position'?: string;
    }
}

export type TooltipText = Translated | (() => Translated)


export type TooltipPosition = 'left' | 'right' | 'bottom' | 'top' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
export interface Tooltip {
    position: TooltipPosition;
    text: TooltipText;
}

export const tooltipConfig = (text: TooltipText): Tooltip => ({ position: 'top', text })

type Attrs = AllHTMLAttributes<HTMLElement>;

// type DivT = typeof DIV
// type ButT = typeof BUTTON

const tooltip = (
    pos: TooltipPosition
) => <T extends HTMLTagName>(
    ctor: ElementFactory<T>
) => (
    content: TooltipText,
    attrs: Attrs,
    ...children: React.ReactNode[]
) => {
            const contentText = typeof content === 'function' ? content() : content;
            const updatedAttributes: Attrs = Object.assign({}, attrs, {
                'data-tooltip-position': pos as string,
                'data-tooltip': contentText,
                'aria-label': contentText,
            });
            return ctor(updatedAttributes, ...children);
        };


export const tooltipLeft = tooltip('left');
export const tooltipRight = tooltip('right');
export const tooltipTop = tooltip('top');
export const tooltipTopRight = tooltip('top-right');
export const tooltipTopLeft = tooltip('top-left');
export const tooltipBottom = tooltip('bottom');
export const tooltipBottomRight = tooltip('bottom-right');
export const tooltipBottomLeft = tooltip('bottom-left');

export const buttonTooltipLeft = tooltipLeft(BUTTON);
export const buttonTooltipRight = tooltipRight(BUTTON);
export const buttonTooltipTop = tooltipTop(BUTTON);
export const buttonTooltipTopRight = tooltipTopRight(BUTTON);
export const buttonTooltipTopLeft = tooltipTopLeft(BUTTON);
export const buttonTooltipBottom = tooltipBottom(BUTTON);
export const buttonTooltipBottomRight = tooltipBottomRight(BUTTON);
export const buttonTooltipBottomLeft = tooltipBottomLeft(BUTTON);

export const buttonTooltip = (tooltip: Tooltip, attrs: Attrs, ...childrens: React.ReactNode[]) => {
    switch (tooltip.position) {
        case 'left': return buttonTooltipLeft(tooltip.text, attrs, childrens);
        case 'right': return buttonTooltipRight(tooltip.text, attrs, childrens);
        case 'top': return buttonTooltipTop(tooltip.text, attrs, childrens);
        case 'top-right': return buttonTooltipTopRight(tooltip.text, attrs, childrens);
        case 'top-left': return buttonTooltipTopLeft(tooltip.text, attrs, childrens);
        case 'bottom': return buttonTooltipBottom(tooltip.text, attrs, childrens);
        case 'bottom-right': return buttonTooltipBottomRight(tooltip.text, attrs, childrens);
        case 'bottom-left': return buttonTooltipBottomLeft(tooltip.text, attrs, childrens);
    }
}

export const divTooltipLeft = tooltipLeft(DIV);
export const divTooltipRight = tooltipRight(DIV);
export const divTooltipTop = tooltipTop(DIV);
export const divTooltipTopRight = tooltipTopRight(DIV);
export const divTooltipTopLeft = tooltipTopLeft(DIV);
export const divTooltipBottom = tooltipBottom(DIV);
export const divTooltipBottomRight = tooltipBottomRight(DIV);
export const divTooltipBottomLeft = tooltipBottomLeft(DIV);

export const spanTooltipLeft = tooltipLeft(SPAN);
export const spanTooltipRight = tooltipRight(SPAN);
export const spanTooltipTop = tooltipTop(SPAN);
export const spanTooltipTopRight = tooltipTopRight(SPAN);
export const spanTooltipTopLeft = tooltipTopLeft(SPAN);
export const spanTooltipBottom = tooltipBottom(SPAN);
export const spanTooltipBottomRight = tooltipBottomRight(SPAN);
export const spanTooltipBottomLeft = tooltipBottomLeft(SPAN);

