/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    Attachment,
    AttachmentIO,
    Category,
    CategoryCollectionIO,
    FeatureCollection,
    fetchIO,
    fetchPaginatedIO,
    fetchWithoutValidationIO,
    IAliasCollection,
    IAliasCollectionIO,
    IMapBaseLayer,
    IMapBaseLayerIO,
    IMapInfo,
    IMapInfoIO,
    Inspire,
    InspireIO,
    ITimeserie,
    ITimeserieIO,
    IUser,
    IUserIO,
    MapLinkListIO,
    GeometryObject,
    postUnrelatedIO,
    FeatureCollectionIO,
    IServiceBaseLayersIO,
    IServiceBaseLayers,
} from 'sdi/source';

import * as io from 'io-ts';

interface HarvestRequest {
    metadata: string;
    geometry: GeometryObject;
}

// tslint:disable-next-line: variable-name
// const BaseLayerCollectionIO = io.dictionary(io.string, IMapBaseLayerIO, 'BaseLayerCollection');
// type BaseLayerCollection = io.TypeOf<typeof BaseLayerCollectionIO>;

export const fetchLayer = (url: string): Promise<FeatureCollection> =>
    fetchWithoutValidationIO(url);
export const fetchBaseLayer = (url: string): Promise<IMapBaseLayer> =>
    fetchIO(IMapBaseLayerIO, url);
export const fetchBaseLayerAll = (url: string): Promise<IServiceBaseLayers> =>
    fetchIO(IServiceBaseLayersIO, url);
export const fetchAllMaps = (url: string): Promise<IMapInfo[]> =>
    fetchIO(io.array(IMapInfoIO), url);
export const fetchMap = (url: string): Promise<IMapInfo> =>
    fetchIO(IMapInfoIO, url);
export const fetchAlias = (url: string): Promise<IAliasCollection> =>
    fetchIO(IAliasCollectionIO, url);
export const fetchUser = (url: string): Promise<IUser> => fetchIO(IUserIO, url);
export const fetchUsers = (url: string): Promise<IUser[]> =>
    fetchIO(io.array(IUserIO), url);

export const fetchDatasetMetadata = (url: string): Promise<Inspire> =>
    fetchIO(InspireIO, url);
export const fetchAllDatasetMetadata = (url: string) =>
    fetchPaginatedIO(InspireIO, url);

export const fetchTimeserie = (url: string): Promise<ITimeserie> =>
    fetchIO(ITimeserieIO, url);
export const fetchCategories = (url: string): Promise<Category[]> =>
    fetchIO(CategoryCollectionIO, url);
export const fetchAttachment = (url: string): Promise<Attachment> =>
    fetchIO(AttachmentIO, url);

export const fetchLinks = (url: string) => fetchIO(MapLinkListIO, url);

export const postHarvestRequest = (url: string, request: HarvestRequest) =>
    postUnrelatedIO(FeatureCollectionIO, url, request);
