import { FeatureCollection, Inspire } from './io';
import { Extent } from 'ol/extent';

import { fetchIO, fetchWithoutValidationIO } from './remote';
import { InspireIO } from './io';
import { getApiUrl } from '../app';

export const fetchLayer = (url: string): Promise<FeatureCollection> =>
    fetchWithoutValidationIO(url);

export const fetchLayerWithExtent = (
    url: string,
    [minx, miny, maxx, maxy]: Extent
) => fetchLayer(`${url}?bbox=${minx},${miny},${maxx},${maxy}`);

// export const fetchBaseLayer = (url: string): Promise<IMapBaseLayer> =>
//     fetchIO(IMapBaseLayerIO, url);
// export const fetchBaseLayerAll = (url: string): Promise<IServiceBaseLayers> =>
//     fetchIO(IServiceBaseLayersIO, url);
// export const fetchAllMaps = (url: string): Promise<IMapInfo[]> =>
//     fetchIO(io.array(IMapInfoIO), url);
// export const fetchMap = (url: string): Promise<IMapInfo> =>
//     fetchIO(IMapInfoIO, url);
// export const fetchAlias = (url: string): Promise<IAliasCollection> =>
//     fetchIO(IAliasCollectionIO, url);
// export const fetchUser = (url: string): Promise<IUser> => fetchIO(IUserIO, url);
// export const fetchUsers = (url: string): Promise<IUser[]> =>
//     fetchIO(io.array(IUserIO), url);

export const fetchDatasetMetadata = (id: string): Promise<Inspire> =>
    fetchIO(InspireIO, getApiUrl(`metadatas/${id}`));
// export const fetchAllDatasetMetadata = (url: string) =>
//     fetchPaginatedIO(InspireIO, url);
