import { InputAttributes } from '.';
import {
    DIV,
    FIELDSET,
    INPUT,
    LABEL,
    LEGEND,
    NodeOrOptional,
    SPAN,
} from '../elements';

const activeClass = (a: boolean) => (a ? 'selected' : 'unselected');
const beforeSelectedClass = (b: boolean) => (b ? 'before-selected' : '');
const withChecked = (c: boolean, attrs: InputAttributes) =>
    c ? { ...attrs, checked: true } : attrs;

export const radioRenderer =
    <T>(
        name: string,
        renderItem: (i: T) => NodeOrOptional,
        select: (i: T) => void,
        isSelected: (i: T) => boolean,
        label?: string
    ) =>
    (options: T[], runtimeClassName = '') => {
        let selectedIndex = options.length;
        return FIELDSET(
            `radio__fieldset`,
            LEGEND(`radio__legend`, label),

            DIV(
                {
                    className: `${runtimeClassName} radio__body length-${options.length}`,
                },
                options.map((o, i) => {
                    const itemSelected = isSelected(o);
                    if (itemSelected) {
                        selectedIndex = i;
                    }
                    return DIV(
                        {
                            className: `radio__item item-${i + 1} ${activeClass(
                                itemSelected
                            )} ${beforeSelectedClass(i < selectedIndex)}`,
                            key: `radio-${name}-${i}`,
                            onClick: () => select(o),
                        },
                        LABEL(
                            {
                                // for: i.toString(),
                                // htmlFor: 'first'
                            },
                            INPUT(
                                withChecked(itemSelected, {
                                    // id: 'first',
                                    type: 'radio',
                                    name,
                                    value: i,
                                    'aria-checked': itemSelected,
                                })
                            ),
                            SPAN(
                                'radio__control',
                                SPAN('radio__control-inner')
                            ),
                            renderItem(o)
                        )
                    );
                })
            )
        );
    };
