import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import { Extent } from 'ol/extent';

import { some, none, fromNullable } from 'fp-ts/lib/Option';

import { scopeOption } from '../../lib/scope';
import {
    ExtractOptions,
    withInteraction,
    ExtractFeature,
    InteractionExtract,
} from '..';
import Geometry from 'ol/geom/Geometry';
import { VectorLayer } from '../map';
import { tryTuple4 } from '../../util/index';

interface FeatureExtract {
    lid: string;
    fs: Feature<Geometry>[];
}

interface MapAndLayers {
    map: Map;
    layers: Collection<VectorLayer>;
}

interface MapAndLayersAndExtent extends MapAndLayers {
    extent: [number, number, number, number];
}

const getExtent = ({ map }: MapAndLayers) =>
    some(map.getView().calculateExtent(map.getSize()));

const sameExtent = (e1: Extent, e2: Extent) =>
    e1[0] === e2[0] && e1[1] === e2[1] && e1[2] === e2[2] && e1[3] === e2[3];

const fromExtract = ({ fs, lid }: FeatureExtract): ExtractFeature[] =>
    fs.map(f => ({
        layerId: lid,
        featureId: f.getId() ?? '__None__',
    }));

const extractFeatures = ({ layers, extent }: MapAndLayersAndExtent) =>
    layers
        .getArray()
        .map(l => ({
            lid: l.get('id'),
            fs: fromNullable(l.getSource())
                .map(source => source.getFeaturesInExtent(extent))
                .getOrElse([]),
        }))
        .reduce<ExtractFeature[]>((acc, fe) => acc.concat(fromExtract(fe)), []);

export const extract = ({ setCollection }: ExtractOptions) => {
    let mapRef: Map;
    let colRef: Collection<VectorLayer>;
    let lastExtent: Extent = [0, 0, 0, 0];

    const update = withInteraction<InteractionExtract>(
        'extract',
        () =>
            scopeOption()
                .let('map', fromNullable(mapRef))
                .let('layers', fromNullable(colRef))
                .let('extent', e =>
                    getExtent(e).chain<[number, number, number, number]>(e =>
                        tryTuple4(e)
                    )
                )
                .let('shouldUpdate', ({ extent }) => {
                    const su = !sameExtent(lastExtent, extent);
                    if (su) {
                        lastExtent = extent;
                        return some(true);
                    }
                    return none;
                })
                .map(extractFeatures)
                .map(setCollection),
        () => {
            lastExtent = [0, 0, 0, 0];
        }
    );

    const init = (map: Map, layers: Collection<VectorLayer>) => {
        mapRef = map;
        colRef = layers;
    };

    return { init, update };
};
