import { DIV, H2 } from 'sdi/components/elements';
import { stringToParagraphs } from 'sdi/util';
import tr, { Translated } from 'sdi/locale';

import { setLayout } from 'view/src/events/app';
import { AppLayout } from 'view/src/shape/types';
import { makeLabelAndIcon } from '../button';
import { getGeometry, getGeometryType } from 'view/src/queries/harvest';
import { startDrawing, endHarvest } from 'view/src/events/harvest';
import { makeLabel } from 'sdi/components/button';
import { GeometryType, IMapInfo } from 'sdi/source';
import { renderMapData } from '../legend';
import { renderRelated } from '../related-maps';

const buttonCancel = makeLabelAndIcon('cancel', 2, 'times', () =>
    tr.view('clearFilter')
);

const buttonQueryPage = makeLabelAndIcon('navigate', 1, 'file', () =>
    tr.view('goToQueryPage')
);

const harvestPointButton = makeLabel('info', 2, () =>
    tr.view('harvestInitiatePoint')
);
const harvestLineButton = makeLabel('info', 2, () =>
    tr.view('harvestInitiateLine')
);
const harvestPolygonButton = makeLabel('info', 2, () =>
    tr.view('harvestInitiatePolygon')
);

const renderTitle = () => H2({}, tr.view('harvestTitle'));

const wrapInfo = (t: Translated) => DIV({}, stringToParagraphs(t));

const renderInfo = () => {
    const gt = getGeometryType().getOrElse('Polygon');
    switch (gt) {
        case 'Point':
        case 'MultiPoint':
            return wrapInfo(tr.view('harvestInfoPoint'));
        case 'LineString':
        case 'MultiLineString':
            return wrapInfo(tr.view('harvestInfoLine'));
        case 'Polygon':
        case 'MultiPolygon':
            return wrapInfo(tr.view('harvestInfoPolygon'));
    }
};
const initiateHarvest = (gt: GeometryType) => {
    startDrawing(gt);
};

const renderInitial = () =>
    DIV(
        'sidebar-main tool spatial-filter',
        H2({}, tr.view('harvestTitle')),
        DIV('', tr.view('spatialFilterHelpText')),
        DIV(
            'filter-selection__wrapper ',
            harvestPointButton(() => initiateHarvest('Point')),
            harvestLineButton(() => initiateHarvest('LineString')),
            harvestPolygonButton(() => initiateHarvest('Polygon'))
        )
    );

const renderRunning = (mapInfo: IMapInfo) =>
    DIV(
        'sidebar-main tool spatial-filter activated',
        renderTitle(),
        renderInfo(),

        DIV(
            'filter-action__wrapper',
            getGeometry().map(() =>
                buttonQueryPage(() => setLayout(AppLayout.Query))
            ),
            buttonCancel(endHarvest)
        ),
        getGeometry().map(() =>
            DIV('', renderMapData(mapInfo, false), renderRelated())
        )
    );

export const renderHarvest = (mapInfo: IMapInfo) =>
    getGeometryType().foldL(renderInitial, () => renderRunning(mapInfo));

export default renderHarvest;
