import { some } from 'fp-ts/lib/Option';

import { Feature, getFeatureProp } from 'sdi/source';
import { DIV, SPAN, H3 } from 'sdi/components/elements';
import tr from 'sdi/locale';
import { helpText } from 'sdi/components/helptext';

import { viewEvents, startPointerPosition } from '../../events/map';
import { openBookmarkModal } from '../../events/modal';
import {
    addBookmark,
    addBookmarkFromMark,
    removeBookmark,
    setBookmarkIndex,
} from '../../events/bookmark';
import { getBookmarks } from '../../queries/bookmark';

import { makeIcon, makeLabelAndIcon } from '../button';
import { setFocusId } from 'sdi/app';

const zoomBookmarkButton = makeIcon('zoomOnFeature', 3, 'search', {
    text: () => tr.view('zoomOnFeature'),
    position: 'top-right',
});
const removeBookmarkButton = makeIcon('remove', 3, 'trash-alt', {
    text: () => tr.view('remove'),
    position: 'top-right',
});
const editBookmarkButton = makeIcon(
    'edit',
    3,
    'edit',
    { text: () => tr.view('editBookmark'), position: 'top-left' },
    some(`bookmark-edit-button`)
);

const renderBookmark = (f: Feature, idx: number) =>
    DIV(
        {
            className: 'layer-item bookmark',
        },
        SPAN({ className: 'bookmark-name' }, getFeatureProp(f, 'name', '~')),
        DIV(
            { className: 'layer-actions bookmark' },
            zoomBookmarkButton(() =>
                viewEvents.updateMapView({
                    dirty: 'geo/feature',
                    feature: f,
                })
            ),

            removeBookmarkButton(() =>
                removeBookmark(getFeatureProp(f, 'name', '~'))
            ),

            editBookmarkButton(() => {
                setBookmarkIndex(idx);
                openBookmarkModal();
                setFocusId('bookmark-modal-input');
            })
        )
    );

const addBookmarkBtn = makeLabelAndIcon('add', 2, 'star', () =>
    tr.view('addBookmark')
);

export const render = () =>
    DIV(
        {
            className: 'bookmark',
        },
        H3({}, tr.view('bookmarks')),
        ...getBookmarks().features.map(renderBookmark),
        addBookmarkBtn(() =>
            addBookmarkFromMark().mapLeft(() =>
                startPointerPosition(addBookmark)
            )
        ),
        helpText(tr.view('helptext:bookmark'))
    );

export default render;
