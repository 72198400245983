/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable } from 'fp-ts/lib/Option';

import tr, { fromRecord } from 'sdi/locale';
import { IMapInfo, Attachment } from 'sdi/source';
import {
    DIV,
    H2,
    A,
    IMG,
    NODISPLAY,
    P,
    NodeOrOptional,
    SPAN,
} from 'sdi/components/elements';
import { filterNotNull } from 'sdi/util';

import { getMapInfo } from '../queries/app';
import { getAttachment } from '../queries/attachments';
import { navigateMap } from '../events/route';
import queries from '../queries/legend';
import { setImageExpand } from '../events/legend';

import { activityLogger } from '../events/app';
import { linkAction } from 'sdi/activity';
import { makeIcon } from 'sdi/components/button';
import { renderReadMore } from 'sdi/components/collapsible-wrapper';

const logger = debug('sdi:map-info');

const internalRe = new RegExp('.*/client/view/(.+)');

const renderInternal = (a: Attachment) => {
    const rs = internalRe.exec(fromRecord(a.url));
    if (rs === null) {
        return NODISPLAY();
    }
    return DIV(
        {
            className: 'link internal-nav',
            key: a.id,
            onClick: () => navigateMap(rs[1]),
        },
        fromRecord(a.name)
    );
};

const renderExternal = (a: Attachment) =>
    DIV(
        { className: 'link', key: a.id },
        A(
            {
                href: fromRecord(a.url),
                target: '_blank',
                onClick: () => activityLogger(linkAction(fromRecord(a.url))),
            },
            fromRecord(a.name)
        )
    );

const isInternal = (a: Attachment) =>
    internalRe.exec(fromRecord(a.url)) !== null;

const isExternal = (a: Attachment) => !isInternal(a);

export const renderAttachments = (info: IMapInfo) => {
    const ats = filterNotNull(
        info.attachments.map(id => getAttachment(id).fold(null, a => a))
    );

    if (ats.length > 0) {
        const ints = ats.filter(isInternal);
        const exts = ats.filter(isExternal);
        if (ints.length === 0) {
            return DIV(
                'map-attached-files',
                H2({}, tr.view('links')),
                exts.map(renderExternal)
            );
        }

        const sectionTitle =
            exts.length > 0 ? H2({}, tr.view('links')) : NODISPLAY();
        return DIV(
            'map-attached-files',
            sectionTitle,
            exts.map(renderExternal),
            DIV(
                'related-maps',
                H2({}, tr.view('relatedMapsLabel')),
                ints.map(renderInternal)
            )
        );
    }
    return NODISPLAY();
};

const expandedClass = () => (queries.expandImage() ? 'expanded' : '');

const buttonExpandImage = makeIcon('expand', 2, 'expand', {
    position: 'right',
    text: () => tr.core('expand'),
});
const buttonCollapseImage = makeIcon('collapse', 2, 'compress', {
    position: 'right',
    text: () => tr.core('collapse'),
});

const expandImage = () =>
    DIV(
        'expand-buttons__wrapper',
        queries.expandImage()
            ? buttonCollapseImage(() => setImageExpand(false))
            : buttonExpandImage(() => setImageExpand(true))
    );

const renderImage = (imageUrl: string) =>
    DIV(
        `map-illustration ${expandedClass()}`,
        expandImage(),
        IMG({ src: imageUrl, alt: '' })
    );

const MAX_WORDCOUNT = 32;

const makeParagraphs = (text: string, end?: NodeOrOptional) =>
    text
        .split('\n')
        .filter(p => p.trim().length > 0)
        .map((paragraph, i, ps) => {
            if (end !== undefined && i === ps.length - 1) {
                return P({ key: `map-desc-par-${i}` }, paragraph, end);
            }
            return P({ key: `map-desc-par-${i}` }, paragraph);
        });

export const renderDescription = (text: string) => {
    const words = text.split(/\s/);
    const wordCount = words.length;
    if (wordCount <= MAX_WORDCOUNT) {
        return DIV('map-description', makeParagraphs(text));
    }
    const shrunk = (() => {
        const targetWordCount =
            wordCount > MAX_WORDCOUNT * 2 ? MAX_WORDCOUNT : wordCount / 2;
        const charCounts =
            words
                .slice(0, targetWordCount)
                .reduce((acc, w) => acc + w.length, 0) + targetWordCount;
        return makeParagraphs(
            text.slice(0, charCounts),
            SPAN('text-expand-sym', '[…]')
        );
    })();
    return DIV(
        'map-description',
        renderReadMore('map-description', shrunk, makeParagraphs(text))
    );
};

export default () => {
    const mapInfo = getMapInfo();
    if (mapInfo) {
        const mapDescription = renderDescription(
            fromRecord(mapInfo.description)
        );
        const mapImage = fromNullable(mapInfo.imageUrl).map(renderImage);

        return DIV('map-infos', mapImage, mapDescription);
    }
    return NODISPLAY();
};

logger('loaded');
