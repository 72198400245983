/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import {
    Config,
    SortDirection,
    TableDataCell,
    TableDataRow,
    TableQuerySet,
    TableEventSet,
    TableDataType,
    CellHighlighterFn,
} from './index';
import { DIV, SPAN } from '../elements';
import { rect } from '../../app';
import tr, { Translated } from '../../locale';
import buttonFactory, { ButtonComponent, makeIcon } from '../button';
import { buttonTooltipBottom } from '../tooltip';
import { renderFilter } from './filters';
import { some } from 'fp-ts/lib/Option';
import { index } from 'fp-ts/lib/Array';

const logger = debug('sdi:table/base');

export const baseTable = (queries: TableQuerySet, events: TableEventSet) => {
    /**
     * As an exception, we're storing a partial state here
     * in order to ease usage. Not ideal but we're a bit in a rush.
     */

    let buttonState: ButtonComponent = {};
    const getButtonState = () => buttonState;
    const setButtonState = (h: (a: ButtonComponent) => ButtonComponent) => {
        buttonState = h(buttonState);
    };
    const button = buttonFactory(getButtonState, setButtonState);
    const closeButton = button.makeLabel('clear', 2, () => tr.core('reset'));
    const filterButton = button.makeIcon('filter', 3, 'filter', {
        position: 'bottom',
        text: () => tr.core('filter'),
    });

    const scrollElement = (el: Element) => {
        const offsetTop = el.scrollTop;
        const rowHeight = queries.rowHeight();
        const rowOffset = Math.ceil(offsetTop / rowHeight);
        const y = queries.position().y;
        logger(`scroll ${offsetTop} ${rowOffset}`);
        if (Math.abs(y - offsetTop) >= rowHeight) {
            events.setPosition(0, offsetTop);
            events.setTableWindowOffset(rowOffset);
        }
    };

    const scroll = (e: React.UIEvent<Element>): void => {
        scrollElement(e.currentTarget);
    };

    // const cellWidth = (types: string[]): string => `${100 / (types.length)}%`;
    type Width = [number, string];
    const cellWidths = () => {
        const columnAdjust = queries.getColumnAdjust();
        return queries
            .getKeys()
            .map<Width>((k, i) => [
                Math.max(
                    3,
                    Math.max(13, k.length * 0.7) * (1 + columnAdjust[i])
                ),
                'em',
            ]);
    };

    const rowWidth = () =>
        cellWidths().reduce((acc, w) => [acc[0] + w[0], w[1]], [
            0,
            '',
        ] as Width);

    const cwString = (cw: Width) => `${cw[0]}${cw[1]}`;

    const getType = (types: TableDataType[], idx: number) =>
        types[idx] ?? 'string';

    // const randColor =
    //     () => Color(Math.round(Math.random() * 0xffffff)).string();

    const getWidth = (widths: Width[], idx: number) =>
        index(idx, widths).getOrElse([12, 'em']);

    const renderCell =
        (
            types: TableDataType[],
            widths: Width[],
            onSelect: (a: number) => void,
            row: TableDataRow,
            highlighter?: CellHighlighterFn
        ) =>
        (data: TableDataCell, idx: number) =>
            DIV(
                {
                    key: `cell-${idx}`,
                    title: data,
                    className: `table-cell data-type-${getType(types, idx)} ${
                        highlighter && highlighter(row.from, idx)
                            ? 'cell-highlight'
                            : ''
                    }`,
                    style: {
                        width: cwString(getWidth(widths, idx)),
                        // backgroundColor: randColor(),
                    },
                    onClick: () => onSelect(idx),
                },
                data
            );

    const renderRowNum = (rowNum: number, key: string | number) =>
        DIV(
            {
                key: `row-num-${key}`,
                className: 'table-row-num',
                style: {
                    display: 'none',
                },
            },
            (rowNum + 1).toString()
        );

    const selectRow = (config: Config, rowNum: number) => () => {
        events.select(rowNum);
        if (config.onRowSelect) {
            const row = queries.getRow(rowNum);
            if (row) {
                config.onRowSelect(row);
            }
        }
    };

    const selectCell = (config: Config, rowNum: number) => (idx: number) => {
        if (config.onCellSelect) {
            const row = queries.getRow(rowNum);
            if (row) {
                config.onCellSelect(row, idx);
            }
        }
    };

    const renderRow =
        (
            offset: number,
            types: TableDataType[],
            widths: Width[],
            config: Config
        ) =>
        (row: TableDataRow, idx: number) => {
            const rowNum = offset + idx;
            const selected = queries.isSelected(rowNum) ? 'active' : '';
            const evenOdd = rowNum % 2 > 0 ? 'odd table-row' : 'even table-row';
            return DIV(
                {
                    key: `row-${row.from}`,
                    className: `${selected} ${evenOdd}`,
                    onClick: selectRow(config, rowNum),
                },
                renderRowNum(rowNum, row.from),
                ...row.cells.map(
                    renderCell(
                        types,
                        widths,
                        selectCell(config, rowNum),
                        row,
                        config.highlighter
                    )
                )
            );
        };

    const renderTableHeaderCell = (
        types: TableDataType[],
        idx: number,
        width: Width,
        col: string
    ) => {
        const sort = queries.getSort();
        const ty = getType(types, idx);
        let newSortDirection: SortDirection;
        let className = `table-cell table-header-cell data-type-${ty}`;

        if (sort.col === idx) {
            if (sort.direction === SortDirection.ascending) {
                className += ' sorted sorted-asc';
                newSortDirection = SortDirection.descending;
            } else {
                className += ' sorted sorted-desc';
                newSortDirection = SortDirection.ascending;
            }
        } else {
            newSortDirection = SortDirection.ascending;
        }

        const adjustPlusButton = makeIcon('add', 3, 'plus', {
            position: 'right',
            text: tr.core('increaseColumnSize'),
        });
        const adjustMinButton = makeIcon('add', 3, 'minus', {
            position: 'right',
            text: tr.core('reduceColumnSize'),
        });

        return DIV(
            {
                className,
                style: { width: cwString(width) },
                key: `header-cell-${idx}`,
                onClick: () => events.sortData(idx, newSortDirection),
            },
            // divTooltipBottomRight(
            //     tr.core('adjustColumnSize'),
            //     { className: 'adjust-col' },
            //     adjustPlusButton(() => events.setColumnAdjust(idx, 0.3)),
            //     adjustMinButton(() => events.setColumnAdjust(idx, -0.3)),
            // ),
            DIV(
                {
                    className: 'adjust-col',
                },
                adjustPlusButton(() => events.setColumnAdjust(idx, 0.3)),
                adjustMinButton(() => events.setColumnAdjust(idx, -0.3))
            ),
            buttonTooltipBottom(
                `${tr.core('sort')} ${col}` as Translated,
                { className: 'label-col' },
                SPAN({}, col)
            ),
            // divTooltipBottom(
            //     tr.core('filter'),
            //     {}, ss
            //     filterButton(() => events.searchActivate(idx, some(ty)))
            // ),
            filterButton(() => events.searchActivate(idx, some(ty)))
        );
    };

    const renderTableHeader = (types: TableDataType[], widths: Width[]) => {
        const keys = queries.getKeys();
        const elems = keys.map((h: string, idx: number) =>
            renderTableHeaderCell(types, idx, widths[idx], h)
        );

        return DIV(
            {
                className: 'table-header',
                key: 'table-header',
                style: {
                    width: cwString(rowWidth()),
                },
            },
            ...elems
        );
    };

    const renderTableBody =
        (config: Config) => (data: TableDataRow[], offset: number) => {
            const widths = cellWidths();
            const rowCount = queries.rowCount();
            const rowHeight = queries.rowHeight();
            const width = cwString(rowWidth());
            const types = queries.getTypes();
            // logger(`sizer = ${rowCount} * ${rowHeight}`);
            return DIV(
                {
                    key: 'table-body',
                    className: 'table-body',
                    style: { width },
                },
                DIV(
                    {
                        className: 'table-body-sizer',
                        style: {
                            minHeight: rowCount * rowHeight,
                        },
                    },
                    DIV(
                        {
                            className: 'table-body-fragment',
                            style: {
                                position: 'relative',
                                top: queries.position().y,
                                width,
                            },
                            key: `table-body-fragment|${offset}`,
                        },
                        ...data.map(renderRow(offset, types, widths, config))
                    )
                )
            );
        };

    const rectifySize = rect(r => {
        events.setViewHeight(r.height);
        events.setTableWindowSize(Math.ceil(r.height / queries.rowHeight()));
    });

    const render = (config: Config) => {
        let scrollWrapperRef: Element | null = null;

        const setTableSize = (el: Element | null) => {
            /**
             * Ref function is called twice, on attach with el as argument
             * and on detach whitout arugments;
             */
            if (el) {
                scrollWrapperRef = el;
                el.scrollTop = queries.position().y;
                rectifySize(el);
            } else {
                scrollWrapperRef = null;
                events.setViewHeight(0);
                events.setTableWindowSize(0);
            }
        };

        const renderBody = renderTableBody(config);

        return () => {
            // if (queries.isLoaded()) {
            const nbOfColumns = queries.getKeys().length + 1;
            if (queries.getColumnAdjust().length != nbOfColumns) {
                events.initColumnAdjust(nbOfColumns);
            }
            const window = queries.tableWindow();
            const data = queries.getData(window);
            const filters = queries.getFilters();

            if (scrollWrapperRef && window.autoScroll) {
                scrollWrapperRef.scrollTop =
                    window.offset * queries.rowHeight();
                events.clearAutoScroll();
            }
            const children: React.ReactNode[] = [];
            if (config.toolbar) {
                children.push(config.toolbar());
            }

            if (filters.length > 0) {
                const close = closeButton(() => events.searchClose());
                children.push(
                    DIV(
                        { className: 'table-search' },
                        DIV(
                            { className: 'filter--wrapper' },
                            ...filters.map((f, i) =>
                                renderFilter(
                                    f,
                                    i,
                                    queries.getKeys,
                                    events.filterData
                                )
                            )
                        ),
                        close
                    )
                );
            }

            children.push(
                DIV(
                    {
                        className: 'table-main',
                        onScroll: scroll,
                        ref: setTableSize,
                    },
                    renderTableHeader(queries.getTypes(), cellWidths()),
                    renderBody(data, window.offset)
                )
            );

            if (queries.isLoaded() === 'loading') {
                children.push(
                    DIV(
                        { className: 'loading' },
                        SPAN({ className: 'loader-spinner' }),
                        SPAN(
                            { className: 'loading-label' },
                            tr.core('loadingData')
                        )
                    )
                );
            }

            return DIV({ className: 'infinite-table' }, ...children);
            // }
            // else {
            //     const { loadData, loadKeys, loadTypes } = config;
            //     events.loadData(loadData, loadKeys, loadTypes);
            //     return DIV({ className: 'infinite-table loading' },
            //         SPAN({ className: 'loader-spinner' }),
            //         SPAN({ className: 'loading-label' },
            //             tr.core('loadingData')));
            // }
        };
    };

    return render;
};

logger('loaded');
