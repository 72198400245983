/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { compose } from 'fp-ts/lib/function';

import {
    Filter,
    ITableSort,
    TableDataRow,
    TableDataType,
    TableWindow,
    TableQuerySet,
    TableGetter,
    TableSourceGetter,
    SortDirection,
    FilterString,
    FilterNumber,
    FilterDate,
    FilterDateTime,
} from '.';

const logger = debug('sdi:table/queries');

interface numberSortMapEntry {
    index: number;
    value: number;
}
interface stringSortMapEntry {
    index: number;
    value: string;
}

type sortMapEntry = numberSortMapEntry | stringSortMapEntry;

const makeSortMap = (sortList: sortMapEntry[], direction: SortDirection) => {
    const sortMap = sortList.sort((a: sortMapEntry, b: sortMapEntry) => {
        if (typeof a.value == 'string' && typeof b.value == 'string') {
            return a.value.localeCompare(b.value);
        }
        return +(a.value > b.value) || +(a.value === b.value) - 1;
    });
    if (direction === SortDirection.descending) {
        sortMap.reverse();
    }

    return sortMap;
};

// Attach a sort column to the table.
const stringSort = (
    data: TableDataRow[],
    col: number,
    direction: SortDirection
) => {
    const treated = data.map((r, k) => ({
        index: k,
        value: r.cells[col].toLowerCase(),
    }));
    return makeSortMap(treated, direction);
};

/**
 * Sort data by selected column as numbers
 * @param data TableDataRow[]
 * @param col column name string
 * @param direction SortDirection
 */
const numberSort = (
    data: TableDataRow[],
    col: number,
    direction: SortDirection
) => {
    const treated = data.map((r, k) => ({ index: k, value: +r.cells[col] }));
    return makeSortMap(treated, direction);
};

const makeStringFilter = (f: FilterString) => {
    const pat = new RegExp(`.*${f.pattern}.*`, 'i');
    const col = f.column;
    return ({ cells }: TableDataRow) => pat.test(cells[col]);
};

const makeNumberFilter = (f: FilterNumber) => {
    const b = f.value;
    const op =
        f.op === 'eq'
            ? (a: number) => a === b
            : f.op === 'gt'
            ? (a: number) => a >= b
            : (a: number) => a <= b;
    const cond = (cell: string) => op(parseFloat(cell));
    const col = f.column;
    return ({ cells }: TableDataRow) => cond(cells[col]);
};

const makeDateFilter = (f: FilterDate) => {
    const b = Date.parse(f.date);
    const op =
        f.op === 'eq'
            ? (a: number) => a === b
            : f.op === 'gt'
            ? (a: number) => a >= b
            : (a: number) => a <= b;
    const cond = (cell: string) => op(Date.parse(cell));
    const col = f.column;

    return ({ cells }: TableDataRow) => cond(cells[col]);
};

const makeDateTimeFilter = (f: FilterDateTime) => {
    const b = Date.parse(f.datetime);
    const op =
        f.op === 'eq'
            ? (a: number) => a === b
            : f.op === 'gt'
            ? (a: number) => a >= b
            : (a: number) => a <= b;
    const cond = (cell: string) => op(Date.parse(cell));
    const col = f.column;

    return ({ cells }: TableDataRow) => cond(cells[col]);
};

const makeFilterFunction = (fs: Filter[]) => {
    const filters = fs.map(f => {
        switch (f.tag) {
            case 'string':
                return makeStringFilter(f);
            case 'number':
                return makeNumberFilter(f);
            case 'date':
                return makeDateFilter(f);
            case 'datetime':
                return makeDateTimeFilter(f);
        }
    });

    return (row: TableDataRow) =>
        filters.reduce((acc, f) => (acc === false ? acc : f(row)), true);
};

export const tableQueries = (
    getTable: TableGetter,
    getSource: TableSourceGetter
): TableQuerySet => {
    const filter = (filters: Filter[]) => (data: TableDataRow[]) =>
        data.filter(makeFilterFunction(filters));

    const sorter =
        (col: number | null, direction: SortDirection, types: string[]) =>
        (data: TableDataRow[]) => {
            if (col !== null) {
                const type = types[col];
                let sortMap: sortMapEntry[] = [];

                if (type === 'number') {
                    sortMap = numberSort(data, col, direction);
                } else {
                    sortMap = stringSort(data, col, direction);
                }

                return sortMap.map(entry => data[entry.index]);
            }
            return data;
        };

    const getFilteredData = () => {
        const { data, types } = getSource();

        const { search, sort } = getTable();
        if (sort.col !== null || search.filters.length > 0) {
            const f = filter(search.filters);
            const g = sorter(sort.col, sort.direction, types);
            const c = compose(g, f);
            return c(data);
        }
        return data;
    };

    const queries = {
        isLoaded() {
            return getTable().loaded;
        },

        getKind() {
            return getSource().kind;
        },

        getKeys(): string[] {
            return getSource().keys;
        },

        getFilters() {
            const { search } = getTable();
            return search.filters;
        },

        getTypes(): TableDataType[] {
            return getSource().types;
        },

        getSort(): ITableSort {
            return getTable().sort;
        },

        getData(window?: TableWindow): TableDataRow[] {
            if ('remote' === getSource().kind) {
                return getSource().data;
            }
            if (window) {
                return getFilteredData().slice(
                    window.offset,
                    window.offset + window.size
                );
            } else {
                return getFilteredData();
            }
        },

        getActiveResult(): number {
            return getTable().search.activeResult;
        },

        getResultCount(): number {
            return getTable().search.resultMap.length;
        },

        rowCount() {
            const rows = queries.getData();
            return rows.length;
        },

        tableWindow() {
            return getTable().window;
        },

        rowHeight() {
            return getTable().rowHeight;
        },

        viewHeight() {
            return getTable().viewHeight;
        },

        position() {
            return getTable().position;
        },

        isSelected(idx: number) {
            return getTable().selected === idx;
        },

        getSelected() {
            return getTable().selected;
        },

        getRow(idx?: number) {
            const selected = idx !== undefined ? idx : queries.getSelected();
            const data = queries.getData();
            if (selected < 0 || selected >= data.length) {
                return null;
            }
            return data[selected];
        },

        getColumnAdjust() {
            return getTable().columnAdjust;
        },
    };

    return queries;
};

logger('loaded');
