/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import * as debug from 'debug';

import {
    fromNullable,
    Option,
    fromPredicate,
    none,
    some,
} from 'fp-ts/lib/Option';
import { left, right, Either } from 'fp-ts/lib/Either';

import { query, queryK } from 'sdi/shape';
import {
    getMessageRecord,
    IMapInfo,
    FeatureCollection,
    ILayerInfo,
    appPosters,
    AppPosterManifest,
    remoteToOption,
    mapRemote,
} from 'sdi/source';
import { getApps, SyntheticLayerInfo } from 'sdi/app';
import { scopeOption } from 'sdi/lib';
import { fromRecord } from 'sdi/locale';
import { withoutBookmarks } from './bookmark';
import { Collection, notEmpty, tryNumber, updateCollection } from 'sdi/util';
import { catOptions, isEmpty } from 'fp-ts/lib/Array';
import { FeaturePathInstance, FeaturePath } from 'sdi/map';
import { getMapList, getSelectedCategories } from './mapnavigator';

const logger = debug('sdi:events/app');

export const mapReady = () => {
    return query('app/map-ready');
};

export const getLayout = () => query('app/layout');
// {
//     const ll = query('app/layout');
//     if (ll.length === 0) {
//         throw new Error('PoppingEmptyLayoutList');
//     }
//     return ll[ll.length - 1];
// };

export const getAllMapsRemote = () => query('data/maps');
export const getAllMapsRemoteFiltered = () =>
    mapRemote(getAllMapsRemote(), maps => getMapList(maps));
export const getAllMaps = () =>
    remoteToOption(getAllMapsRemote()).getOrElse([]);

export const getLayerData = (
    ressourceId: string
): Either<string, Option<FeatureCollection>> => {
    const errors = query('remote/errors');
    if (ressourceId in errors) {
        return left(errors[ressourceId]);
    }
    return right(getLayerDataFromUri(ressourceId));
};

export const getLayerInfo = (lid: string | null) =>
    getMapInfoOption().chain(currentMap =>
        fromNullable(currentMap.layers.find(l => l.id === lid))
    );
// for (const map of getAllMaps()) {
//     for (const layer of map.layers) {
//         if (layer.id === lid) {
//             return some(layer);
//         }
//     }
// }
// return none;

export const getLayerDataFromInfo = (
    layerId: string | null
): Option<FeatureCollection> =>
    scopeOption()
        .let('layerInfo', getLayerInfo(layerId))
        .let('metadata', ({ layerInfo }) =>
            getDatasetMetadataOption(layerInfo.metadataId)
        )
        .let('data', ({ metadata }) =>
            getLayerDataFromUri(metadata.uniqueResourceIdentifier)
        )
        .pick('data');

export const getLayerDataFromUri = (uri: string) =>
    fromNullable(query('data/layers')[uri]);

export const getDatasetMetadata = (id: string) => {
    const collection = query('data/datasetMetadata');
    if (id in collection) {
        return collection[id];
    }
    return null;
};

export const getSyntheticLayerInfoOption = (
    layerId: string
): Option<SyntheticLayerInfo> =>
    scopeOption()
        .let('layerInfo', getLayerInfo(layerId))
        .let('metadata', ({ layerInfo }) =>
            getDatasetMetadataOption(layerInfo.metadataId)
        )
        .map(({ layerInfo, metadata }) => ({
            name: getMessageRecord(metadata.resourceTitle),
            info: layerInfo,
            metadata,
        }));

export const getSyntheticLayerInfo = getSyntheticLayerInfoOption;

export const getCurrentMap = () => query('app/current-map');

export const getCurrentLayer = () => query('app/current-layer');

export const getCurrentLayerOpt = () => fromNullable(getCurrentLayer());

export const getCurrentLayerInfoOption = () =>
    fromNullable(query('app/current-layer')).chain(getSyntheticLayerInfoOption);

export const getCurrentLayerInfo = getCurrentLayerInfoOption;

export const getFirstLayer = () =>
    getMapInfoOption()
        .map(mapInfo =>
            withoutBookmarks(
                mapInfo.layers.filter(layer => layer.visibleLegend)
            )
        )
        .chain(l => (l.length > 0 ? some(l[l.length - 1]) : none));

export const getCurrentMetadata = () =>
    getCurrentLayerInfo().map(i => i.metadata);
export const getCurrentInfo = () =>
    getCurrentLayerInfo().map<ILayerInfo>(i => i.info);
export const getCurrentName = () =>
    getCurrentLayerInfo().map<string>(i => fromRecord(i.name));

export const pathInstance = ({
    layerId,
    featureId,
}: FeaturePath): Option<FeaturePathInstance> =>
    scopeOption()
        .let('layer', getLayerInfo(layerId))
        .let('data', getLayerDataFromInfo(layerId))
        .let('feature', s =>
            fromNullable(s.data.features.find(f => f.id === featureId))
        );

export const getCurrentSelection = queryK('app/selected-features');

export const getSelectedFeatures = () =>
    notEmpty(catOptions(getCurrentSelection().map(pathInstance)));

const groupPathsByLayer = (paths: FeaturePathInstance[]) =>
    paths.reduce<Collection<FeaturePathInstance[]>>((acc, path) => {
        if (!(path.layer.id in acc)) {
            return updateCollection(acc, path.layer.id, [path]);
        } else {
            return updateCollection(
                acc,
                path.layer.id,
                acc[path.layer.id].concat(path)
            );
        }
    }, {});

export const pathsSortedByLayers = (paths: FeaturePathInstance[]) => {
    const pathsByLayer = Object.entries(groupPathsByLayer(paths));
    return getMapInfoOption()
        .map(info =>
            info.layers
                .reduce(
                    (acc: [string, FeaturePathInstance[]][], layer) =>
                        fromNullable(
                            pathsByLayer.find(([key, _]) => key === layer.id)
                        )
                            .map(paths => acc.concat([paths]))
                            .getOrElse(acc),
                    []
                )
                .reverse()
        )
        .getOrElse([]);
};

// export const getCurrentFeature = (): Option<Feature> =>
//     fromNullable(query('app/current-feature'));

// export const isSelectedFeature = (f: Feature) =>
//     getSelectedFeatures()
//         .map(list => list.indexOf(f) > -1)
//         .getOrElse(false);

export const getCurrentBaseLayerName = () => {
    const mid = query('app/current-map');
    const map = getAllMaps().find(m => m.id === mid);
    if (map) {
        return map.baseLayer;
    }
    return null;
};

export const getBaseLayer = (id: string | null) => {
    const [serviceId, layerName] = fromNullable(id)
        .map(i => i.split('/'))
        .getOrElse(['', '']);
    const service = query('data/baselayers').find(s => s.id === serviceId);
    if (service) {
        return service.layers.find(l => l.codename === layerName) ?? null;
    }
    return null;
};

export const getCurrentBaseLayer = () => {
    const name = getCurrentBaseLayerName();
    return getBaseLayer(name);
};

export const getBaseLayerServices = () =>
    query('data/baselayers').map(s => s.id);

export const getBaseLayersForService = (serviceId: string) =>
    fromNullable(query('data/baselayers').find(s => s.id === serviceId))
        .map(s => s.layers)
        .getOrElse([]);

export const getMapInfo = () => {
    const mid = query('app/current-map');
    const info = getAllMaps().find(m => m.id === mid);
    return info !== undefined ? info : null;
};

export const getMapInfoOption = () => fromNullable(getMapInfo());

export const getMapOption = (mid: string) =>
    fromNullable(getAllMaps().find(m => m.id === mid));

export const getDatasetMetadataOption = (id: string) =>
    fromNullable(getDatasetMetadata(id));

export const hasPrintTitle = () =>
    null === query('component/print').customTitle;

export const getPrintTitle = (info: IMapInfo) =>
    fromNullable(query('component/print').customTitle).fold(info.title, s => s);

export const isTableSelected = () =>
    fromPredicate<boolean>(v => v)(query('app/table/selected'));

const filterPosters = (posters: AppPosterManifest[]) =>
    posters.filter(p => {
        const cats = getSelectedCategories();
        if (!isEmpty(cats)) {
            return getSelectedCategories().some(c => p.categories.includes(c));
        }
        return true;
    });

export const getPosters = () => notEmpty(filterPosters(appPosters(getApps())));

const getUsers = () => query('data/users');
export const findUser = (id: number) =>
    fromNullable(
        getUsers().find(u => tryNumber(u.id).fold(false, uid => uid === id))
    );

export const userName = (id: number) => findUser(id).map(u => u.name);

logger('loaded');
