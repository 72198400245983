import * as io from 'io-ts';
import { MessageRecordIO, nullable } from './io';

// tslint:disable-next-line:variable-name
export const DomainIO = io.interface({
    id: io.number,
    name: MessageRecordIO,
    kind: nullable(io.number),
    description: nullable(MessageRecordIO),
}, 'DomainIO');
export type Domain = io.TypeOf<typeof DomainIO>;

// tslint:disable-next-line:variable-name
export const DomainListIO = io.array(DomainIO);