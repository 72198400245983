import { fromPredicate, none, Option } from 'fp-ts/lib/Option';
import * as io from 'io-ts';
import { Coordinate } from 'ol/coordinate';
import { getCurrentAppCodename } from '../app/queries';
import { scopeOption } from '../lib';
import { dispatch } from '../shape';
import { fetchIO, RemoteResource } from '../source';
import {
    ServiceDescription,
    ServiceDescriptionIO,
    ServiceResponse,
} from './io';

export const fetchAllServices = (): Promise<ServiceDescription[]> =>
    fetchIO(
        io.array(ServiceDescriptionIO),
        `/lookup/services/${getCurrentAppCodename()}`
    );

export const loadAllServices = () => {
    fetchAllServices().then(services => {
        const selected = services.length > 0 ? services[0].code : null;
        dispatch('app/geocoder', ({ response }) => ({
            serviceList: services,
            response,
            selected,
        }));
    });
};

export const dispatchGeocoderState = (
    response: RemoteResource<ServiceResponse>
) => {
    dispatch('app/geocoder', ({ serviceList, selected }) => ({
        serviceList,
        response,
        selected,
    }));
};

export const clearServiceResponse = () => {
    dispatch('app/geocoder', ({ serviceList, selected }) => ({
        serviceList,
        response: null,
        selected,
    }));
};

export const selectService = (service_code: string) => {
    dispatch('app/geocoder', ({ serviceList, response }) => ({
        serviceList,
        response,
        selected: service_code,
    }));
};

export const updateTerm = (term: string) => {
    dispatch('app/geocoder/input', () => term);
};

const validNumber = fromPredicate(
    (n: number) => !Number.isNaN(n) && Number.isFinite(n)
);
export const parseSearchCoordinates = (coord: string): Option<Coordinate> => {
    const parts = coord.split(/\s+/);
    if (parts.length === 2) {
        return scopeOption()
            .let('x', validNumber(parseFloat(parts[0])))
            .let('y', validNumber(parseFloat(parts[1])))
            .map(({ x, y }) => [x, y]);
    }
    return none;
};

export const updateSearchCoord = (coord: Coordinate) => {
    dispatch('app/geocoder/input/coord', () => coord);
};
