import { fromPredicate, Option } from 'fp-ts/lib/Option';

import { Rect, Box, TextAlign } from 'sdi/print/context';

import { templates } from 'platform/print';

type ResAnnotation = { resolution: number };

export interface TemplateSpec {
    rect: Rect;
    fontSize: number;
    strokeWidth: number;
    textAlign: TextAlign;
    color: string;
}

export interface PartialSpec {
    rect: Rect;
    fontSize?: number;
    strokeWidth?: number;
    textAlign?: TextAlign;
    color?: string;
}

export type Spec = TemplateSpec & ResAnnotation;

export type SpecName =
    | 'title'
    | 'description'
    | 'map'
    | 'legend'
    | 'legendItem'
    | 'logo'
    | 'attribution'
    | 'scaleline'
    | 'north'
    | 'credits';

export type Template = { [k in SpecName]?: TemplateSpec } & ResAnnotation;

export type TemplateName =
    | 'a4/portrait'
    | 'a4/landscape'
    | 'a3/portrait'
    | 'a3/landscape'
    | 'a0/portrait'
    | 'a0/landscape';

// export type TemplateName = string;

export type TemplateCollection = { [k in TemplateName]: Template };

const getTemplate = (name: TemplateName) => templates[name];

const withSpec = (template: Template, specName: SpecName) =>
    fromPredicate<Template>(t => specName in t)(template).map(t =>
        Object.assign({}, t[specName], { resolution: template.resolution })
    );

export interface ApplyFn<T = Box> {
    (specName: SpecName, fn: SpecFn<T>): Option<T>;
}

export interface SpecFn<T = Box> {
    (s: Spec): T;
}

export const getResolution = (templateName: TemplateName) =>
    getTemplate(templateName).resolution;

export const applySpec =
    (templateName: TemplateName) =>
    <T = Box>(specName: SpecName, fn: SpecFn<T>) =>
        withSpec(getTemplate(templateName), specName).map(fn);
