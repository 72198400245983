import { some } from 'fp-ts/lib/Option';

import Map from 'ol/Map';

import {
    PositionOptions,
    withInteraction,
    InteractionPosition,
} from '..';


export const position =
    ({ setPosition, stopPosition }: PositionOptions) => {
        let isActive = false;

        const update = withInteraction<InteractionPosition>('position',
            () => {
                isActive = true;
            },
            () => {
                isActive = false;
            });

        const init =
            (map: Map) => {
                map.on('pointermove', (event) => {
                    if (isActive) {
                        setPosition(event.coordinate);
                    }
                });

                map.on('singleclick', (event) => {
                    if (isActive) {
                        stopPosition(some(event.coordinate));
                    }
                });
            };

        return { init, update };
    };
