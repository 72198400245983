import * as io from 'io-ts';
import { MessageRecordIO, nullable } from './io';

// tslint:disable-next-line:variable-name
export const TermIO = io.interface({
    id: io.number,
    name: MessageRecordIO,
    description: nullable(MessageRecordIO),
    domain: io.number,
    sort_index: io.number
});

export type Term = io.TypeOf<typeof TermIO>;

// tslint:disable-next-line:variable-name
export const TermListIO = io.array(TermIO);


