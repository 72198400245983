/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

import { getContext, IOLContext, polygonStyle } from '../../map/style';
import { DIV, NodeOrOptional, SPAN } from '../elements';
import { fromRecord } from '../../locale';
import {
    ILayerInfo,
    PolygonStyleConfig,
    PolygonStyleConfigSimple,
    PolygonStyleConfigDiscrete,
    PolygonStyleConfigContinuous,
    Inspire,
    PatternStyle,
} from '../../source';
import { none, Option } from 'fp-ts/lib/Option';
import {
    applyResolutionStyle,
    OpacitySelector,
    renderSimpleItemLabel,
    renderOpacitySelector,
    defaultOpacitySelector,
} from '.';
import { getAlphaNb } from '../input';

const logger = debug('sdi:legend-polygon');

const polygonGeometry = new Polygon([
    [
        [0, 0],
        [100, 0],
        [100, 100],
        [0, 100],
        [0, 0],
    ],
]);

interface Stroke {
    width: number;
    color: string;
}

type StrokeSource = {
    strokeWidth: number;
    strokeColor: string;
    pattern?: PatternStyle;
};

const makeStroke = (config: StrokeSource): Stroke | null => {
    if (config.strokeWidth >= 0.5) {
        // if (config.pattern && config.patternColor === undefined) {
        //     return null;
        // }
        return {
            width: config.strokeWidth,
            color: config.strokeColor,
        };
    }
    return null;
};

const item = (
    geomType: string,
    dataUrl: string,
    label: string,
    stroke: Stroke | null
) =>
    DIV(
        { className: `legend-item ${geomType}` },
        DIV(
            { className: 'item-style' },
            DIV({
                style: {
                    width: '100%',
                    height: '50%',
                    backgroundImage: `url(${dataUrl})`,
                    backgroundPosition: 'center',
                    borderWidth: stroke === null ? '0' : `${stroke.width}px`,
                    borderColor: stroke === null ? 'inherit' : stroke.color,
                    borderStyle: stroke === null ? 'none' : 'solid',
                },
            })
        ),
        // IMG({ src: dataUrl })),
        DIV({ className: 'item-label' }, SPAN({}, label))
    );

const renderSimple = (
    config: PolygonStyleConfigSimple,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, olContext } = ctx;
    const styles = applyResolutionStyle(
        polygonStyle(config),
        new Feature(polygonGeometry)
    );
    styles.forEach(style => {
        olContext.setStyle(style);
        olContext.drawGeometry(polygonGeometry);
    });

    const label = renderSimpleItemLabel(layerInfo, md);
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    return [
        DIV(
            `item ${opacityClass}`,
            item('polygon', canvas.toDataURL(), label, makeStroke(config)),
            renderOpacitySelector(opacitySelector, layerInfo)
        ),
    ];
};

const displayWithCondition = (
    element: Option<React.ReactNode>,
    predicate: boolean
) => (predicate ? element : none);

const renderDiscrete = (
    config: PolygonStyleConfigDiscrete,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = polygonStyle(config);
    const items: NodeOrOptional[] = [];
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';
    const groups = config.groups;
    if (groups.length >= 1) {
        const allSameOpacity = groups.every(g => {
            return (
                getAlphaNb(g.fillColor)() === getAlphaNb(groups[0].fillColor)()
            );
        });
        if (allSameOpacity) {
            items.push(renderOpacitySelector(opacitySelector, layerInfo));
        }
        groups.forEach((group, id) => {
            if (group.values.length > 0) {
                canvasContext.clearRect(0, 0, 100, 100);
                const f = new Feature(polygonGeometry);
                f.set(config.propName, group.values[0]);
                const styles = applyResolutionStyle(styleFn, f);
                styles.forEach(style => {
                    olContext.drawFeature(f, style);
                });
                items.push(
                    DIV(
                        `item ${opacityClass}`,
                        item(
                            'polygon',
                            canvas.toDataURL(),
                            fromRecord(group.label),
                            makeStroke(group)
                        ),
                        displayWithCondition(
                            renderOpacitySelector(
                                opacitySelector,
                                layerInfo,
                                id
                            ),
                            !allSameOpacity
                        )
                    )
                );
            }
        });
    }

    return items;
};

const renderContinuous = (
    config: PolygonStyleConfigContinuous,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = polygonStyle(config);
    const items: NodeOrOptional[] = [];
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';
    const intervals = config.intervals;
    if (intervals.length >= 1) {
        const allSameOpacity = intervals.every(g => {
            return (
                getAlphaNb(g.fillColor)() ===
                getAlphaNb(intervals[0].fillColor)()
            );
        });
        if (allSameOpacity) {
            items.push(renderOpacitySelector(opacitySelector, layerInfo));
        }

        intervals.forEach((interval, id) => {
            canvasContext.clearRect(0, 0, 100, 100);
            const f = new Feature(polygonGeometry);
            const v = interval.low + (interval.high - interval.low) / 2;
            f.set(config.propName, v);
            const styles = applyResolutionStyle(styleFn, f);
            styles.forEach(style => {
                olContext.drawFeature(f, style);
            });
            items.push(
                DIV(
                    `item ${opacityClass}`,
                    item(
                        'polygon',
                        canvas.toDataURL(),
                        fromRecord(interval.label),
                        makeStroke(interval)
                    ),
                    displayWithCondition(
                        renderOpacitySelector(opacitySelector, layerInfo, id),
                        !allSameOpacity
                    )
                )
            );
        });
    }
    return items;
};

const render = (
    config: PolygonStyleConfig,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    opacitySelector = defaultOpacitySelector
) => {
    const ctx = getContext(100, 100);
    if (ctx) {
        switch (config.kind) {
            case 'polygon-simple':
                return renderSimple(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
            case 'polygon-discrete':
                return renderDiscrete(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
            case 'polygon-continuous':
                return renderContinuous(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
        }
    }

    return [];
};

export default render;

logger('loaded');
