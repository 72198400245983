/*
 *  Copyright (C) 2022 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    // deselectAppList,
    // getAppListStatus,
    getApps,
    // selectAppList,
} from '../app';
import { appName, appDisplayName, appUrl, appDisplay } from '../source';
import tr, { fromRecord } from '../locale';
import { DIV, SPAN, A } from './elements';
import { nameToString } from './button/names';

export const renderAppSelect = (current: string) => {
    const apps = getApps();
    const tail = apps
        .filter(a => appName(a) !== current && appDisplay(a) !== 'notInMenu')
        .map(a =>
            appDisplayName(a).map(name =>
                A(
                    { className: `app-item ${appName(a)}`, href: appUrl(a) },
                    DIV(
                        'app-name',
                        SPAN('app-picto'),
                        SPAN('app-name', fromRecord(name))
                    )
                )
            )
        );

    const head = DIV(
        'app-list-title',
        SPAN('icon fa', nameToString('home')),
        tr.core('applications')
    );

    return DIV('app-select', head, DIV('tail', ...tail));

    // return DIV(
    //     {
    //         className: 'app-select__wrapper active',
    //         onClick: e => e.currentTarget.classList.toggle('active'),
    //     },
    //     DIV('app-select', head, DIV('tail', ...tail))
    // );
};

// export const renderAppSelect = (current: string) => {
//     const apps = getApps();
//     const tail = apps
//         .filter(a => appName(a) !== current)
//         .map(a =>
//             appDisplayName(a).map(name =>
//                 A(
//                     { className: `app-item ${appName(a)}`, href: appUrl(a) },
//                     DIV(
//                         'app-name',
//                         SPAN('app-picto'),
//                         SPAN('app-name', fromRecord(name))
//                     )
//                 )
//             )
//         );

//     const head = DIV(
//         'selected head',
//         BUTTON(
//             {
//                 className: 'head-label',
//                 'aria-haspopup': 'listbox',
//                 'aria-expanded': getAppListStatus(),
//                 onClick: () =>
//                     getAppListStatus() ? deselectAppList() : selectAppList(),
//             },
//             SPAN('icon fa', nameToString('home')),
//             tr.core('applications')
//         )
//     );

//     return DIV(
//         {
//             className: 'app-select__wrapper ',
//             onClick: e => e.currentTarget.classList.toggle('active'),
//         },
//         DIV(
//             'app-select',
//             head,
//             getAppListStatus() ? DIV('tail', ...tail) : NODISPLAY()
//         )
//     );
// };
