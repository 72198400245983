/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import tr from 'sdi/locale';
import { DIV, H2 } from 'sdi/components/elements';

import { setLayout } from '../../events/app';
import { measureEvents } from '../../events/map';
import { AppLayout } from '../../shape/types';
import { makeLabelAndIcon } from '../button';
import { setFocusId } from 'sdi/app';

const measureLength = () => {
    setLayout(AppLayout.MapAndMeasure);
    measureEvents.startMeasureLength();
};

const measureArea = () => {
    setLayout(AppLayout.MapAndMeasure);
    measureEvents.startMeasureArea();
};

const btnMeasureLength = makeLabelAndIcon('start', 2, 'arrows-alt-h', () =>
    tr.view('measureLength')
);

const btnMeasureArea = makeLabelAndIcon('start', 2, 'arrows-alt', () =>
    tr.view('measureArea')
);

const render = () => {
    return DIV(
        { className: 'sidebar-main tool measure' },
        H2({}, tr.view('measureTool')),
        DIV({}, tr.view('helptext:measureTool')),
        DIV(
            { className: 'tool-body' },
            btnMeasureLength(() => {
                measureLength();
                setFocusId(`stop-measure`);
            }),
            btnMeasureArea(() => {
                measureArea();
                setFocusId(`stop-measure`);
            })
        )
    );
};

export default render;
