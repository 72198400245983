// import tr from 'locale';
import tr from '../locale';
import { getCollapsible, setCollapsible } from '../app';
import { makeLabelAndIcon } from './button';
import { DETAILS, DIV, NodeOrOptional, SUMMARY } from './elements';

// collapsible wrapper

export const renderCollapsibleWrapper = (
    key: string,
    header: NodeOrOptional,
    body: NodeOrOptional,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange = (_event: 'collapse' | 'expand') => void 0
) =>
    DETAILS(
        {
            className: `collapsible-wrapper__header ${key}`,
            open: getCollapsible(key),
        },
        SUMMARY(
            {
                className: 'collapsible-wrapper__body',
                onClick: e => {
                    setCollapsible(key, !getCollapsible(key));
                    getCollapsible(key)
                        ? onChange('expand')
                        : onChange('collapse');
                    e.preventDefault();
                },
            },
            header
        ),
        body
    );

type ExCo = [() => void, () => void];

const btnExpandText = makeLabelAndIcon('expand', 3, 'plus', () =>
    tr.core('readMore')
);

const btnCollapseText = makeLabelAndIcon('collapse', 3, 'minus', () =>
    tr.core('readLess')
);

export const renderReadMore = (
    key: string,
    shrunkText: NodeOrOptional,
    fullText: NodeOrOptional,
    [onExpand, onCollapse] = [() => void 0, () => void 0] as ExCo,
    [setter, getter] = [setCollapsible, getCollapsible]
) =>
    getter(key)
        ? DIV(
              'text text-collapsed',
              shrunkText,
              btnExpandText(() => {
                  setter(key, false);
                  onCollapse();
              })
          )
        : DIV(
              'text text-expanded',
              fullText,
              btnCollapseText(() => {
                  setter(key, true);
                  onExpand();
              })
          );

export const renderTooledCollapsibleWrapper = (
    key: string,
    title: NodeOrOptional,
    tools: NodeOrOptional,
    body: NodeOrOptional,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange = (_event: 'collapse' | 'expand') => void 0
) =>
    DETAILS(
        {
            className: `collapsible-wrapper__header tooled ${key}`,
            open: getCollapsible(key),
        },
        SUMMARY(
            {
                className: 'collapsible-wrapper__body tooled',
                onClick: e => {
                    setCollapsible(key, !getCollapsible(key));
                    getCollapsible(key)
                        ? onChange('expand')
                        : onChange('collapse');
                    e.preventDefault();
                },
            },
            DIV('summary-title', title),
            DIV('summary-tools', tools)
        ),
        body
    );

// function P(arg0: { className: string; open: boolean }) {
//     throw new Error('Function not implemented.');
// }

// Collapsible wrapper Extra (custom, with no detail/summary elements)
// export const renderCollapsibleWrapperExtra = (
//     key: string,
//     header: NodeOrOptional | NodeOrOptional[],
//     headerActions: NodeOrOptional | NodeOrOptional[],
//     body: NodeOrOptional | NodeOrOptional[],
//     // [onExpand, onCollapse] = [() => { }, () => { }] as ExCo,
//     [setter, getter] = [setCollapsible, getCollapsible]
// ) =>
//     DIV(
//         { className: `collapsible-extra__wrapper ${key} ${getter(key)}` },
//         DIV(
//             { className: 'collapsible-extra__header' },
//             BUTTON(
//                 {
//                     className: 'collapsible__title-btn',
//                     onClick: () => {
//                         if (getter(key)) {
//                             setter(key, false);
//                             // onCollapse();
//                         } else {
//                             setter(key, true);
//                             // onExpand();
//                         }
//                     },
//                 },
//                 header
//             ),
//             DIV(
//                 { className: 'collapsible-extra__header--actions' },
//                 headerActions,
//                 BUTTON(
//                     {
//                         className: 'btn-collapsible',
//                         onClick: () => {
//                             if (getter(key)) {
//                                 setter(key, false);
//                                 // onCollapse();
//                             } else {
//                                 setter(key, true);
//                                 // onExpand();
//                             }
//                         },
//                     },
//                     nameToString('chevron-right')
//                 )
//             )
//         ),
//         getter(key)
//             ? DIV({ className: 'collapsible-extra__body' }, body)
//             : NODISPLAY()
//     );
