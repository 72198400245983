/*
 *  Copyright (C) 2019 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    Activity,
    ActivityActionData,
    ButtonData,
    CheckboxData,
    ExportData,
    LangData,
    LinkData,
    NavigateCapakeyData,
    NavigateDetailData,
    NavigateMapData,
    NavigateSolarContactData,
    PrintMapData,
    PrintReportData,
    VisitData,
} from '../source/io/activity';
import { NavigateQueryData } from '../source/io/activity/action-query';
import { SaveUnitsData } from '../source/io/activity/action-project';

import {
    MessageRecord,
    MessageRecordLang,
    NoContentIO,
    postUnrelatedIO,
} from '../source';
import { getActivityToken, getLang } from '../app';
import { SelectWaterLevelData } from '../source/io/activity/action-timeserie';

const postActivity = (url: string, data: Activity) =>
    postUnrelatedIO<null, Activity>(NoContentIO, url, data);

// const now = () => datetime8601(new Date())

export const activityURLs = {
    token: '/activity/token',
    post: '/activity/post/',
};

export const visitAction = (): VisitData => ({
    action: 'visit',
    parameter: {},
});

export const checkboxAction = (id: string): CheckboxData => ({
    action: 'checked',
    parameter: { id },
});

export const clickAction = (id: string, select: string | null): ButtonData => ({
    action: 'clicked',
    parameter: { id, select },
});

export const linkAction = (link: string): LinkData => ({
    action: 'link',
    parameter: { link },
});

export const navigateMapAction = (
    id: string,
    title: MessageRecord
): NavigateMapData => ({
    action: 'navigate-map',
    parameter: { id, title },
});

export const navigateQueryAction = (
    id: string,
    title: MessageRecord
): NavigateQueryData => ({
    action: 'navigate-query',
    parameter: { id, title },
});

export const printMapAction = (
    id: string,
    title: MessageRecord
): PrintMapData => ({
    action: 'print-map',
    parameter: { id, title },
});

export const printReportAction = (address: string): PrintReportData => ({
    action: 'print-report',
    parameter: { address },
});

export const navigateDetailAction = (system: string): NavigateDetailData => ({
    action: 'navigate-detail',
    parameter: { system },
});

export const navigateSolarContactAction = (
    system: string
): NavigateSolarContactData => ({
    action: 'navigate-solar-contact',
    parameter: { system },
});

export const navigateCapakeyAction = (
    capakey: string
): NavigateCapakeyData => ({
    action: 'navigate-capakey',
    parameter: { capakey },
});

export const langAction = (lang: MessageRecordLang): LangData => ({
    action: 'lang-choice',
    parameter: { lang },
});

export const saveUnitsAction = (
    projectId: number,
    numberOfEdits: number
): SaveUnitsData => ({
    action: 'save-units',
    parameter: { projectId, numberOfEdits },
});

export const selectWaterLevelAction = (
    type: 'surface' | 'ground'
): SelectWaterLevelData => ({
    action: 'select-water-level',
    parameter: { type },
});

export const exportAction = (fileName: string, key?: string): ExportData =>
    key != undefined
        ? {
              action: 'export',
              parameter: { fileName, key },
          }
        : {
              action: 'export',
              parameter: { fileName },
          };

// export const activity =
//     (namespace: string) =>
//         (action: string, parameter: string) => {
//             getActivityToken()
//                 .map(token =>
//                     postActivity(activityURLs.post, {
//                         datetime: now(),
//                         lang: getLang(),
//                         token,
//                         namespace,
//                         action,
//                         parameter,
//                     })
//                 );
//         };

let pendingActivities: Omit<Activity, 'token'>[] = [];

const processPendings = (token: string) => {
    pendingActivities.forEach(data =>
        postActivity(activityURLs.post, { ...data, token } as Activity)
    );
    pendingActivities = [];
};

export const activity =
    (namespace: string) => (actionData: ActivityActionData) => {
        getActivityToken().foldL(
            () => {
                pendingActivities.push({
                    datetime: Date.now(),
                    lang: getLang(),
                    namespace,
                    ...actionData,
                });
            },
            token => {
                postActivity(activityURLs.post, {
                    datetime: Date.now(),
                    lang: getLang(),
                    token,
                    namespace,
                    ...actionData,
                });
                processPendings(token);
            }
        );
    };

// example
// const a = activity('namespace')
// a(navigateMap('xxx', {fr:'ma carte'}))
// a(map.id, md.title)
