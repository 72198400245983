import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import * as interaction from 'ol/interaction';
import Vector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import { DrawEvent } from 'ol/interaction/Draw';

import { MeasureOptions, withInteraction, InteractionMeasure } from '..';
import { VectorLayer } from '../map';
import { fromNullable } from 'fp-ts/lib/Option';

// measure
const measureHandlers = ({
    updateMeasureCoordinates,
    stopMeasuring,
    source,
}: MeasureOptions & { source: SourceVector<Geometry> }) => {
    const startMeasureLength = (e: DrawEvent) => {
        const feature: Feature<Geometry> = e.feature;
        const line = <LineString>feature.getGeometry();
        line.on('change', () => {
            updateMeasureCoordinates(line.getCoordinates());
        });
    };

    const stopMeasureLength = () => () => {
        source.clear();
        stopMeasuring();
    };

    const startMeasureArea = (e: DrawEvent) => {
        const feature: Feature<Geometry> = e.feature;
        fromNullable(feature.getGeometry()).map((polygon: Polygon) => {
            polygon.on('change', () =>
                fromNullable(polygon?.getLinearRing(0)?.getCoordinates()).map(
                    updateMeasureCoordinates
                )
            );
        });
    };

    const stopMeasureArea = () => () => {
        source.clear();
        stopMeasuring();
    };

    return {
        startMeasureLength,
        stopMeasureLength,
        startMeasureArea,
        stopMeasureArea,
    };
};

export const measure = (options: MeasureOptions) => {
    const measureSource = new SourceVector();
    const measureLayer = new Vector({
        source: measureSource,
    });
    const measureLength = new interaction.Draw({
        type: 'LineString',
        source: measureSource,
    });
    const measureArea = new interaction.Draw({
        type: 'Polygon',
        source: measureSource,
    });

    const {
        startMeasureLength,
        stopMeasureLength,
        startMeasureArea,
        stopMeasureArea,
    } = measureHandlers({ ...options, source: measureSource });

    measureLength.on('drawstart', startMeasureLength);
    measureLength.on('drawend', stopMeasureLength);
    measureArea.on('drawstart', startMeasureArea);
    measureArea.on('drawend', stopMeasureArea);

    const isMeasuring = () =>
        measureLength.getActive() || measureArea.getActive();

    const update = withInteraction<InteractionMeasure>(
        'measure',
        ({ state }) => {
            if (!isMeasuring()) {
                measureSource.clear();
            }
            switch (state.geometryType) {
                case 'LineString':
                    measureLength.setActive(true);
                    break;
                case 'Polygon':
                    measureArea.setActive(true);
                    break;
            }
        },
        () => {
            measureSource.clear();
            measureLength.setActive(false);
            measureArea.setActive(false);
        }
    );

    const init = (map: Map, layers: Collection<VectorLayer>) => {
        layers.push(measureLayer);
        map.addInteraction(measureLength);
        map.addInteraction(measureArea);
    };

    return { init, update };
};
