import tr from '../locale';
import { DIV, SPAN, A, DETAILS, SUMMARY, BUTTON } from './elements';
import { nameToString } from './button/names';
import { checkURLScheme } from '../util';

export type ExportForm = 'csv' | 'xlsx';
type UrlFn = (form: ExportForm) => string;

export const exportCSVLink = (exportUrl: UrlFn, ariaLabel = '') =>
    A(
        {
            className: 'table-download-link',
            target: '_blank',
            href: checkURLScheme(exportUrl('csv')),
        },
        SPAN(
            {
                className: 'dl-item',
                'aria-label': `${tr.core('exportCSV')} ${ariaLabel}`,
            },
            'csv'
        )
    );

export const exportXLSXLink = (exportUrl: UrlFn, ariaLabel = '') =>
    A(
        {
            className: 'table-download-link',
            target: '_blank',
            href: checkURLScheme(exportUrl('xlsx')),
        },
        SPAN(
            {
                className: 'dl-item',
                'aria-label': `${tr.core('exportXLSX')} ${ariaLabel}`,
            },
            'xlsx'
        )
    );

export const exportSelect = (exportUrl: UrlFn, ariaLabel = '') =>
    DETAILS(
        'download-table',
        SUMMARY('btn btn-3 icon-only', SPAN('icon', nameToString('download'))),
        DIV(
            'download__body',
            DIV('export-title', tr.core('export')),
            DIV('export-link link-csv', exportCSVLink(exportUrl, ariaLabel)),
            DIV('export-link link-xlsx', exportXLSXLink(exportUrl, ariaLabel))
        )
    );

export const exportLinks = (exportUrl: UrlFn, arialLabel = '') =>
    DIV(
        { className: 'table-download' },
        BUTTON(
            'btn btn-3 label-and-icon',
            SPAN('btn-label', exportCSVLink(exportUrl, arialLabel)),
            SPAN('icon', nameToString('download'))
        ),
        BUTTON(
            'btn btn-3 label-and-icon',
            SPAN('btn-label', exportXLSXLink(exportUrl, arialLabel)),
            SPAN('icon', nameToString('download'))
        )
    );
