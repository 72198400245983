import * as debug from 'debug';
import { ReactNode } from 'react';

import { Collection, updateCollection, ensureArray } from '../../util';
import { queryK, dispatchK } from '../../shape';
import { DIV, NODISPLAY } from '../elements';
import { isESCAPE } from '../keycodes';

const logger = debug('sdi:modal');

export type ModalSize = 'large' | 'medium' | 'small';
export type ModalStatus = 'close' | 'open';
export type ModalStatusGetter = () => ModalStatus;
export type ModalStatusSetter = (ms: ModalStatus) => void;

export type ModalState = Collection<ModalStatus>;
export type ModalStateQuery = () => ModalState;
export type ModalStateReducer = (ms: ModalState) => ModalState;
export type ModalStateDispatch = (r: ModalStateReducer) => void;

export type ModalClose = () => void;

type NodeProducer = (close: ModalClose) => ReactNode | ReactNode[];
export interface ModalRenderObject {
    header: NodeProducer;
    footer: NodeProducer;
    body: NodeProducer;
    sizeHint?: ModalSize;
}
export type ModalRenderFactory = () => ModalRenderObject;
export type ModalRender = ModalRenderObject | ModalRenderFactory;

export const emptyModal = (): ModalRenderObject => ({
    header: NODISPLAY,
    footer: NODISPLAY,
    body: NODISPLAY,
});

export const renderModal = (g: ModalStatusGetter, s: ModalStatusSetter) => {
    console.warn(`
        "renderModal" is deprecated.
        You should migrate to the new modal subsystem.
        `);
    const inner = (
        whenClose: ReactNode,
        header: ReactNode,
        footer: ReactNode,
        ...body: ReactNode[]
    ): ReactNode => {
        const cancel = () => s('close');
        if (g() === 'close') {
            return whenClose;
        }

        return DIV(
            {
                className: 'modal__background',
                onKeyDown: ke => {
                    if (isESCAPE(ke)) {
                        cancel();
                    }
                }, // FIXME: doesnt work, but dont know why, yet
                onClick: cancel,
            },
            DIV(
                {
                    className: 'modal__box modal-size-large',
                    onClick: e => e.stopPropagation(),
                },
                DIV(
                    { className: 'modal__inner' },
                    DIV({ className: 'modal__header' }, header),
                    DIV({ className: 'modal__content' }, ...body),
                    DIV({ className: 'modal__footer' }, footer)
                )
            )
        );
    };

    return inner;
};

const query = queryK('sdi/component/info/modal/status');
const dispatch = dispatchK('sdi/component/info/modal/status');

const fromFactOrObj =
    (k: keyof Omit<ModalRenderObject, 'sizeHint'>) =>
    (m: ModalRender, close: ModalClose) => {
        if (typeof m === 'function') {
            const p = m()[k];
            return ensureArray(p(close));
        }
        return ensureArray(m[k](close));
    };

const renderHeader = fromFactOrObj('header');
const renderFooter = fromFactOrObj('footer');
const renderBody = fromFactOrObj('body');
const sizeHint = (m: ModalRender) => {
    const mr = typeof m === 'function' ? m() : m;
    return mr.sizeHint ?? 'large';
};

/**
 * Renderers must be available no matter
 * which instance of init has been used;
 */
const renderers: Collection<ModalRender> = {};

export const init = () => {
    document.addEventListener('keyup', (ke: unknown) => {
        /**
         * It might happen that it will be triggered when not intended,
         * it shouldn't be such a problem as we just dispatch for modals though.
         * Let's see - pm
         */
        if (isESCAPE(ke as React.KeyboardEvent<Element>)) {
            dispatch(ms => {
                for (const modalName in ms) {
                    const status = ms[modalName];
                    if (status === 'open') {
                        return updateCollection(ms, modalName, 'close');
                    }
                }
                return ms;
            });
        }
    });

    const render = () => {
        const state = query();
        const modalName = Object.keys(state).find(key => state[key] === 'open');
        if (modalName === undefined) {
            return NODISPLAY();
        }
        const renderer = renderers[modalName];
        if (renderer === undefined) {
            return NODISPLAY();
        }
        const cancel = () =>
            dispatch(ms => updateCollection(ms, modalName, 'close'));

        const header = renderHeader(renderer, cancel);
        const body = renderBody(renderer, cancel);
        const footer = renderFooter(renderer, cancel);
        const size = sizeHint(renderer);

        return DIV(
            {
                className: 'modal__background',
                onClick: cancel,
            },
            DIV(
                {
                    className: `modal__box modal-size-${size}`,
                    onClick: e => e.stopPropagation(),
                },
                DIV(
                    { className: 'modal__inner' },
                    DIV({ className: 'modal__header' }, ...header),
                    DIV({ className: 'modal__content' }, ...body),
                    DIV({ className: 'modal__footer' }, ...footer)
                )
            )
        );
    };

    const open = (name: string) =>
        dispatch(ms => {
            logger(`open ${name}`);
            const keys = Object.keys(ms);
            keys.forEach(k => (ms[k] = 'close'));
            ms[name] = 'open';
            return ms;
        });

    const close = (name: string) =>
        dispatch(ms => updateCollection(ms, name, 'close'));

    const register = (name: string, r: ModalRender) => {
        if (name in renderers) {
            throw `Hmm, we've got this modal renderer already ${name}`;
        }
        renderers[name] = r;
        return [
            // close
            () => close(name),
            // open
            () => open(name),
        ];
    };

    return { render, register, open };
};

logger('loaded');
