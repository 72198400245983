/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import * as debug from 'debug';

import 'sdi/polyfill';
import { displayException } from 'sdi/app';
import { defaultToolGeocoder } from 'sdi/ports/geocoder';
import { initialTableState } from 'sdi/components/table';
import { defaultTableState } from 'sdi/components/table2';
import {
    source,
    AppConfigIO,
    getMessage,
    initialStreamingState,
    remoteNone,
} from 'sdi/source';
import { IShape, configure, defaultShape } from 'sdi/shape';
import {
    defaultInteraction,
    defaultPrintRequest,
    defaultPrintResponse,
} from 'sdi/map';

import './shape';
import './locale';
import App from './app';
import { AppLayout, defaultHomeConfigTile } from './shape/types';
import { defaultPrintState } from './components/print';
import './shape';
import './locale';

const logger = debug('sdi:index');

export const main = (SDI: unknown) => {
    AppConfigIO.decode(SDI).fold(
        errors => {
            const textErrors = errors.map(e => getMessage(e.value, e.context));
            displayException(textErrors.join('\n'));
        },
        config => {
            const initialState: IShape = {
                'app/codename': 'view',
                ...defaultShape(config),
                'app/user': config.user,
                'app/root': config.root,
                'app/api-root': config.api,
                'app/csrf': config.csrf,
                'app/lang': 'fr',
                'app/layout': AppLayout.MapNavigatorFS,
                'app/map-ready': false,
                'app/current-map': null,
                'app/current-layer': null,
                // 'app/current-feature': null,
                'app/selected-features': [],
                'app/route': config.args,
                'app/table/selected': false,
                'component/legend': {
                    currentPage: 'info',
                },

                'component/menu': {
                    folded: true,
                },

                'component/home': defaultHomeConfigTile(),

                'component/table': defaultTableState(),
                'component/table/extract': initialTableState(),

                'component/timeserie': {},

                'component/legend/expand-image': false,

                'component/legend/show-wms-legend': false,

                'component/legend/webservices': {
                    folded: true,
                    url: '',
                    layers: [],
                },

                'component/legend/geocoder': defaultToolGeocoder(),

                'component/legend/positioner': {
                    point: {
                        latitude: 0,
                        longitude: 0,
                    },
                },

                'component/legend/share': {
                    withView: false,
                },

                'component/harvest/geometry': null,
                'component/harvest/geometry-type': null,
                'component/harvest/results': [],
                'component/harvest/minimap': {},

                'component/button': {},
                'component/bookmark/current-index': null,

                'port/map/scale': {
                    count: 0,
                    unit: '',
                    width: 0,
                },

                'port/map/view': {
                    dirty: 'geo',
                    srs: 'EPSG:3857',
                    center: [-1313000, 1975000],
                    rotation: 0,
                    zoom: 7,
                    feature: null,
                    extent: null,
                },

                'port/map/interaction': defaultInteraction(),
                'port/map/printRequest': defaultPrintRequest(),
                'port/map/printResponse': defaultPrintResponse(),

                'component/print': defaultPrintState(),

                'port/map/loading': [],
                'data/layers': {},
                'data/maps': remoteNone,
                'data/alias': [],
                'data/timeseries': {},
                'data/categories': [],
                'data/datasetMetadata': {},
                'data/attachments': [],
                'data/baselayers': [],
                'data/links': {},

                'remote/errors': {},

                'data/features/stream': initialStreamingState(),
                'data/users': [],
            };

            try {
                const start = source<IShape>(['app/lang']);
                const store = start(initialState);
                configure(store);
                const app = App(store);
                logger('start rendering');
                app();
            } catch (err) {
                displayException(`${err}`);
            }
        }
    );
};

logger('loaded');
