import {
    BUTTON,
    DETAILS,
    DIV,
    INPUT,
    LABEL,
    NodeOrOptional,
    OPTION,
    SELECT,
    SPAN,
    SUMMARY,
} from '../elements';
import { Setoid } from 'fp-ts/lib/Setoid';
import { ReactNode, Component, createElement } from 'react';
import { none, Option, some } from 'fp-ts/lib/Option';
import tr from '../../locale';
import { tryNumber } from '../../util';
import { index } from 'fp-ts/lib/Array';
import { makeIcon } from '../../components/button';
import { setoidString } from 'fp-ts/lib/Setoid';
import { identity } from 'fp-ts/lib/function';
import { isSelectOpen } from '../../app';

const renderFilterSelectItem =
    <T>(renderItem: (a: T) => ReactNode, select: (a: T) => void) =>
    (v: T) =>
        BUTTON(
            {
                className: 'tail-item',
                onClick: () => {
                    select(v);
                },
            },
            SPAN('item__control'),
            DIV('item__label', renderItem(v))
        );

// type State = [string, boolean];
// let stateList: State[] = [];

// const toggle = (id: string, newStatus: boolean) => {
//     stateList = stateList
//         .filter(([i, _]) => i !== id)
//         .map<State>(([i, _]) => [i, false])
//         .concat([[id, newStatus]]);
//     __forceRefreshState()
// };

// export const renderSelect = <T>(
//     S: Setoid<T>,
//     renderItem: (a: T) => ReactNode,
//     select: (a: T) => void,
//     key = uniqId(),
// ) => {
//     stateList.push([key, false]);

//     const mkItem = renderSelectItem(renderItem, select);

//     return (list: T[] | Readonly<T[]>, selected: Option<T>) => {

//         const isOpen = fromNullable(stateList.find(([i, status]) => i === key && status === true));
//         const renderTail = isOpen.map(() => {
//             const tail = list
//                 .filter(i => selected.fold(true, s => S.equals(i, s) !== true))
//                 .map(mkItem);
//             return DIV({ className: 'tail' }, ...tail)
//         })

//         return DIV(
//             {
//                 className: 'select__wrapper active',
//                 onClick: () => toggle(key, isOpen.isNone())
//             },
//             DIV(
//                 {
//                     className: 'select'
//                     // FIXME this is a temporary hack, we should be VDOMy
//                 },
//                 renderSelected(renderItem, selected),
//                 renderTail,
//             )
//         );
//     }
// };

const renderSelectItem =
    <T>(toString: (a: T) => string, isSelected: boolean) =>
    (object: T, i: number) =>
        OPTION(
            {
                className: 'interactive',
                value: i,
                selected: isSelected,
            },
            toString(object)
        );

const renderDefaultItem = <T>(selected: Option<T>, defaultStr: string) =>
    selected.isNone()
        ? some(
              OPTION(
                  {
                      disabled: true,
                      selected: true,
                  },
                  defaultStr
              )
          )
        : none;

export const renderSelect =
    <T>(
        name: string,
        toString: (a: T) => string,
        select: (a: T) => void,
        eq: Setoid<T>,
        label?: string
    ) =>
    (list: T[] | Readonly<T[]>, selected: Option<T>) =>
        DIV(
            `select ${name}`,
            LABEL('', label),
            SELECT(
                {
                    name,
                    id: name,
                    key: name,
                    onChange: ev =>
                        tryNumber(ev.currentTarget.value)
                            .chain(i => index(i, [...list]))
                            .map(select),
                },
                ...[...list].map((e, i) =>
                    renderSelectItem(
                        toString,
                        selected.fold(false, s => eq.equals(s, e))
                    )(e, i)
                )
            )
        );

export const renderSelectWithDefaultStr =
    <T>(
        name: string,
        toString: (a: T) => string,
        select: (a: T) => void,
        eq: Setoid<T>,
        defaultStr: string,
        label?: string
    ) =>
    (list: T[] | Readonly<T[]>, selected: Option<T>) =>
        DIV(
            'select',
            LABEL('', label),
            SELECT(
                {
                    name,
                    id: name,
                    onChange: ev =>
                        tryNumber(ev.currentTarget.value)
                            .chain(i => index(i, [...list]))
                            .map(select),
                },
                ...[...list]
                    .map<NodeOrOptional>((e, i) =>
                        renderSelectItem(
                            toString,
                            selected.fold(false, s => eq.equals(s, e))
                        )(e, i)
                    )
                    .concat(renderDefaultItem(selected, defaultStr))
            )
        );

interface FilterState {
    input: string;
    active: boolean;
}

export interface FilterProps<T> {
    list: T[] | Readonly<T[]>;
    renderItem: (a: T) => ReactNode;
    select: (a: T) => void;
    selected: Option<T>;
    S: Setoid<T>;
    toString: (a: T) => string;
    title: () => string;
}

class SelectFilter<T> extends Component<FilterProps<T>, FilterState> {
    constructor(props: FilterProps<T>) {
        super(props);
    }

    render() {
        const { renderItem, select, list, selected, S, toString, title } =
            this.props;
        const inputValue = this.state?.input ?? '';
        const active = this.state?.active ?? false;
        const pat = inputValue.toLocaleLowerCase();
        const mkItem = renderFilterSelectItem(renderItem, select);
        const tail = list
            .filter(
                i =>
                    toString(i).toLocaleLowerCase().includes(pat) &&
                    selected.fold(true, s => S.equals(i, s) !== true)
            )
            .map(mkItem);

        const input = INPUT({
            type: 'text',
            placeholder: tr.core('filter'),
            onChange: el =>
                this.setState({ active, input: el.currentTarget.value }),
        });

        return DETAILS(
            `select-filter__wrapper`,
            SUMMARY(
                '',
                selected.map(renderItem).getOrElse(title())
                // DIV('btn btn-3 icon-only', nameToString('angle-down'))
            ),
            DIV('select-tail__wrapper', input, DIV('tail', ...tail))
        );
    }
}

export const renderSelectFilter =
    <T>(
        S: Setoid<T>,
        renderItem: (a: T) => ReactNode,
        select: (a: T) => void,
        toString: (a: T) => string,
        title = () => ''
    ) =>
    (list: T[] | Readonly<T[]>, selected: Option<T>) => {
        const props: FilterProps<T> = {
            S,
            renderItem,
            select,
            toString,
            list,
            selected,
            title,
        };
        return createElement(SelectFilter, props);
    };

// We also need a select with a filter, where we also can add an élément if not in the list:

export interface FilterOrActionProps<T> {
    list: T[] | Readonly<T[]>;
    renderItem: (a: T) => ReactNode;
    select: (a: T) => void;
    selected: Option<T>;
    S: Setoid<T>;
    toString: (a: T) => string;
    action: (input: string) => void;
    key: string;
    title: () => string;
}

export class SelectFilterAdd extends Component<
    FilterOrActionProps<string>,
    FilterState
> {
    constructor(props: FilterOrActionProps<string>) {
        super(props);
    }

    render() {
        const {
            renderItem,
            select,
            list,
            selected,
            S,
            toString,
            action,
            key,
            title,
        } = this.props;
        const inputValue = this.state?.input ?? '';
        const active = this.state?.active ?? false;

        const pat = inputValue.toLocaleLowerCase();
        const mkItem = renderFilterSelectItem(renderItem, select);
        const tail = list
            .filter(
                i =>
                    toString(i).toLocaleLowerCase().includes(pat) &&
                    selected.fold(true, s => S.equals(i, s) !== true)
            )
            .map(mkItem);

        const input = INPUT({
            type: 'text',
            placeholder: tr.core('filter'),
            onChange: el =>
                this.setState({ active, input: el.currentTarget.value }),
        });

        const addButton = makeIcon('add', 3, 'plus', {
            text: () => tr.core('add'),
            position: 'top-left',
        });

        return DETAILS(
            {
                className: `select-filter__wrapper`,
                open: isSelectOpen(key),
            },
            SUMMARY(
                '',
                selected
                    .chain(s => (s === '' ? none : some(renderItem(s))))
                    .getOrElse(title())
                // DIV('btn btn-3 icon-only', nameToString('angle-down'))
            ),
            DIV(
                'select-tail__wrapper',
                SPAN(
                    'input__wrapper',
                    input,
                    addButton(() => action(inputValue))
                ),
                DIV('tail', ...tail)
            )
        );
    }
}

export const renderSelectFilterAdd =
    (
        renderItem: (a: string) => ReactNode,
        select: (a: string) => void,
        action: (input: string) => void,
        title = () => ''
    ) =>
    (
        list: string[] | Readonly<string[]>,
        selected: Option<string>,
        key: string
    ) => {
        const props: FilterOrActionProps<string> = {
            S: setoidString,
            renderItem,
            select,
            toString: identity,
            list,
            selected,
            action,
            key,
            title,
        };
        return createElement(SelectFilterAdd, props);
    };
