import { TableDataRow } from '.';
import { StreamingField } from '../../source';
import { fromNullable } from 'fp-ts/lib/Option';
import { findTerm } from '../../app';
import { fromRecord } from '../../locale';

export type SortDirection = 'ASC' | 'DESC';

export interface TableSort {
    col: number;
    direction: SortDirection;
}

type Sortable = string | number;

interface SortMapEntry {
    index: number;
    value: Sortable;
}

const timestamp = (x: string) => {
    const ts = Date.parse(x);
    if (Number.isNaN(ts)) {
        return 0;
    }
    return ts;
};
const bool = (x: boolean) => (x ? 1 : 0);
const lower = (x: string) => x.toString().toLocaleLowerCase();

const comp = (a: Sortable, b: Sortable) => (a < b ? -1 : a > b ? 1 : 0);

const sortMap = (
    data: TableDataRow[],
    direction: SortDirection,
    col: number,
    map: (a: unknown) => Sortable
) => {
    const sortMap = data
        .map((r, k) => ({
            index: k,
            value: map(r.cells[col]),
        }))
        .sort((a: SortMapEntry, b: SortMapEntry) => comp(a.value, b.value));
    if (direction === 'DESC') {
        sortMap.reverse();
    }

    return sortMap;
};

export const sortRows =
    (col: number, direction: SortDirection, fields: StreamingField[]) =>
    (data: TableDataRow[]) =>
        fromNullable(fields[col]).fold(data, ([, fieldType]) => {
            const output = ({ index }: SortMapEntry) => data[index];
            switch (fieldType) {
                case 'string':
                    return sortMap(data, direction, col, lower).map(output);
                case 'term':
                    return sortMap(data, direction, col, (x: number) =>
                        findTerm(x)
                            .map<Sortable>(t => fromRecord(t.name))
                            .getOrElse(x)
                    ).map(output);
                case 'number':
                    return sortMap(data, direction, col, (x: number) => x).map(
                        output
                    );
                case 'date':
                case 'datetime':
                    return sortMap(data, direction, col, timestamp).map(output);
                case 'boolean':
                    return sortMap(data, direction, col, bool).map(output);
            }
        });
