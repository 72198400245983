/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { dispatch } from 'sdi/shape';
import { IMapBaseLayer } from 'sdi/source';

import { LegendPage } from '../shape/types';
import { IUgWsResponse } from 'sdi/ports/geocoder';

export const setPage = (p: LegendPage) => {
    dispatch('component/legend', state => {
        state.currentPage = p;
        return state;
    });
};

export const setImageExpand = (b: boolean) => {
    dispatch('component/legend/expand-image', () => b);
};

export const setWMSLegendVisible = (b: boolean) => {
    dispatch('component/legend/show-wms-legend', () => b);
};

export const updateWebServiceURL = (url: string) => {
    dispatch('component/legend/webservices', state => ({ ...state, url }));
};

export const updateWebServiceLayers = (services: IMapBaseLayer[]) => {
    dispatch('component/legend/webservices', state => {
        state.layers = services;
        state.folded = false;
        return state;
    });
};

// export const addWebServiceLayer = (lyr: IMapBaseLayer) => {
//     dispatch('port/map/baseLayers', bl => bl.concat([lyr]));
// };

export const updateGeocoderTerm = (address: string) => {
    dispatch('component/legend/geocoder', state => ({ ...state, address }));
};

export const updateGeocoderResponse = (
    serviceResponse: IUgWsResponse | null
) => {
    dispatch('component/legend/geocoder', state => {
        state.serviceResponse = serviceResponse;
        return state;
    });
};

export const unfoldGeocoder = () => {
    dispatch('component/legend/geocoder', state => {
        state.folded = false;
        return state;
    });
};

export const foldGeocoder = () => {
    dispatch('component/legend/geocoder', state => {
        state.folded = true;
        return state;
    });
};

export const updatePositionerLatitude = (lat: number) => {
    dispatch('component/legend/positioner', state => {
        state.point.latitude = lat;
        return state;
    });
};

export const updatePositionerLongitude = (lon: number) => {
    dispatch('component/legend/positioner', state => {
        state.point.longitude = lon;
        return state;
    });
};

export const shareWithView = (b: boolean) => {
    dispatch('component/legend/share', state => {
        state.withView = b;
        return state;
    });
};
