import * as debug from 'debug';

import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';

import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Vector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import Point from 'ol/geom/Point';

import {
    TrackerOptions,
    withInteraction,
    setoidTrackerCoordinate,
    TrackerCoordinate,
    InteractionTrack
} from '..';
import { last } from 'fp-ts/lib/Array';
import { scopeOption } from '../../lib';
import { fromNullable } from 'fp-ts/lib/Option';
import { VectorLayer } from '../map';

const logger = debug('sdi:map/tracker');

const trackerStyles = (accuracy: number, res: number) => [
    new Style({
        image: new Circle({
            radius: accuracy / res,
            fill: new Fill({
                color: 'rgba(255,255,255,.3)',
            }),
            stroke: new Stroke({
                color: '#FF4C00',
                width: 1,
            }),
        }),
    }),
    new Style({
        image: new Circle({
            radius: 6,
            fill: new Fill({
                color: '#3399CC',
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2,
            }),
        }),
    }),
];

export const track = ({
    updateTrack,
    resetTrack,
    setCenter,
}: TrackerOptions) => {
    let lastPosition: TrackerCoordinate | null = null;

    const follow = () => {
        if (lastPosition !== null) {
            setCenter(lastPosition.coord);
        }
    };

    const tracker = (geoloc: Geolocation) => () =>
        scopeOption()
            .let('coord', fromNullable(geoloc.getPosition()))
            .let('accuracy', fromNullable(geoloc.getAccuracy()))
            .map(({ coord, accuracy }) =>
                updateTrack({
                    coord,
                    accuracy,
                })
            );
    // geolocation
    const geolocation = new Geolocation({
        projection: 'EPSG:3857', //can we make a map.getView().getProjection() ?
        tracking: false,
    });

    geolocation.on('change', tracker(geolocation));

    const geolocationSource = new SourceVector();
    const geolocationLayer = new Vector({
        source: geolocationSource,
    });

    const isTracking = () => geolocation.getTracking();

    const update = withInteraction<InteractionTrack>(
        'track',
        ({ state }) => {
            geolocationSource.clear();
            if (!isTracking()) {
                resetTrack();
            }
            geolocation.setTracking(true);

            const features = state.track.map(coords => {
                const accuracy = coords.accuracy;
                const f = new Feature({
                    geometry: new Point(coords.coord),
                });
                f.setStyle((_f, r) => {
                    return trackerStyles(accuracy, r);
                });
                return f;
            });
            geolocationSource.addFeatures(features);
            last(state.track).map(tc => {
                if (
                    lastPosition === null ||
                    !setoidTrackerCoordinate.equals(tc, lastPosition)
                ) {
                    lastPosition = tc;
                    follow();
                }
            });
        },
        () => {
            geolocationSource.clear();
            geolocation.setTracking(false);
        }
    );

    const init = (_map: Map, layers: Collection<VectorLayer>) => {
        layers.push(geolocationLayer);
    };

    return { init, update };
};

logger('loaded');
