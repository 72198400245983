import { none, some } from 'fp-ts/lib/Option';
import { iife } from '../../lib';

const reservedNames = {
    'tags+plus': 0x0000,
};

export const pictoCircle = {
    CircleFill: 0xee00,
    CircleFillDot: 0xee01,
    CircleThin: 0xee02,
    CircleThinDot: 0xee03,
    CircleThick: 0xee04,
    CircleThickDot: 0xee05,
};

export const pictoTriangle = {
    // TriangleEFill: 0xee06,
    // TriangleEFillDot: 0xee07,
    // TriangleEThin: 0xee08,
    // TriangleEThinDot: 0xee09,
    // TriangleEThick: 0xee0a,
    // TriangleEThickDot: 0xee0b,
    TriangleNFill: 0xee0c,
    TriangleNFillDot: 0xee0d,
    TriangleNThin: 0xee0e,
    TriangleNThinDot: 0xee0f,
    TriangleNThick: 0xee10,
    TriangleNThickDot: 0xee11,
    // TriangleWFill: 0xee12,
    // TriangleWFillDot: 0xee13,
    // TriangleWThin: 0xee14,
    // TriangleWThinDot: 0xee15,
    // TriangleWThick: 0xee16,
    // TriangleWThickDot: 0xee17,
    TriangleSFill: 0xee18,
    TriangleSFillDot: 0xee19,
    TriangleSThin: 0xee1a,
    TriangleSThinDot: 0xee1b,
    TriangleSThick: 0xee1c,
    TriangleSThickDot: 0xee1d,
};
export const pictoSquare = {
    SquareEFill: 0xee1e,
    SquareEFillDot: 0xee1f,
    SquareEThin: 0xee20,
    SquareEThinDot: 0xee21,
    SquareEThick: 0xee22,
    SquareEThickDot: 0xee23,
    // SquareNFill: 0xee24,
    // SquareNFillDot: 0xee25,
    // SquareNThin: 0xee26,
    // SquareNThinDot: 0xee27,
    // SquareNThick: 0xee28,
    // SquareNThickDot: 0xee29,
};
export const pictoLosange = {
    LosangeEFill: 0xee2a,
    LosangeEFillDot: 0xee2b,
    LosangeEThin: 0xee2c,
    LosangeEThinDot: 0xee2d,
    LosangeEThick: 0xee2e,
    LosangeEThickDot: 0xee2f,
    // LosangeNFill: 0xee30,
    // LosangeNFillDot: 0xee31,
    // LosangeNThin: 0xee32,
    // LosangeNThinDot: 0xee33,
    // LosangeNThick: 0xee34,
    // LosangeNThickDot: 0xee35,
};
export const pictoPentagon = {
    PentagonEFill: 0xee36,
    PentagonEFillDot: 0xee37,
    PentagonEThin: 0xee38,
    PentagonEThinDot: 0xee39,
    PentagonEThick: 0xee3a,
    PentagonEThickDot: 0xee3b,
    // PentagonNFill: 0xee3c,
    // PentagonNFillDot: 0xee3d,
    // PentagonNThin: 0xee3e,
    // PentagonNThinDot: 0xee3f,
    // PentagonNThick: 0xee40,
    // PentagonNThickDot: 0xee41,
    // PentagonWFill: 0xee42,
    // PentagonWFillDot: 0xee43,
    // PentagonWThin: 0xee44,
    // PentagonWThinDot: 0xee45,
    // PentagonWThick: 0xee46,
    // PentagonWThickDot: 0xee47,
    // PentagonSFill: 0xee48,
    // PentagonSFillDot: 0xee49,
    // PentagonSThin: 0xee4a,
    // PentagonSThinDot: 0xee4b,
    // PentagonSThick: 0xee4c,
    // PentagonSThickDot: 0xee4d,
};
export const pictoHexagon = {
    HexagonEFill: 0xee4e,
    HexagonEFillDot: 0xee4f,
    HexagonEThin: 0xee50,
    HexagonEThinDot: 0xee51,
    HexagonEThick: 0xee52,
    HexagonEThickDot: 0xee53,
    // HexagonNFill: 0xee54,
    // HexagonNFillDot: 0xee55,
    // HexagonNThin: 0xee56,
    // HexagonNThinDot: 0xee57,
    // HexagonNThick: 0xee58,
    // HexagonNThickDot: 0xee59,
};
const pictos = {
    ...pictoCircle,
    ...pictoTriangle,
    ...pictoSquare,
    ...pictoLosange,
    ...pictoPentagon,
    ...pictoHexagon,
};

const pictoApp = {
    LogoBrusolar: 0xeea0,
    LogoBrugeotool: 0xeea1,
    LogoBruwater: 0xeea2,
    LogoInfiltrasoil: 0xeea3,
};

const names = {
    ...reservedNames,
    ...pictos,
    ...pictoApp,
    'accessible-icon': 0xf368,
    'address-book': 0xf2b9,
    'address-card': 0xf2bb,
    adjust: 0xf042,
    'align-center': 0xf037,
    'align-justify': 0xf039,
    'align-left': 0xf036,
    'align-right': 0xf038,
    ambulance: 0xf0f9,
    'american-sign-language-interpreting': 0xf2a3,
    anchor: 0xf13d,
    'angle-double-down': 0xf103,
    'angle-double-left': 0xf100,
    'angle-double-right': 0xf101,
    'angle-double-up': 0xf102,
    'angle-down': 0xf107,
    'angle-left': 0xf104,
    'angle-right': 0xf105,
    'angle-up': 0xf106,
    archive: 0xf187,
    'arrow-alt-circle-down': 0xf358,
    'arrow-alt-circle-left': 0xf359,
    'arrow-alt-circle-right': 0xf35a,
    'arrow-alt-circle-up': 0xf35b,
    'arrow-circle-down': 0xf0ab,
    'arrow-circle-left': 0xf0a8,
    'arrow-circle-right': 0xf0a9,
    'arrow-circle-up': 0xf0aa,
    'arrow-down': 0xf063,
    'arrow-left': 0xf060,
    'arrow-right': 0xf061,
    'arrow-up': 0xf062,
    'arrows-alt': 0xf0b2,
    'arrows-alt-h': 0xf337,
    'arrows-alt-v': 0xf338,
    'assistive-listening-systems': 0xf2a2,
    asterisk: 0xf069,
    at: 0xf1fa,
    'audio-description': 0xf29e,
    backward: 0xf04a,
    'balance-scale': 0xf24e,
    ban: 0xf05e,
    barcode: 0xf02a,
    bars: 0xf0c9,
    bath: 0xf2cd,
    'battery-empty': 0xf244,
    'battery-full': 0xf240,
    'battery-half': 0xf242,
    'battery-quarter': 0xf243,
    'battery-three-quarters': 0xf241,
    bed: 0xf236,
    beer: 0xf0fc,
    bell: 0xf0f3,
    'bell-slash': 0xf1f6,
    bicycle: 0xf206,
    binoculars: 0xf1e5,
    biometric: 0xf32b,
    'birthday-cake': 0xf1fd,
    blind: 0xf29d,
    bold: 0xf032,
    bolt: 0xf0e7,
    bomb: 0xf1e2,
    book: 0xf02d,
    bookmark: 0xf02e,
    braille: 0xf2a1,
    briefcase: 0xf0b1,
    bug: 0xf188,
    building: 0xf1ad,
    bullhorn: 0xf0a1,
    bullseye: 0xf140,
    bus: 0xf207,
    calculator: 0xf1ec,
    calendar: 0xf133,
    'calendar-alt': 0xf073,
    'calendar-check': 0xf274,
    'calendar-minus': 0xf272,
    'calendar-plus': 0xf271,
    'calendar-times': 0xf273,
    camera: 0xf030,
    'camera-retro': 0xf083,
    car: 0xf1b9,
    'caret-down': 0xf0d7,
    'caret-left': 0xf0d9,
    'caret-right': 0xf0da,
    'caret-square-down': 0xf150,
    'caret-square-left': 0xf191,
    'caret-square-right': 0xf152,
    'caret-square-up': 0xf151,
    'caret-up': 0xf0d8,
    'cart-arrow-down': 0xf218,
    'cart-plus': 0xf217,
    certificate: 0xf0a3,
    'chart-area': 0xf1fe,
    'chart-bar': 0xf080,
    'chart-line': 0xf201,
    'chart-pie': 0xf200,
    check: 0xf00c,
    'check-circle': 0xf058,
    'check-square': 0xf14a,
    'check-square-o': 0xf046,
    'chevron-circle-down': 0xf13a,
    'chevron-circle-left': 0xf137,
    'chevron-circle-right': 0xf138,
    'chevron-circle-up': 0xf139,
    'chevron-down': 0xf078,
    'chevron-left': 0xf053,
    'chevron-right': 0xf054,
    'chevron-up': 0xf077,
    child: 0xf1ae,
    circle: 0xf111,
    'circle-notch': 0xf1ce,
    clipboard: 0xf328,
    clock: 0xf017,
    clone: 0xf24d,
    'closed-captioning': 0xf20a,
    cloud: 0xf0c2,
    'cloud-download-alt': 0xf381,
    'cloud-upload-alt': 0xf382,
    code: 0xf121,
    'code-branch': 0xf126,
    coffee: 0xf0f4,
    cog: 0xf013,
    cogs: 0xf085,
    columns: 0xf0db,
    comment: 0xf075,
    'comment-dots': 0xf4ad,
    comments: 0xf086,
    compass: 0xf14e,
    compress: 0xf066,
    copy: 0xf0c5,
    copyright: 0xf1f9,
    'creative-commons': 0xf25e,
    'credit-card': 0xf09d,
    crop: 0xf125,
    crosshairs: 0xf05b,
    cube: 0xf1b2,
    cubes: 0xf1b3,
    cut: 0xf0c4,
    database: 0xf1c0,
    deaf: 0xf2a4,
    desktop: 0xf108,
    'dollar-sign': 0xf155,
    'dot-circle': 0xf192,
    download: 0xf019,
    edit: 0xf044,
    eject: 0xf052,
    'ellipsis-h': 0xf141,
    'ellipsis-v': 0xf142,
    envelope: 0xf0e0,
    'envelope-open': 0xf2b6,
    'envelope-square': 0xf199,
    eraser: 0xf12d,
    'euro-sign': 0xf153,
    'exchange-alt': 0xf362,
    exclamation: 0xf12a,
    'exclamation-circle': 0xf06a,
    'exclamation-triangle': 0xf071,
    expand: 0xf065,
    'expand-arrows-alt': 0xf31e,
    'external-link-alt': 0xf35d,
    'external-link-square-alt': 0xf360,
    eye: 0xf06e,
    'eye-dropper': 0xf1fb,
    'eye-slash': 0xf070,
    'fast-backward': 0xf049,
    'fast-forward': 0xf050,
    fax: 0xf1ac,
    female: 0xf182,
    'fighter-jet': 0xf0fb,
    file: 0xf15b,
    'file-alt': 0xf15c,
    'file-archive': 0xf1c6,
    'file-audio': 0xf1c7,
    'file-code': 0xf1c9,
    'file-epub': 0xf321,
    'file-excel': 0xf1c3,
    'file-image': 0xf1c5,
    'file-pdf': 0xf1c1,
    'file-powerpoint': 0xf1c4,
    'file-video': 0xf1c8,
    'file-word': 0xf1c2,
    film: 0xf008,
    filter: 0xf0b0,
    fire: 0xf06d,
    'fire-extinguisher': 0xf134,
    flag: 0xf024,
    'flag-checkered': 0xf11e,
    flask: 0xf0c3,
    folder: 0xf07b,
    'folder-open': 0xf07c,
    font: 0xf031,
    forward: 0xf04e,
    frown: 0xf119,
    futbol: 0xf1e3,
    gamepad: 0xf11b,
    gavel: 0xf0e3,
    gem: 0xf3a5,
    genderless: 0xf22d,
    gift: 0xf06b,
    'glass-martini': 0xf000,
    globe: 0xf0ac,
    'globe-e': 0xf304,
    'globe-w': 0xf305,
    'graduation-cap': 0xf19d,
    'h-square': 0xf0fd,
    'hand-lizard': 0xf258,
    'hand-paper': 0xf256,
    'hand-peace': 0xf25b,
    'hand-point-down': 0xf0a7,
    'hand-point-left': 0xf0a5,
    'hand-point-right': 0xf0a4,
    'hand-point-up': 0xf0a6,
    'hand-pointer': 0xf25a,
    'hand-rock': 0xf255,
    'hand-scissors': 0xf257,
    'hand-spock': 0xf259,
    handshake: 0xf2b5,
    hashtag: 0xf292,
    hdd: 0xf0a0,
    heading: 0xf1dc,
    headphones: 0xf025,
    heart: 0xf004,
    heartbeat: 0xf21e,
    history: 0xf1da,
    home: 0xf015,
    hospital: 0xf0f8,
    hourglass: 0xf254,
    'hourglass-end': 0xf253,
    'hourglass-half': 0xf252,
    'hourglass-start': 0xf251,
    'i-cursor': 0xf246,
    'id-badge': 0xf2c1,
    'id-card': 0xf2c2,
    image: 0xf03e,
    inbox: 0xf01c,
    indent: 0xf03c,
    industry: 0xf275,
    info: 0xf129,
    'info-circle': 0xf05a,
    italic: 0xf033,
    key: 0xf084,
    'key-modern': 0xf2f7,
    keyboard: 0xf11c,
    language: 0xf1ab,
    laptop: 0xf109,
    leaf: 0xf06c,
    lemon: 0xf094,
    'level-down-alt': 0xf3be,
    'level-up-alt': 0xf3bf,
    'life-ring': 0xf1cd,
    lightbulb: 0xf0eb,
    link: 0xf0c1,
    'lira-sign': 0xf195,
    list: 0xf03a,
    'list-alt': 0xf022,
    'list-ol': 0xf0cb,
    'list-ul': 0xf0ca,
    'location-arrow': 0xf124,
    lock: 0xf023,
    'long-arrow-alt-down': 0xf309,
    'long-arrow-alt-left': 0xf30a,
    'long-arrow-alt-right': 0xf30b,
    'long-arrow-alt-up': 0xf30c,
    'low-vision': 0xf2a8,
    magic: 0xf0d0,
    magnet: 0xf076,
    male: 0xf183,
    map: 0xf279,
    'map-marker-alt': 0xf3c5,
    'map-pin': 0xf276,
    'map-signs': 0xf277,
    mars: 0xf222,
    'mars-double': 0xf227,
    'mars-stroke': 0xf229,
    'mars-stroke-h': 0xf22b,
    'mars-stroke-v': 0xf22a,
    medkit: 0xf0fa,
    meh: 0xf11a,
    mercury: 0xf223,
    microchip: 0xf2db,
    microphone: 0xf130,
    'microphone-slash': 0xf131,
    minus: 0xf068,
    'minus-circle': 0xf056,
    'minus-square': 0xf146,
    'mobile-alt': 0xf3cd,
    'money-bill-alt': 0xf3d1,
    moon: 0xf186,
    motorcycle: 0xf21c,
    'mouse-pointer': 0xf245,
    music: 0xf001,
    neuter: 0xf22c,
    newspaper: 0xf1ea,
    'object-group': 0xf247,
    'object-ungroup': 0xf248,
    outdent: 0xf03b,
    'paint-brush': 0xf1fc,
    'paper-plane': 0xf1d8,
    paperclip: 0xf0c6,
    paragraph: 0xf1dd,
    pause: 0xf04c,
    'pause-circle': 0xf28b,
    paw: 0xf1b0,
    'pen-square': 0xf14b,
    'pencil-alt': 0xf303,
    percent: 0xf295,
    phone: 0xf095,
    'phone-square': 0xf098,
    'phone-volume': 0xf2a0,
    plane: 0xf072,
    play: 0xf04b,
    'play-circle': 0xf144,
    plug: 0xf1e6,
    plus: 0xf067,
    'plus-circle': 0xf055,
    'plus-square': 0xf0fe,
    podcast: 0xf2ce,
    'pound-sign': 0xf154,
    'power-off': 0xf011,
    print: 0xf02f,
    'puzzle-piece': 0xf12e,
    qrcode: 0xf029,
    question: 0xf128,
    'question-circle': 0xf059,
    'quote-left': 0xf10d,
    'quote-right': 0xf10e,
    random: 0xf074,
    recycle: 0xf1b8,
    redo: 0xf01e,
    registered: 0xf25d,
    reply: 0xf112,
    'reply-all': 0xf122,
    retweet: 0xf079,
    road: 0xf018,
    rocket: 0xf135,
    rss: 0xf09e,
    'rss-square': 0xf143,
    'ruble-sign': 0xf158,
    'rupee-sign': 0xf156,
    save: 0xf0c7,
    search: 0xf002,
    'search-minus': 0xf010,
    'search-plus': 0xf00e,
    server: 0xf233,
    share: 0xf064,
    'share-square': 0xf14d,
    'shekel-sign': 0xf20b,
    'shield-alt': 0xf3ed,
    ship: 0xf21a,
    'shopping-bag': 0xf290,
    'shopping-basket': 0xf291,
    'shopping-cart': 0xf07a,
    shower: 0xf2cc,
    'sign-in-alt': 0xf2f6,
    'sign-language': 0xf2a7,
    'sign-out-alt': 0xf2f5,
    signal: 0xf012,
    sitemap: 0xf0e8,
    'sliders-h': 0xf1de,
    smile: 0xf118,
    snowflake: 0xf2dc,
    sort: 0xf0dc,
    'sort-alpha-down': 0xf15d,
    'sort-alpha-up': 0xf15e,
    'sort-amount-down': 0xf160,
    'sort-amount-up': 0xf161,
    'sort-down': 0xf0dd,
    'sort-numeric-down': 0xf162,
    'sort-numeric-up': 0xf163,
    'sort-up': 0xf0de,
    'space-shuttle': 0xf197,
    'spell-check': 0xf327,
    spinner: 0xf110,
    square: 0xf0c8,
    'square-o': 0xf096,
    star: 0xf005,
    'star-half': 0xf089,
    'step-backward': 0xf048,
    'step-forward': 0xf051,
    stethoscope: 0xf0f1,
    'sticky-note': 0xf249,
    stop: 0xf04d,
    'stop-circle': 0xf28d,
    'street-view': 0xf21d,
    strikethrough: 0xf0cc,
    subscript: 0xf12c,
    subway: 0xf239,
    suitcase: 0xf0f2,
    sun: 0xf185,
    superscript: 0xf12b,
    sync: 0xf021,
    table: 0xf0ce,
    'tablet-alt': 0xf3fa,
    'tachometer-alt': 0xf3fd,
    tag: 0xf02b,
    tags: 0xf02c,
    tasks: 0xf0ae,
    taxi: 0xf1ba,
    terminal: 0xf120,
    'text-height': 0xf034,
    'text-width': 0xf035,
    th: 0xf00a,
    'th-large': 0xf009,
    'th-list': 0xf00b,
    'thermometer-empty': 0xf2cb,
    'thermometer-full': 0xf2c7,
    'thermometer-half': 0xf2c9,
    'thermometer-quarter': 0xf2ca,
    'thermometer-three-quarters': 0xf2c8,
    'thumbs-down': 0xf165,
    'thumbs-up': 0xf164,
    thumbtack: 0xf08d,
    'ticket-alt': 0xf3ff,
    times: 0xf00d,
    'times-circle': 0xf057,
    tint: 0xf043,
    'toggle-off': 0xf204,
    'toggle-on': 0xf205,
    trademark: 0xf25c,
    train: 0xf238,
    transgender: 0xf224,
    'transgender-alt': 0xf225,
    'trash-alt': 0xf2ed,
    tree: 0xf1bb,
    trophy: 0xf091,
    truck: 0xf0d1,
    tty: 0xf1e4,
    tv: 0xf26c,
    umbrella: 0xf0e9,
    underline: 0xf0cd,
    undo: 0xf0e2,
    'universal-access': 0xf29a,
    university: 0xf19c,
    unlink: 0xf127,
    unlock: 0xf09c,
    'unlock-alt': 0xf13e,
    upload: 0xf093,
    user: 0xf007,
    'user-circle': 0xf2bd,
    'user-md': 0xf0f0,
    'user-plus': 0xf234,
    'user-secret': 0xf21b,
    'user-times': 0xf235,
    users: 0xf0c0,
    'utensil-spoon': 0xf2e5,
    utensils: 0xf2e7,
    venus: 0xf221,
    'venus-double': 0xf226,
    'venus-mars': 0xf228,
    video: 0xf03d,
    'volume-down': 0xf027,
    'volume-off': 0xf026,
    'volume-up': 0xf028,
    wheelchair: 0xf193,
    wifi: 0xf1eb,
    'window-close': 0xf410,
    'window-maximize': 0xf2d0,
    'window-minimize': 0xf2d1,
    'window-restore': 0xf2d2,
    'won-sign': 0xf159,
    wrench: 0xf0ad,
    'yen-sign': 0xf157,
};

type ReservedNames = typeof reservedNames;
type ReservedNameKey = keyof ReservedNames;

export type Names = typeof names;

export type IconName = keyof Names;

export const nameToCode = (n: IconName) => names[n];

const fcp = String.fromCodePoint;

export const nameToString = (n: IconName) => {
    if (n in reservedNames) {
        switch (n as ReservedNameKey) {
            case 'tags+plus':
                return fcp(names['tags']) + ' ' + fcp(names['plus']);
        }
    }

    return fcp(names[n]);
};

export const tryNameToString = (name: string) =>
    name in names ? some(fcp(names[name as IconName])) : none;

export const mapPictos = <T>(
    which:
        | 'circle'
        | 'triangle'
        | 'square'
        | 'losange'
        | 'pentagon'
        | 'hexagon',
    f: (s: IconName, value: number) => T
) => {
    const dict = iife(() => {
        switch (which) {
            case 'circle':
                return pictoCircle;
            case 'triangle':
                return pictoTriangle;
            case 'square':
                return pictoSquare;
            case 'losange':
                return pictoLosange;
            case 'pentagon':
                return pictoPentagon;
            case 'hexagon':
                return pictoHexagon;
        }
    });
    return Object.keys(dict).map((k: IconName) => f(k, names[k]));
};
