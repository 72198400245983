import * as debug from 'debug';
import { Extent } from 'ol/extent';
import { filterNotNull } from '../util';

const logger = debug('sdi:stream');

const last = <T>(a: T[]) => a[a.length - 1];

export type ExtentList = Extent[];

export interface DataStreamId {
    uri: string;
    lid: string;
}

export interface DataStream extends DataStreamId {
    extents: ExtentList;
}

// yep, glocal.
let streams: DataStream[] = [];

const inRect =
    ([ax1, ay1, ax2, ay2]: Extent) =>
    ([bx1, by1, bx2, by2]: Extent) =>
        bx1 >= ax1 && bx2 <= ax2 && by1 >= ay1 && by2 <= ay2;

// const extentToPolygon = ([minx, miny, maxx, maxy]: Extent) => ({
//     type: 'Feature',
//     properties: {},
//     geometry: {
//         type: 'Polygon',
//         coordinates: [[
//             [minx, miny],
//             [minx, maxy],
//             [maxx, maxy],
//             [maxx, miny],
//             [minx, miny],
//         ]]
//     }
// });

// const dumpExtents = (s: DataStream) => {
//     const features = s.extents.map(extentToPolygon);
//     (window as any).dumpExt = JSON.stringify({ type: 'FeatureCollection', features }, null, 2);
//     logger(`Dumped ${features.length} extents`);
// };

const roundExtent = (e: Extent) => [
    Math.floor(e[0]),
    Math.floor(e[1]),
    Math.ceil(e[2]),
    Math.ceil(e[3]),
];

const updateStreamExtent = (uri: string, lid: string, newExtent: Extent) => {
    const idx = streams.findIndex(s => s.lid === lid && s.uri === uri);
    const extent = roundExtent(newExtent);
    if (idx >= 0) {
        const inNew = inRect(extent);
        const extents = streams[idx].extents
            .sort((a, b) => area(a) - area(b))
            .filter(e => !inNew(e))
            .concat([extent]);

        streams[idx].extents = extents;
        // dumpExtents(streams[idx]);
    }
};

type Range = [number, number] & { readonly marker: unique symbol };

const range = (a: number, b: number): Range =>
    (a <= b ? [a, b] : [b, a]) as Range;

const area = ([minx, miny, maxx, maxy]: Extent) =>
    (maxx - minx) * (maxy - miny);

const inRange =
    ([base0, base1]: Range) =>
    ([r0, r1]: Range) =>
        (r0 < base0 && r1 > base1) ||
        (r0 < base0 && r1 > base0) ||
        (r0 < base1 && r1 > base1) ||
        (r0 > base0 && r1 < base1);

// very weird, the non strict comparisions breaks and leave holes if we pan (verically) by small steps ??
// const inRange =
//     ([base0, base1]: Range) => ([r0, r1]: Range) =>
//         (r0 <= base0 && r1 >= base1)
//         || (r0 <= base0 && r1 >= base0)
//         || (r0 <= base1 && r1 >= base1)
//         || (r0 >= base0 && r1 <= base1);

const mergeRanges = (rs: Range[]): Range[] => {
    if (rs.length < 2) {
        return rs;
    }
    const sorted = rs.sort((a, b) => a[0] - b[1]);
    const nrs = [sorted[0]];
    for (let i = 1; i < sorted.length; i += 1) {
        const [lastA, lastB] = last(nrs);
        const [a, b] = sorted[i];
        if (a <= lastB) {
            nrs[i - 1] = range(lastA, b);
        } else {
            nrs.push(range(a, b));
        }
    }
    return rs;
};

const negRanges = (base: Range, rs: Range[]): Range[] => {
    if (rs.length < 1) {
        return [base];
    }
    const result: Range[] = [];
    const ms = mergeRanges(rs);
    let cur = base[0];
    for (let i = 0; i < ms.length; i += 1) {
        const [left, right] = ms[i];
        if (cur < left) {
            result.push(range(cur, left));
        }
        cur = right;
    }
    if (cur < base[1]) {
        result.push(range(cur, base[1]));
    }
    return result;
};

const computeFillExtentList = (
    target: Extent,
    extents: ExtentList
): ExtentList => {
    const [minx, miny, maxx, maxy] = target;
    const yrange = inRange(range(miny, maxy));
    const xrange = inRange(range(minx, maxx));
    const intersects = filterNotNull(
        extents.map(e =>
            xrange(range(e[0], e[2])) && yrange(range(e[1], e[3])) ? e : null
        )
    ).map(([a, b, c, d]) => [
        Math.max(minx, a),
        Math.max(miny, b),
        Math.min(maxx, c),
        Math.min(maxy, d),
    ]);

    if (intersects.length === 0) {
        return [target];
    }

    const results: ExtentList = [];
    const ys = [
        ...new Set(
            intersects.reduce(
                (acc, [, y0, , y1]) => acc.concat([y0, y1]),
                [miny, maxy]
            )
        ).values(),
    ].sort();

    for (let i = 0; i < ys.length - 1; i += 1) {
        const [y0, y1] = range(ys[i], ys[i + 1]);

        const bandRange = inRange(range(y0, y1));
        const obstacles = filterNotNull(
            extents.map(e =>
                bandRange(range(e[1], e[3])) ? range(e[0], e[2]) : null
            )
        );

        negRanges(range(minx, maxx), obstacles).forEach(([x0, x1]) =>
            results.push([x0, y0, x1, y1])
        );
    }

    return results;
};

export const clearStreams = () => {
    streams = [];
};

export const addStream = ({ uri, lid }: DataStreamId) => {
    streams = streams
        .filter(s => s.lid !== lid || s.uri !== uri)
        .concat({ uri, lid, extents: [] });
};

export const mapStream = (
    target: Extent,
    f: (s: DataStream, e: Extent) => void
) =>
    streams.forEach(s => {
        computeFillExtentList(target, s.extents).forEach(e => {
            if (area(e) > 0) {
                logger(target, e, area(e));
                f(s, roundExtent(e));
            }
        });
    });

export const pushStreamExtent = (extent: Extent, { uri, lid }: DataStreamId) =>
    updateStreamExtent(uri, lid, extent);

logger('loaded');

// export const test = () => {
//     const assert = (c: boolean, e = '') => {
//         if (!c) {
//             throw `Assert Failed ${e}`;
//         }
//     };
//     const uri = 'test';
//     const lid = 'test';

//     const e1: Extent = [2, 2, 4, 4];
//     const t1: Extent = [1, 1, 6, 6];

//     addStream(uri, lid);
//     pushStreamExtent(e1, uri, lid);
//     let n = 0;
//     mapStreamExtent(t1, (_x, _z, e) => {
//         n += 1;
//         logger(`TEST: ${e.join(', ')}`);
//     });
//     // assert(n === 4, 'Sould be 4');
// };

// // test();
