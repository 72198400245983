import * as proj from 'ol/proj';
import { getArea, getDistance, SphereMetricOptions } from 'ol/sphere';

import { Getter, query } from '../shape';
import {
    Interaction,
    withInteraction,
    IGeoMeasure,
    InteractionMeasure,
} from '.';
import tr, { formatNumber } from '../locale';
import { Option, some, none } from 'fp-ts/lib/Option';
import Polygon from 'ol/geom/Polygon';
import { IMapInfo } from '../source/io/map';

const wgs84MeanRadius = 6371008.8;
const wgs84SphereOpt: SphereMetricOptions = {
    projection: 'EPSG:4326',
    radius: 6371008.8,
};

const length = (state: IGeoMeasure) => {
    const coordinates = state.coordinates.map(c =>
        proj.transform(c, 'EPSG:31370', 'EPSG:4326')
    );
    const length = coordinates.reduce((acc, c, idx) => {
        if (idx === 0) {
            return 0;
        }
        const lastPoint = coordinates[idx - 1];
        return acc + getDistance(lastPoint, c, wgs84MeanRadius);
    }, 0);
    return Math.round(length);
};

const getMeasuredLength = (query: Getter<Interaction>) => () =>
    withInteraction<InteractionMeasure>('measure', ({ state }) =>
        length(state)
    )(query());

const area = (state: IGeoMeasure) => {
    const coordinates = state.coordinates.map(c =>
        proj.transform(c, 'EPSG:31370', 'EPSG:4326')
    );
    if (coordinates.length < 3) {
        return 0;
    }
    const poly = new Polygon([coordinates]);
    return Math.round(Math.abs(getArea(poly, wgs84SphereOpt)));
};

const getMeasuredArea = (query: Getter<Interaction>) => () =>
    withInteraction<InteractionMeasure>('measure', ({ state }) => area(state))(
        query()
    );

export const measureQueryFactory = (query: Getter<Interaction>) => ({
    getMeasuredLength: getMeasuredLength(query),
    getMeasuredArea: getMeasuredArea(query),

    getMeasured() {
        let m: Option<string> = none;
        withInteraction<InteractionMeasure>('measure', ({ state }) => {
            switch (state.geometryType) {
                case 'LineString':
                    m = some(
                        `${formatNumber(length(state))}${String.fromCharCode(
                            160
                        )}m`
                    );
                    break;
                case 'Polygon':
                    m = some(
                        `${formatNumber(area(state))}${String.fromCharCode(
                            160
                        )}m²`
                    );
                    break;
            }
        })(query());
        return m;
    },
});

export const mapStatus = (map: IMapInfo) => {
    if (map.status == 'draft') {
        return tr.core('private');
    } else {
        return map.inPublicGroup
            ? tr.core('public_published')
            : tr.core('internally_published');
    }
};

export const getHighlightedBaseLayers = () =>
    query('data/highlighted-baselayers');
