import { DIV, H3 } from 'sdi/components/elements';
import { inputText, options } from 'sdi/components/input';
import tr, { fromRecord, updateRecordRaw } from 'sdi/locale';
import { IMapInfo } from 'sdi/source';

import { setPrintTitle, resetPrintTitle } from '../../events/app';
import { getPrintTitle, hasPrintTitle } from '../../queries/app';
import { makeLabelAndIcon } from '../button';

const btnReset = makeLabelAndIcon('reset', 2, 'sync', () =>
    tr.view('originalTitle')
);

const renderCheckBox = (info: IMapInfo) => {
    if (hasPrintTitle()) {
        return DIV({
            onClick: () => setPrintTitle(getPrintTitle(info)),
        });
    }
    return btnReset(() => resetPrintTitle());
};

const render = (info: IMapInfo) =>
    DIV(
        { className: 'custom-title' },
        H3({}, tr.view('emptyMapTitle')),
        inputText(
            options(
                `print-input-${info.id}`,
                () => fromRecord(getPrintTitle(info)),
                t => setPrintTitle(updateRecordRaw(getPrintTitle(info), t))
            )
        ),
        renderCheckBox(info)
    );

export default render;
