/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as io from 'io-ts';
import { some, fromNullable, none } from 'fp-ts/lib/Option';
import { Task } from 'fp-ts/lib/Task';
import { getCSRF } from '../app';
import { checkURLScheme, Collection } from '../util';

export type ContentType = 'none' | 'application/json';

export const defaultFetchOptions = (
    contentType = 'application/json' as ContentType
): RequestInit => {
    const headers = new Headers();
    switch (contentType) {
        case 'none':
            break;
        case 'application/json':
            headers.append('Content-Type', 'application/json');
    }
    getCSRF().map(csrf => headers.append('X-CSRFToken', csrf));

    return {
        mode: 'cors',
        cache: 'default',
        redirect: 'follow',
        credentials: 'same-origin',
        headers,
    };
};

const stringify = (value: unknown): string => {
    return typeof value === 'function'
        ? io.getFunctionName(value)
        : JSON.stringify(value);
};

const getContextPath = (context: io.Context): string => {
    return context.map(({ key, type }) => `${key}: ${type.name}`).join('/');
};

export const getMessage = (value: unknown, context: io.Context): string => {
    return `Invalid value ${stringify(value)} supplied to ${getContextPath(
        context
    )}`;
};

export const onValidationError =
    <T>(ioType: io.Type<T>, url: string) =>
    (errors: io.ValidationError[]) => {
        const msg = errors.map(e => getMessage(e.value, e.context));
        console.group(`Validation Failed: ${url}(${ioType.name})`);
        msg.forEach(m => console.log(m));
        console.groupEnd();
        throw new Error(`${ioType.name} failed validation`);
    };

const identity = <T>(a: T) => a;

export const fetchWithoutValidationIO = <T>(
    url: string,
    getOptions: RequestInit = {}
) => {
    const options: RequestInit = {
        method: 'GET',
        ...defaultFetchOptions(),
        ...getOptions,
    };

    return fetch(checkURLScheme(url), options).then(response => {
        if (response.ok) {
            return response.json() as Promise<T>;
        }
        throw response;
    });
};

export const fetchIO = <T>(
    ioType: io.Type<T>,
    url: string,
    getOptions: RequestInit = {}
) => {
    const options: RequestInit = {
        method: 'GET',
        ...defaultFetchOptions(),
        ...getOptions,
    };

    return fetch(checkURLScheme(url), options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw response;
        })
        .then(obj =>
            ioType.decode(obj).fold(onValidationError(ioType, url), identity)
        );
};

const makePageType = <T>(ioType: io.Type<T>) =>
    io.interface(
        {
            count: io.number,
            next: io.union([io.string, io.null]),
            previous: io.union([io.string, io.null]),
            results: io.array(ioType),
        },
        `Page<${ioType.name}>`
    );

export interface FetchedPage<T> {
    results: T[];
    page: number;
    total: number;
}

const makePage = <T>(
    results: T[],
    page: number,
    total: number
): FetchedPage<T> => ({
    results,
    page,
    total,
});

class Inc {
    private storedValue = 0;
    step() {
        this.storedValue += 1;
        return this.value();
    }
    value() {
        return this.storedValue;
    }
}

export const fetchPaginatedIO = <T>(
    ioType: io.Type<T>,
    url0: string,
    getOptions?: RequestInit
) => {
    const pagetType = makePageType(ioType);
    let pageSize = 0;
    let nextUrl = some(`${url0}?page=1`);
    const pageCounter = new Inc();

    const fetchPage = () =>
        nextUrl.map(url =>
            fetchIO(pagetType, url, getOptions)
                .then(r => {
                    if (pageCounter.value() === 0) {
                        pageSize = r.results.length;
                    }
                    nextUrl = fromNullable(r.next);
                    const frame = makePage<T>(
                        r.results,
                        pageCounter.value(),
                        pageSize > 0 ? r.count / pageSize : 0
                    );
                    pageCounter.step();

                    return frame;
                })
                .catch(() => {
                    nextUrl = none;
                    return makePage<T>([], -1, -1);
                })
        );

    const loop = (f: (a: FetchedPage<T>) => void, end: () => void) =>
        fetchPage().foldL(end, p =>
            p.then(results => {
                f(results);
                loop(f, end);
            })
        );

    return loop;
};

export const NoContentIO = io.null;

export const postUnrelatedIO = <T, DT>(
    ioType: io.Type<T>,
    url: string,
    data: Partial<DT>,
    postOptions: RequestInit = {}
) => {
    const options: RequestInit = {
        body: JSON.stringify(data),
        method: 'POST',
        ...defaultFetchOptions(),
        ...postOptions,
    };

    return fetch(checkURLScheme(url), options)
        .then(response => {
            if (response.ok) {
                if (response.status === 204) {
                    // no content
                    return null;
                }
                return response.json();
            }
            throw response;
        })
        .then(obj => {
            return ioType
                .decode(obj)
                .fold(onValidationError(ioType, url), identity);
        });
};

export const downloadBlobIO = <T, DT>(
    _ioType: io.Type<T>,
    url: string,
    data: Partial<DT>,
    postOptions: RequestInit = {}
) => {
    const options: RequestInit = {
        body: JSON.stringify(data),
        method: 'POST',
        ...defaultFetchOptions(),
        ...postOptions,
    };

    return fetch(checkURLScheme(url), options).then(response => {
        if (response.ok) {
            const contentDisposition = response.headers.get(
                'Content-Disposition'
            );

            if (contentDisposition) {
                const startIndex = contentDisposition.indexOf('filename=') + 10;
                const endIndex = contentDisposition.length - 1;
                const filename = contentDisposition.substring(
                    startIndex,
                    endIndex
                );

                // Download
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    // The `if` does not make sense as it checks for
                    // something that's not used. And these 2 APIs are
                    // non-standard and obsolete according to MDN.
                    // if (navigator.msSaveOrOpenBlob) {
                    //     navigator.msSaveBlob(blob, filename);
                    // }
                    // else {
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    // }
                    window.URL.revokeObjectURL(url);
                });
            }

            return response;
        }
        throw response;
    });
};

export const postIO = <IOT, T>(
    ioType: io.Type<IOT>,
    url: string,
    data: T,
    postOptions: RequestInit = {}
) => postUnrelatedIO(ioType, url, data, postOptions);

export const putIO = <T, DT>(
    ioType: io.Type<T>,
    url: string,
    data: DT,
    postOptions: RequestInit = {}
) => postIO(ioType, url, data, { ...postOptions, method: 'PUT' });

export const deleteIO = (url: string, getOptions: RequestInit = {}) => {
    const options: RequestInit = {
        method: 'DELETE',
        ...defaultFetchOptions(),
        ...getOptions,
    };

    return fetch(checkURLScheme(url), options).then(response => {
        if (response.ok) {
            return void 0;
        }
        throw new Error(
            `Network response was not ok.\n[${url}]\n${response.statusText}`
        );
    });
};

export const uploadIO = <IOT>(
    ioType: io.Type<IOT>,
    url: string,
    name: string,
    file: File
) => {
    const body = new FormData();
    body.append(name, file, file.name);
    const options: RequestInit = {
        body,
        method: 'POST',
        ...defaultFetchOptions('none'),
    };
    return postIO(ioType, url, {}, options);
};

export const taskFetchIO = <T>(
    ioType: io.Type<T>,
    url: string,
    getOptions: RequestInit = {}
) => new Task(() => fetchIO(ioType, url, getOptions));

/**
 * The following is a literal transcription of gcanti's blog post
 * https://medium.com/@gcanti/slaying-a-ui-antipattern-with-flow-5eed0cfb627b
 */

export interface RemoteNone {
    readonly tag: 'none';
}
export interface RemoteLoading {
    readonly tag: 'loading';
}
export interface RemoteSuccess<D> {
    readonly tag: 'success';
    data: D;
}
export interface RemoteError<E> {
    readonly tag: 'error';
    error: E;
}

export type RemoteResource<D, E = string> =
    | RemoteNone
    | RemoteLoading
    | RemoteSuccess<D>
    | RemoteError<E>;

export const remoteNone: RemoteNone = { tag: 'none' };
export const remoteLoading: RemoteLoading = { tag: 'loading' };
export const remoteSuccess = <D>(data: D): RemoteSuccess<D> => ({
    tag: 'success',
    data,
});
export const remoteError = <E = string>(error: E): RemoteError<E> => ({
    tag: 'error',
    error,
});

export const foldRemote =
    <Data, Error, Return>(
        none: () => Return,
        loading: () => Return,
        error: (err: Error) => Return,
        success: (dat: Data) => Return
    ) =>
    (resource: RemoteResource<Data, Error>): Return => {
        switch (resource.tag) {
            case 'none':
                return none();
            case 'loading':
                return loading();
            case 'error':
                return error(resource.error);
            case 'success':
                return success(resource.data);
        }
    };

export const foldRemoteC =
    <D, E, R>(n: () => R) =>
    (l: () => R) =>
    (e: (err: E) => R) =>
    (s: (dat: D) => R) =>
        foldRemote(n, l, e, s);

export const remoteToOption = <D, E>(rr: RemoteResource<D, E>) => {
    if (rr.tag === 'success') return some(rr.data);
    return none;
};

export const mapRemote = <D, E, R = D>(
    rr: RemoteResource<D, E>,
    f: (inner: D) => R
): RemoteResource<R, E> => {
    switch (rr.tag) {
        case 'success':
            return remoteSuccess(f(rr.data));
        case 'error':
            return remoteError(rr.error);
        case 'loading':
            return remoteLoading;
        case 'none':
            return remoteNone;
    }
};

export const onRemoteNone =
    <D, E, R = unknown>(rr: () => RemoteResource<D, E>) =>
    (f: () => R) => {
        if (rr().tag === 'none') return f();
        return null;
    };

type EncodableLiteral = string | number | boolean;
type Encodable = EncodableLiteral | EncodableLiteral[];

const encodeComponent = (key: string, value: Encodable): string => {
    if (Array.isArray(value)) {
        return value.map(v => `${key}=${encodeURIComponent(v)}`).join('&');
    }
    return `${key}=${encodeURIComponent(value)}`;
};

export const withQueryString = (url: string, attrs: Collection<Encodable>) => {
    const qs = Object.keys(attrs)
        .map(k => encodeComponent(k, attrs[k]))
        .join('&');
    return `${url}?${qs}`;
};

const lo = io.interface({
    logout: io.string,
});

type Lo = io.TypeOf<typeof lo>;
export const logoutUser = (url: string): Promise<Lo> => postIO(lo, url, {});
