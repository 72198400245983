import { Option, none, some } from 'fp-ts/lib/Option';

export type Range = [number, number];
export type Window = [number, number, number];
export interface SparseArray<T> {
    readonly data: T[][];
    readonly windows: Window[];
}

type Overlap = 'CONTAINS' | 'INTERSECTS_LEFT' | 'INTERSECTS_RIGHT' | 'COVERED_BY' | 'DISJOINT';

interface MergeResult<T> {
    delList: number[];
    newData: T[];
    newRange: Range;
}
const overlap =
    (baseWindow: Window, r: Range): Overlap => {
        const [baseLow, baseHigh] = getRange(baseWindow);
        const [rangeLow, rangeHigh] = r;

        if (
            rangeLow <= baseLow
            && rangeHigh >= baseHigh
        ) {
            return 'CONTAINS';
        }
        else if (
            rangeLow <= baseLow
            && rangeHigh >= baseLow - 1 // overlap or contiguous
        ) {
            return 'INTERSECTS_LEFT';
        }
        else if (
            rangeLow <= baseHigh + 1 // overlap or contiguous
            && rangeHigh >= baseHigh
        ) {
            return 'INTERSECTS_RIGHT';
        }
        else if (
            rangeLow >= baseLow
            && rangeHigh <= baseHigh
        ) {
            return 'COVERED_BY';
        }
        else {
            return 'DISJOINT';
        }
    };


const getLow = (w: Window | Range) => w[0];
const getHigh = (w: Window | Range) => w[1];
const getRange = (w: Window) => ([w[0], w[1]]);
const getIndex = (w: Window) => w[2];

// poor man branded type
type LocalIndexBrand = {};
type LocalIndex = 0 | number & { readonly brand: LocalIndexBrand };

const transpose = (w: Window, index: number) => (index - getLow(w)) as LocalIndex;

const slice = <T>(a: T[], begin: LocalIndex, end?: LocalIndex) => a.slice(begin, end);

const prepareMerge = <T>(
    low: number,
    high: number,
    newData: T[],
    { windows, data }: SparseArray<T>,
): MergeResult<T> => {
    const delList = [];
    let newRange: Range = [low, high];
    for (let i = 0; i < windows.length; i += 1) {
        const w = windows[i];
        const oldData = data[getIndex(w)];
        switch (overlap(w, [getLow(newRange), getHigh(newRange)])) {
            case 'CONTAINS':
                delList.push(i);
                break;
            case 'INTERSECTS_LEFT':
                newData = newData.concat(slice(oldData, transpose(w, high)));
                newRange = [getLow(newRange), getHigh(w)];
                delList.push(i);
                break;
            case 'INTERSECTS_RIGHT':
                newData = slice(oldData, 0, transpose(w, low)).concat(newData);
                newRange = [getLow(w), getHigh(newRange)];
                delList.push(i);
                break;
            case 'COVERED_BY':
                const head = slice(oldData, 0, transpose(w, getLow(newRange)));
                const tail = slice(oldData, transpose(w, getHigh(newRange)));
                newData = head.concat(newData, tail);
                newRange = [getLow(w), getHigh(w)];
                delList.push(i);
                break;
            case 'DISJOINT':
                break;
        }
    }
    return { delList: delList.reverse(), newData, newRange };
};

const deleteData = <T>(
    storage: SparseArray<T>,
    delList: number[],
): SparseArray<T> => {
    const data: T[][] = [];
    const windows: Window[] = [];

    for (let i = 0; i < storage.windows.length; i += 1) {
        if (delList.indexOf(i) < 0) {
            const win = storage.windows[i];
            const dataIdx = getIndex(win);
            data.push(storage.data[dataIdx]);
            windows.push([...getRange(win), data.length - 1] as Window);
        }
    }
    return { data, windows };
};


const merge = <T>(
    storage: SparseArray<T>,
    { delList, newData, newRange }: MergeResult<T>,
) => {
    const newStorage = deleteData(storage, delList);
    newStorage.data.push(newData);
    newStorage.windows.push([...newRange, newStorage.data.length - 1] as Window);
    newStorage.windows.sort((a, b) => a[0] - b[0]);
    return newStorage;
};


const dataLen = <T>(
    storage: SparseArray<T>,
) => storage.data.reduce((acc, line) => acc + line.length, 0);



export const sparseArray = <T>() => {


    const pushSparse = (
        storage: SparseArray<T>,
        low: number,
        newData: T[],
    ): SparseArray<T> => {
        const len = newData.length;
        if (len > 0) {
            const high = low + len;
            const size = dataLen(storage)
            const merged = merge(storage, prepareMerge(low, high, newData, storage));
            if (dataLen(merged) < size) {
                throw (new Error('Pushing in sparse array shrinked. Bad'))
            }
            return merged
        }
        return storage;
    };


    const getSparse = (
        { data, windows }: SparseArray<T>,
        offset: number,
        size: number
    ): Option<T[]> => {
        if (size <= 0) {
            return none;
        }
        const cur = offset;
        const high = offset + size - 1;
        for (const win of windows) {
            const [wLow, wHigh] = getRange(win);
            if (
                cur >= wLow
                && cur <= wHigh
                && high <= wHigh
            ) {
                const dataRange = data[getIndex(win)];
                const result = slice(dataRange, transpose(win, cur), transpose(win, high + 1));
                return some(result);
            }
        }

        return none;
    };


    const getRanges = (
        { windows }: SparseArray<T>,
        offset: number,
        size: number,
    ): Range[] => {
        const ranges: Range[] = [];
        if (size <= 0) {
            return ranges;
        }
        let cur = offset;
        const high = offset + size;
        for (const win of windows) {
            const [wLow, wHigh] = getRange(win);
            if (cur < wLow) {
                ranges.push([cur, wLow - cur]);
            }
            if (wHigh >= cur) {
                cur = wHigh;
            }
        }
        if (cur < high) {
            ranges.push([cur, high - cur]);
        }

        return ranges;
    };


    const newSparseArray = (): SparseArray<T> => ({
        data: [],
        windows: [],
    });

    return { pushSparse, getSparse, getRanges, newSparseArray };
};
