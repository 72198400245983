/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import {
    ITableSort,
    TableDataRow,
    TableDataType,
    TableWindow,
    TableQuerySet,
    TableGetter,
    TableSourceGetter,
} from '.';

const logger = debug('sdi:table/stream-queries');

export const tableStreamQueries = (
    getTable: TableGetter,
    getSource: TableSourceGetter
): TableQuerySet => {


    const queries = {
        isLoaded() {
            return getTable().loaded;
        },

        getKeys(): string[] {
            return getSource().keys;
        },

        getFilters() {
            const { search } = getTable();
            return search.filters;
        },

        getTypes(): TableDataType[] {
            return getSource().types;
        },

        getSort(): ITableSort {
            return getTable().sort;
        },

        getData(window?: TableWindow): TableDataRow[] {
            if (window) {
                return getSource().data.slice(
                    window.offset,
                    window.offset + window.size
                );
            }
            else {
                return getSource().data;
            }
        },

        getActiveResult(): number {
            return getTable().search.activeResult;
        },

        getResultCount(): number {
            return getTable().search.resultMap.length;
        },

        rowCount() {
            const rows = queries.getData();
            return rows.length;
        },

        tableWindow() {
            return getTable().window;
        },

        rowHeight() {
            return getTable().rowHeight;
        },

        viewHeight() {
            return getTable().viewHeight;
        },

        position() {
            return getTable().position;
        },

        isSelected(idx: number) {
            return getTable().selected === idx;
        },

        getSelected() {
            return getTable().selected;
        },

        getRow(idx?: number) {
            const selected = idx !== undefined ? idx : queries.getSelected();
            const data = queries.getData();
            if (selected < 0 || selected >= data.length) {
                return null;
            }
            return data[selected];
        },

        getColumnAdjust() {
            return getTable().columnAdjust;
        }
    };

    return queries;
};

logger('loaded');
