
/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */



import * as debug from 'debug';
import { Pair, pair, snd, fst } from '../lib';

const logger = debug('sdi:source/source');

export const isProxySupported = (typeof Proxy === 'function');// && false;


export type KeyOfIShape<IShape> = keyof IShape;
export type SubTypeOfIShape<IShape> = IShape[KeyOfIShape<IShape>];

export interface IReducer<IShape, S extends SubTypeOfIShape<IShape>> {
    (a: S): S;
}

export interface IReducerAsync<IShape, S extends SubTypeOfIShape<IShape>> {
    (a: S): Promise<S>;
}

export interface IObserver<IShape, K extends KeyOfIShape<IShape>> {
    key: K;
    handler(a: Readonly<IShape[K]>): void;
    immediate: boolean;
}

export interface IStoreInteractions<IShape> {
    dispatch<K extends KeyOfIShape<IShape>>(key: K, handler: IReducer<IShape, IShape[K]>): void;
    dispatchAsync<K extends KeyOfIShape<IShape>>(key: K, handler: IReducerAsync<IShape, IShape[K]>): void;
    observe<K extends KeyOfIShape<IShape>>(key: K, handler: (a: Readonly<IShape[K]>) => void, immediate?: boolean): void;
    get<K extends keyof IShape>(key: K): Readonly<IShape[K]>;
    version(): number;
    observeVersion(f: (i: number) => void): void;
    lock(f: () => void): boolean;
    // release(): void;
}

const getLocaleStorage =
    () => {
        try {
            const storage = window.localStorage;
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return storage;
        }
        catch (e) {
            return false;
        }
    };


export const source =
    <IShape>(localKeys: (keyof IShape)[]) => {

        // const isObject = (o: Object): o is object => {
        //     if (o instanceof Object) {
        //         return true;
        //     }
        //     return false;
        // };

        const toLocalStorage =
            (state: IShape) => {
                const storage = getLocaleStorage();
                if (storage) {
                    localKeys.forEach((key) => {
                        storage.setItem(key as string, JSON.stringify(state[key]));
                    });
                }
            };

        const getLocalStorageValue =
            <K extends keyof IShape>(storage: Storage, key: K): IShape[K] | null => {
                const jsonString = storage.getItem(key as string);
                if (jsonString) {
                    return JSON.parse(jsonString);
                }
                return null;
            };

        const importLocalStorage =
            (state: IShape) => {
                const storage = getLocaleStorage();
                if (storage) {
                    localKeys.forEach((key) => {
                        const localState = getLocalStorageValue(storage, key);
                        if (localState) {
                            state[key] = localState;
                        }
                    });
                }
                return state;
            };


        const start =
            (initialState: IShape, withLocalStorage = true): IStoreInteractions<IShape> => {


                const store = initialState;
                let versionNumber = 0;
                let writeLock = 0;

                const versionObservers: ((i: number) => void)[] = [];
                const observers: IObserver<IShape, KeyOfIShape<IShape>>[] = [];
                type KeyHandler<K extends keyof IShape> = Pair<K, IReducer<IShape, IShape[K]>>;
                const pendings: KeyHandler<keyof IShape>[] = [];

                const lock = (f: () => void) => {
                    if (writeLock > 0) {
                        lockViolation(`You're depp-locking the store (${writeLock}), it can't go well`);
                        // return false;
                    }
                    writeLock += 1;

                    f();
                    release();
                    return true;
                };

                const release = () => {
                    if (writeLock > 1) {
                        lockViolation(`You're n(${writeLock})-releasing the store`);
                    }
                    writeLock -= 1;
                    if (pendings.length > 0) {
                        pendings.forEach((kh) => {
                            logger(`Processing pending dispatch: ${fst(kh)}`);
                            dispatch(fst(kh), snd(kh));
                        });
                        pendings.splice(0);
                    }
                };

                const lockViolation = (msg: string) => {
                    // if (
                    //     process
                    //     && process.env
                    //     && process.env.NODE_ENV === 'production'
                    // ) {
                    //     console.warn(msg);
                    // }
                    // else {
                    //     console.error(msg);
                    // }
                    console.error(msg);
                };

                const pushState = (_ns: IShape) => {
                    // store = ns;
                    versionNumber += 1;
                    Promise.resolve(versionNumber)
                        .then((vn) => {
                            // we check if it's worth bothering listeners while there is 
                            // a newer version number in flight
                            if (vn === versionNumber) {
                                versionObservers.forEach(f => f(vn));
                            }
                        });
                };

                // const head = () => store;// store[store.length - 1];

                const get = <K extends keyof IShape>(key: K): Readonly<IShape[K]> => {
                    // const state = head();
                    const value = store[key];
                    return value;
                };


                const observe =
                    <K extends KeyOfIShape<IShape>>(
                        key: K,
                        handler: (a: Readonly<IShape[K]>) => void,
                        immediate = false,
                    ): void => {
                        logger(`observe ${key}`);
                        const alreadyRegistered = observers.find(o => o.handler === handler && o.key === key);
                        if (!alreadyRegistered) {
                            observers.push({ key, handler, immediate });
                        }
                        else {
                            throw `Duplicate Observer: ${key}`;
                        }
                    };

                const processObservers =
                    <K extends KeyOfIShape<IShape>>(a: K) => {
                        observers.filter(o => o.key === a)
                            .forEach((o) => {
                                const state = get(a);
                                if (o.immediate) {
                                    return o.handler(state);
                                }
                                setTimeout(() => {
                                    o.handler(state);
                                }, 1);
                            });
                    };

                const dispatch =
                    <K extends KeyOfIShape<IShape>>(key: K, handler: IReducer<IShape, IShape[K]>): void => {
                        if (writeLock) {
                            pendings.push(pair(key, handler));
                            return;
                        }
                        logger(`[dispatch] ${key}`);
                        store[key] = handler(store[key]);
                        toLocalStorage(store);
                        pushState(store);
                        processObservers(key);

                    };

                const dispatchAsync =
                    <K extends KeyOfIShape<IShape>>(key: K, handler: IReducerAsync<IShape, IShape[K]>): void => {
                        handler(store[key])
                            .then((nk) => {
                                store[key] = nk;
                                toLocalStorage(store);
                                pushState(store);
                                processObservers(key);
                            });
                    };


                if (withLocalStorage) {
                    const ns = importLocalStorage(initialState);
                    pushState(ns);
                }

                const version = () => versionNumber;

                const observeVersion =
                    (f: (i: number) => void) => versionObservers.push(f);



                return {
                    dispatch,
                    dispatchAsync,
                    get,
                    observe,
                    observeVersion,
                    version,
                    lock,
                };
            };


        return start;
    };


