import {
    StreamingField,
    PropertyTypeDescriptor,
    streamFieldName,
} from '../../source';
import { DIV, NodeOrOptional } from '../elements';
import { renderTableHeader, renderTableBody, renderFilters } from './render';
import { rect, __forceRefreshState } from '../../app';
import { Option, none, some, fromEither } from 'fp-ts/lib/Option';
import { TableSort, SortDirection } from './sort';
import { Filter, makeInitialFilter } from './filter';
import * as io from 'io-ts';

export * from './filter';
export * from './sort';

export interface TableWindow {
    offset: number;
    size: number;
}

export const sliceWindow = (w: TableWindow) => (rows: TableDataRow[]) =>
    rows.slice(w.offset, w.offset + w.size + 1);

export interface TableState {
    position: { x: number; y: number };
    rowHeight: number;
    columnAdjust: number[]; // %widths to add to the original widths, one number per column.
    selected: number;
    viewHeight: number;
    window: TableWindow;
    sort: Option<TableSort>;
    filters: Filter[];
}

export const defaultTableState = (): TableState => ({
    position: { x: 0, y: 0 },
    rowHeight: 19,
    columnAdjust: [],
    selected: -1,
    viewHeight: -1,
    window: { offset: 0, size: 100 },
    sort: none,
    filters: [],
});

const TableDataCellBaseIO = io.union([io.string, io.number, io.boolean]);
const TableDataCellIO = io.union([
    TableDataCellBaseIO,
    io.array(TableDataCellBaseIO),
]);
export type TableDataCell = io.TypeOf<typeof TableDataCellIO>;

export const tryTableDataCell = (value: unknown): Option<TableDataCell> =>
    fromEither(TableDataCellIO.decode(value));

export interface TableDataRow {
    // pointer to origin
    from: number | string;
    cells: TableDataCell[];
}

export interface TableData {
    rows: TableDataRow[];
    total: number;
    filteredCount: number;
}

export type CellHighlighterFn = (
    from: number | string,
    column: number
) => boolean;

export interface TableSource {
    data: (w: TableWindow) => TableData;
    fields: StreamingField[];
    highlight?: CellHighlighterFn;
}

export const emptySource = (): TableSource => ({
    data: () => ({ rows: [], total: 0, filteredCount: 0 }),
    fields: [],
});

export type TableDispatch = (r: (state: TableState) => TableState) => void;
export type TableQuery = () => TableState;
export type RowSelectHandler = (row: TableDataRow, i: number) => void;
export type TableFilterChange = () => void;

export const table = (
    dispatch: TableDispatch,
    query: TableQuery,
    rowHandler: RowSelectHandler,
    filterChange = __forceRefreshState as TableFilterChange
) => {
    /// < events
    const setTableWindowOffset = (offset: number) =>
        dispatch(s => ({
            ...s,
            window: { ...s.window, offset: Math.max(0, offset) },
        }));

    const setTableWindowSize = (viewHeight: number): void =>
        dispatch(s => ({
            ...s,
            viewHeight,
            window: {
                ...s.window,
                size: Math.ceil(viewHeight / s.rowHeight),
            },
        }));

    const setPosition = (x: number, y: number): void =>
        dispatch(s => ({ ...s, position: { x, y } }));

    const setSort = (col: number, direction: SortDirection) => {
        dispatch(s => ({ ...s, sort: some({ col, direction }) }));
        filterChange();
    };

    const initColumnAdjust = (nbOfColumns: number) =>
        dispatch(s => ({ ...s, columnAdjust: Array(nbOfColumns).fill(0) }));

    const setColumnAdjust = (columnNb: number, adjust: number) =>
        dispatch(s => {
            s.columnAdjust[columnNb] = s.columnAdjust[columnNb] + adjust;
            return s;
        });

    const rectify = rect(({ height }) => {
        const { rowHeight, viewHeight } = query();
        const diff = Math.abs(viewHeight - height);
        if (diff > rowHeight / 2) {
            setTableWindowSize(Math.ceil(height));
        }
    });

    const clearFilters = () => dispatch(s => ({ ...s, filters: [] }));

    const initFilter = (column: number, dataType: PropertyTypeDescriptor) => {
        dispatch(state => {
            const { filters, window } = state;
            return {
                ...state,
                window: { ...window, offset: 0 },
                filters: filters.concat([makeInitialFilter(column, dataType)]),
            };
        });

        filterChange();
    };

    const setFilter = (filter: Filter, index: number) => {
        dispatch(state => {
            const filters = state.filters.map((f, i) => {
                if (index === i && f.column === filter.column) {
                    return filter;
                }
                return f;
            });

            return { ...state, filters };
        });

        filterChange();
    };
    ///  events >

    /// < ref
    let it: number | null = null;
    let scrolls: number[] = [];
    const mount = () => {
        scrolls = [];
        it = window.setInterval(() => {
            const sl = scrolls.length;
            if (sl > 0) {
                const offsetTop = scrolls[sl - 1];
                const { rowHeight, position } = query();
                const rowOffset = Math.ceil(offsetTop / rowHeight);
                const diff = Math.abs(position.y - offsetTop);
                if (diff >= rowHeight) {
                    setPosition(0, offsetTop);
                    setTableWindowOffset(rowOffset);
                }
            }
            scrolls = [];
        }, 64);
    };
    const unmount = () => {
        if (it !== null) {
            window.clearInterval(it);
        }
    };

    const setTableSize = (el: Element | null) => {
        if (el) {
            rectify(el);
        }
    };

    const scroll = (e: React.UIEvent<Element>): void => {
        scrolls.push(e.currentTarget.scrollTop);
    };

    const lifeCycle = (el: Element | null) => {
        if (el === null) {
            unmount();
        } else {
            mount();
        }
    };
    /// ref >

    const renderBody = renderTableBody(rowHandler);
    const render = (source: TableSource, toolbar: NodeOrOptional) => {
        const state = query();
        const data = source.data(state.window);
        const nbColumns = source.fields.length;
        if (state.columnAdjust.length != nbColumns) {
            initColumnAdjust(nbColumns);
        }

        const filters =
            state.filters.length > 0
                ? some(
                      renderFilters(
                          state.filters,
                          source.fields.map(streamFieldName),
                          setFilter,
                          clearFilters
                      )
                  )
                : none;

        const main = DIV(
            {
                className: 'table-main',
                ref: setTableSize,
                onScroll: scroll,
            },
            renderTableHeader(
                source.fields,
                state,
                setSort,
                setColumnAdjust,
                initFilter
            ),
            renderBody(data, source.fields, state, source.highlight)
        );

        return DIV(
            { className: 'infinite-table', ref: lifeCycle },
            toolbar,
            filters,
            main
        );
    };

    return render;
};
