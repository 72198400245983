import Point from 'ol/geom/Point';
import OLFeature from 'ol/Feature';

import { getContext, StyleFn } from 'sdi/map/style';
import { Feature, ILayerInfo, StyleConfig } from 'sdi/source';
import pointStyle from 'sdi/map/style/point';
import lineStyle from 'sdi/map/style/line';
import polygonStyle from 'sdi/map/style/polygon';
import { DIV, IMG } from 'sdi/components/elements';
import { fromNullable } from 'fp-ts/lib/Option';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import Geometry from 'ol/geom/Geometry';

const sideSize = 32;

const pointGeometry = new Point([sideSize / 2, sideSize / 2]);
const lineGeometry = new LineString([
    [0, sideSize / 2],
    [sideSize, sideSize / 2],
]);
const polygonGeometry = new Polygon([
    [
        [0, 0],
        [sideSize, 0],
        [sideSize, sideSize],
        [0, sideSize],
        [0, 0],
    ],
]);

const makeLegendFeature = (
    feature: Feature | Readonly<Feature>,
    geometry: Geometry
) => {
    const olFeature = new OLFeature(geometry);
    olFeature.setProperties(feature.properties ?? {});
    return olFeature;
};

const item = (geomType: string, dataUrl: string) =>
    DIV(
        { className: `legend-item ${geomType}` },
        DIV({ className: 'item-style' }, IMG({ src: dataUrl, alt: '' }))
    );

const renderBase =
    <C extends StyleConfig>(
        geomType: string,
        geometry: Geometry,
        fn: (c: C) => StyleFn
    ) =>
    (config: C, feature: Feature | Readonly<Feature>) =>
        fromNullable(getContext(sideSize, sideSize)).map(ctx => {
            const { canvas, olContext } = ctx;
            const styleFn = fn(config);
            const styles = styleFn(makeLegendFeature(feature, geometry), 12);
            styles.forEach(style => {
                olContext.setStyle(style);
                olContext.drawGeometry(geometry);
            });
            return item(geomType, canvas.toDataURL());
        });

const renderPoint = renderBase('point', pointGeometry, pointStyle);
const renderPolygon = renderBase('polygon', polygonGeometry, polygonStyle);
const renderLinestring = renderBase('line', lineGeometry, lineStyle);

export const renderLegendItem = (
    info: ILayerInfo,
    feature: Feature | Readonly<Feature>
) => {
    switch (info.style.kind) {
        case 'point-discrete':
        case 'point-simple':
        case 'point-continuous':
            return renderPoint(info.style, feature);
        case 'polygon-continuous':
        case 'polygon-discrete':
        case 'polygon-simple':
            return renderPolygon(info.style, feature);
        case 'line-simple':
        case 'line-discrete':
        case 'line-continuous':
            return renderLinestring(info.style, feature);
    }
};

export default renderLegendItem;
