/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { v4 as uuid } from 'uuid';
import * as debug from 'debug';
import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import RenderFeature from 'ol/render/Feature';

import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import * as interaction from 'ol/interaction';
import Vector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import * as condition from 'ol/events/condition';
import Point from 'ol/geom/Point';
import MultiPoint from 'ol/geom/MultiPoint';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Snap from 'ol/interaction/Snap';
import * as extent from 'ol/extent';
import { fromNullable } from 'fp-ts/lib/Option';
import Geometry from 'ol/geom/Geometry';

import {
    Feature as IOFeature,
    GeometryType,
    DirectGeometryObject,
} from '../../source';

// import { fontSizeExtractRegexp, fontSizeReplaceRegexp } from '../style';

import {
    formatGeoJSON,
    EditOptions,
    Interaction,
    IGeoModify,
    IGeoCreate,
    withInteraction,
    InteractionCreate,
    InteractionModify,
} from '../index';
import { VectorLayer } from '../map';

const logger = debug('sdi:map/edit');

type OlGeom =
    | Point
    | MultiPoint
    | LineString
    | MultiLineString
    | Polygon
    | MultiPolygon;

const isSupportedGeometry = (g: unknown): g is OlGeom =>
    g instanceof Point ||
    g instanceof MultiPoint ||
    g instanceof LineString ||
    g instanceof MultiLineString ||
    g instanceof Polygon ||
    g instanceof MultiPolygon;

const editStyle = (color = '#ffcc33') =>
    new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color,
            width: 2,
        }),
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color,
            }),
        }),
    });

type AddHandlerFn = (g: IOFeature, f: Feature<Geometry>) => void;
interface EditInfra {
    addDraw: (gt: GeometryType) => void;
    removeDraw: () => void;
    addModify: (f: Feature<Geometry>) => void;
    removeModify: () => void;
    setAddHandler: (h: AddHandlerFn) => void;
    drawing: () => boolean;
    modifying: () => boolean;
}

const editInfra = (
    map: Map,
    toolsLayers: Collection<VectorLayer>,
    customStyle?: (feature: RenderFeature) => Style | Style[]
): EditInfra => {
    const features = new Collection<Feature<Geometry>>();
    const modFeatures = new Collection<Feature<Geometry>>();
    let draw: interaction.Draw | null = null;
    let drawing = false;
    let modifying = false;

    const overlay = new Vector({
        source: new SourceVector({ features }),
        style: customStyle ?? editStyle(),
    });

    const oid = uuid();
    overlay.set('id', oid);

    const addDraw = (gt: GeometryType) => {
        logger('addDraw');
        drawing = true;
        draw = new interaction.Draw({
            features,
            type: gt,
        });
        draw.set('id', 'draw');
        if (
            !map
                .getInteractions()
                .getArray()
                .find(i => i.get('id') === 'draw')
        ) {
            map.addInteraction(draw);
        }
        if (!toolsLayers.getArray().find(l => l.get('id') === oid)) {
            toolsLayers.push(overlay);
        }
    };

    const removeDraw = () => {
        if (!drawing) {
            return;
        }
        logger('removeDraw');
        drawing = false;
        const ints = map.getInteractions();
        ints.getArray().forEach(i => {
            if (i.get('id') === 'draw') {
                ints.remove(i);
            }
        });
        draw = null;
        toolsLayers.clear();
        features.clear();
        if (!toolsLayers.getArray().find(l => l.get('id') === moid)) {
            toolsLayers.push(modOverlay);
        }
    };

    const modOverlay = new Vector({
        source: new SourceVector({ features: modFeatures }),
        style: customStyle ?? editStyle('#FF5900'),
    });
    const moid = uuid();
    modOverlay.set('id', moid);

    const modify = new interaction.Modify({
        features: modFeatures,
        deleteCondition: event => {
            return (
                condition.shiftKeyOnly(event) && condition.singleClick(event)
            );
        },
    });
    modify.set('id', 'modify');

    const addModify = (f: Feature<Geometry>) => {
        logger('addModify');
        modifying = true;
        modFeatures.clear();
        modFeatures.push(f);
        if (
            !map
                .getInteractions()
                .getArray()
                .find(i => i.get('id') === 'modify')
        ) {
            map.addInteraction(modify);
        }
        if (!toolsLayers.getArray().find(l => l.get('id') === moid)) {
            toolsLayers.push(modOverlay);
        }
    };

    const removeModify = () => {
        if (!modifying) {
            return;
        }
        logger('removeModify');
        modifying = false;
        const ints = map.getInteractions();
        ints.getArray().forEach(i => {
            if (i.get('id') === 'modify') {
                ints.remove(i);
            }
        });
        toolsLayers.clear();
        modFeatures.clear();
    };

    let addHandler: AddHandlerFn | null = null;
    const onAdd = () =>
        fromNullable(features.pop()).map(f => {
            f.setId(uuid());
            const g: IOFeature = JSON.parse(formatGeoJSON.writeFeature(f));
            if (addHandler) {
                addHandler(g, f);
            }
        });

    features.on('add', onAdd);
    const setAddHandler = (h: AddHandlerFn) => (addHandler = h);

    return {
        addDraw,
        removeDraw,
        addModify,
        removeModify,
        setAddHandler,
        drawing: () => drawing,
        modifying: () => modifying,
    };
};

export const edit = (options: EditOptions) => {
    // FIXME : all of it
    let infra: EditInfra | null = null;
    let curLayerRec: string | null = null;
    let currentFeature: string | number | null = null;
    let mapRef: Map | null = null;
    let mainLayersRef: Collection<VectorLayer> | null = null;
    let snap: ['create' | 'modify', Snap] | null = null;

    const editSource = new SourceVector();
    const editLayer = new Vector({
        source: editSource,
    });

    const init = (
        map: Map,
        toolsLayers: Collection<VectorLayer>,
        mainLayers: Collection<VectorLayer>
    ) => {
        mapRef = map;
        mainLayersRef = mainLayers;
        toolsLayers.push(editLayer);
        infra = editInfra(mapRef, toolsLayers, options.getStyle);
        infra.setAddHandler((g, f) => {
            options.addFeature(g);
            editSource.addFeature(f);
            // toolsLayers.forEach((l) => {
            //     if (l.get('id') === options.getCurrentLayerId()) {
            //     }
            // });
        });
    };

    const centerOn = (f: Feature<Geometry>) => {
        const g = f.getGeometry();
        if (!mapRef || g === undefined) return;
        const e = extent.buffer(g.getExtent(), 32);
        const v = mapRef.getView();
        v.fit(e, {
            size: mapRef.getSize(),
        });
    };

    const updtateSnap = (which: 'create' | 'modify') => {
        /**
         * Be carefull to call that after the Draw interaction has been added to the map !
         */
        const getSnapLayer = options.getSnapLayer;
        if (
            getSnapLayer !== undefined &&
            snap === null &&
            mapRef !== null &&
            mainLayersRef !== null
        ) {
            fromNullable(
                mainLayersRef
                    .getArray()
                    .find(l => l.get('id') === getSnapLayer())
            ).map(layer =>
                fromNullable(layer.getSource()).map(source => {
                    const snapInteraction = new Snap({
                        source,
                        edge: false,
                    });
                    mapRef?.addInteraction(snapInteraction);
                    snap = [which, snapInteraction];
                })
            );
        }
    };

    const removeSnap = (which: 'create' | 'modify') => {
        fromNullable(snap).map(([w, i]) => {
            if (w === which) {
                fromNullable(mapRef).map(m => {
                    m.removeInteraction(i);
                    snap = null;
                });
            }
        });
    };

    const updateModify = (state: IGeoModify) => {
        if (!infra || !mapRef || !mainLayersRef) return;
        const lid = options.getCurrentLayerId();
        const lyr = mainLayersRef.getArray().find(l => l.get('id') === lid);
        infra.removeDraw();
        if (state.selected) {
            if (currentFeature !== state.selected) {
                infra.removeModify();
                currentFeature = null;
            }

            if (!infra.modifying()) {
                currentFeature = state.selected;
                if (currentFeature !== null && lid && lyr) {
                    const innerInfra = infra; // ?? without it TS does complain further of infra being possibly null
                    const cf = currentFeature;
                    fromNullable((<VectorLayer>lyr).getSource())
                        .chain(source =>
                            fromNullable(source.getFeatureById(cf))
                        )
                        .map(sourceFeature => {
                            const cf = new Feature(
                                sourceFeature.clone().getGeometry()
                            );
                            innerInfra.addModify(cf);
                            if (state.centerOnSelected) {
                                centerOn(cf);
                            }
                            fromNullable(cf.getGeometry()).map(geom =>
                                geom.on('change', _a => {
                                    const g = cf.getGeometry();
                                    if (isSupportedGeometry(g)) {
                                        options.setGeometry({
                                            type: g.getType(),
                                            coordinates: g.getCoordinates(),
                                        } as DirectGeometryObject);
                                        sourceFeature.setGeometry(g);
                                    }
                                })
                            );
                        });
                }
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateCreate = (_state: IGeoCreate) => {
        if (!infra || !mapRef) return;

        const lid = options.getCurrentLayerId();
        infra.removeModify();

        if (curLayerRec !== lid) {
            infra.removeDraw();
        }

        if (lid) {
            infra.addDraw(options.getGeometryType(lid));
            curLayerRec = lid;
            updtateSnap('create');
        }
    };

    const update = (i: Interaction) => {
        if (infra === null || mapRef === null) return;
        const { removeModify, removeDraw } = infra;
        withInteraction<InteractionCreate>(
            'create',
            i => updateCreate(i.state),
            () => {
                removeSnap('create');
                removeDraw();
                editSource.clear();
            }
        )(i);
        withInteraction<InteractionModify>(
            'modify',
            i => updateModify(i.state),
            () => {
                removeSnap('modify');
                removeModify();
                editSource.clear();
            }
        )(i);
    };

    return { init, update };
};

// export const addActions =
//     (options: EditOptions, map: Map, layers: Collection<Vector>) => {
//         const updateSelect = addSelection(options, map, localLayersRef);
//         const updateModify = addEdit(options, map);

//         return (
//             () => {
//                 updateSelect();
//                 updateModify();
//             });
//     };

logger('loaded');
