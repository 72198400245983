/**
 *  Copyright (C) 2020 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    Option,
    none,
    fromNullable,
    fromPredicate,
    some,
} from 'fp-ts/lib/Option';
import { Either } from 'fp-ts/lib/Either';
import {
    FeatureCollection,
    Feature,
    FeatureViewOptions,
    FeatureViewConfig,
    foldRemote,
    getFields,
    PropertyTypeDescriptor,
    StreamingField,
    getFeatureProp,
    streamFieldName,
    Inspire,
    getSparse,
    StreamingRequestSort,
    StreamingRequestFilter,
    hashRequestFilter,
    StreamingState,
    getRanges,
    remoteLoading,
    StreamingRequest,
    postUnrelatedIO,
    StreamingDataIO,
    pushSparse,
    remoteSuccess,
    remoteError,
    newSparseArray,
    fetchIO,
    StreamingMetaIO,
    DirectGeometryObject,
} from '../../source';
import {
    TableDataRow,
    emptySource,
    TableSource,
    TableDataCell,
    TableWindow,
    TableData,
    sortRows,
    filterRows,
    sliceWindow,
    TableState,
    TableSort,
    Filter,
    tryTableDataCell,
} from '.';
import { getLang, SyntheticLayerInfo } from '../../app';
import { InteractionExtract } from '../../map';
import {
    isNotNullNorUndefined,
    getLayerPropertiesKeys,
    updateCollection,
} from '../../util';
import { index } from 'fp-ts/lib/Array';

const makeStreamingRequestSort = (
    stream: StreamingState,
    optSort: Option<TableSort>,
    fieldNames: string[]
) =>
    optSort.chain<StreamingRequestSort>(({ col, direction }) => {
        if (stream.meta.tag === 'success') {
            return some({
                column: col,
                columnName: fieldNames[col],
                direction,
            });
        }
        return none;
    });

const makeStreamingRequestFilters = (
    stream: StreamingState,
    tableFilters: Filter[],
    fieldNames: string[]
) => {
    const streamingRequestFilters: StreamingRequestFilter[] = [];

    tableFilters.forEach(filter => {
        if (stream.meta.tag === 'success') {
            const cond = (() => {
                switch (filter.tag) {
                    case 'string':
                        return filter.pattern.trim().length > 0;
                    default:
                        return true;
                }
            })();
            if (cond) {
                streamingRequestFilters.push({
                    ...filter,
                    columnName: fieldNames[filter.column],
                });
            }
        }
    });

    return streamingRequestFilters;
};

const getStreamingUrl = (metadata: Inspire) =>
    isNotNullNorUndefined(metadata.dataStreamUrl)
        ? some(metadata.dataStreamUrl)
        : none;

export const makeTable2Manip = (
    getCurrentLayerInfo: () => Option<SyntheticLayerInfo>,
    getSyntheticLayerInfo: (layerId: string) => Option<SyntheticLayerInfo>,
    getLayerData: (id: string) => Either<string, Option<FeatureCollection>>,
    getGeometryContrainst: () => Option<DirectGeometryObject>,
    getTable: () => Readonly<TableState>,
    getStream: () => Readonly<StreamingState>,
    withExtract: () => Option<InteractionExtract>,
    dispatchStreaming: (handler: (s: StreamingState) => StreamingState) => void,
    updateLayer: (uri: string, features: Feature[]) => void,
    keysFromConfig: boolean
) => {
    // Layer / FeatureCollection

    const getLayer = (): Option<FeatureCollection> =>
        getCurrentLayerInfo().chain(({ metadata }) =>
            getLayerData(metadata.uniqueResourceIdentifier).getOrElse(none)
        );

    const getFeatureData = (numRow: number): Feature | null => {
        return getLayer().fold(null, layer => {
            if (layer && numRow >= 0 && numRow < layer.features.length) {
                return layer.features[numRow];
            }
            return null;
        });
    };

    const getLayerDataFromFeatures = (
        layer: FeatureCollection,
        w: TableWindow
    ): TableData => {
        const keys = getConfigLayerKeys(layer);
        const { filters, sort } = getTable();
        const sorter = sort
            .map(s => sortRows(s.col, s.direction, getLayerFields(layer)))
            .getOrElse((data: TableDataRow[]) => data);
        const filter = filterRows(filters);
        const slicer = sliceWindow(w);
        const features = withExtract().fold(layer.features, ({ state }) =>
            layer.features.filter(
                f => state.findIndex(fe => fe.featureId === f.id) >= 0
            )
        );

        const rawRows = features
            .map<TableDataRow>(feature =>
                fromNullable(feature.properties).foldL(
                    () => ({ from: -1, cells: [] }),
                    props => ({
                        from: feature.id,
                        cells: keys.map(k => {
                            const value = props[k];
                            return tryTableDataCell(value).getOrElse('');
                        }),
                    })
                )
            )
            .filter(r => r.from >= 0);

        const filteredRows = filter(rawRows);
        const sortedRows = sorter(filteredRows);
        const windowedRows = slicer(sortedRows);
        return {
            rows: windowedRows,
            total: features.length,
            filteredCount: filteredRows.length,
        };
    };

    // FeatureViewOptions

    const fromConfig = fromPredicate<FeatureViewOptions>(
        options => options.type === 'config'
    );

    const getTyper = (layer: FeatureCollection) => {
        const fd = getFields(layer)
            .map(fds => {
                const result: { [k: string]: PropertyTypeDescriptor } = {};
                fds.forEach(([f, t]) => (result[f] = t));
                return result;
            })
            .getOrElseL(() => {
                const keys = getConfigLayerKeys(layer);
                return index(0, layer.features)
                    .chain(feature => fromNullable(feature.properties))
                    .map(firstRow => {
                        const result: { [k: string]: PropertyTypeDescriptor } =
                            {};
                        keys.forEach(k => {
                            const val = firstRow[k];

                            switch (typeof val) {
                                case 'string':
                                    result[k] = 'string';
                                    break;
                                case 'number':
                                    result[k] = 'number';
                                    break;
                                case 'boolean':
                                    result[k] = 'boolean';
                                    break;
                                default:
                                    result[k] = 'string';
                            }
                        });
                        return result;
                    })
                    .getOrElse({});
            });

        type FD = typeof fd;

        return <K extends keyof FD>(key: K): PropertyTypeDescriptor => fd[key];
    };

    const getConfig = () =>
        getCurrentLayerInfo()
            .chain(({ info }) => fromNullable(info.featureViewOptions))
            .chain(fromConfig)
            .getOrElse({ type: 'config', rows: [] }) as FeatureViewConfig;

    const getRows = () => {
        const lc = getLang();
        const allRows = getConfig().rows;
        return allRows.filter(r => r.lang === lc);
    };

    const getConfigLayerKeys = (layer: FeatureCollection) => {
        const layerKeys = getLayerPropertiesKeys(layer);
        if (keysFromConfig) {
            const configKeys = getRows();

            if (configKeys.length > 0) {
                return configKeys
                    .map(row => row.propName)
                    .filter(key => layerKeys.indexOf(key) >= 0);
            }
        }
        return layerKeys;
    };

    const getLayerFields = (layer: FeatureCollection) => {
        const typer = getTyper(layer);
        return getConfigLayerKeys(layer).map<StreamingField>(k => [
            k,
            typer(k),
        ]);
    };

    const makeLayerSource = () =>
        getLayer().fold(emptySource(), layer => ({
            data: w => getLayerDataFromFeatures(layer, w),
            fields: getLayerFields(layer),
        }));

    const makeRow = (cells: TableDataCell[], from: number): TableDataRow => ({
        cells,
        from,
    });

    const makeEmptyRow = (size: number) => (id: number) =>
        // tslint:disable-next-line: prefer-array-literal
        makeRow(new Array(Math.abs(size)).fill('-'), id);

    const makeEmptyTable = (width: number, length: number) =>
        // tslint:disable-next-line: prefer-array-literal
        new Array(Math.abs(length))
            .fill(0)
            .map((_, i) => makeEmptyRow(width)(i));

    const hashWindow = (
        w: TableWindow,
        s: Option<StreamingRequestSort>,
        f: StreamingRequestFilter[]
    ) => {
        let hash = `${w.offset}-${w.size}`;

        s.map(s => {
            hash += `-${s.column}/${s.direction}`;
        });
        f.forEach(filter => (hash += `-${hashRequestFilter(filter)}`));

        return hash;
    };

    const getStreamInfo = () => {
        const stream = getStream();
        const table = getTable();
        const fieldNames = getLayer().map(getConfigLayerKeys).getOrElse([]);
        const optSort = makeStreamingRequestSort(
            stream,
            table.sort,
            fieldNames
        );
        const filters = makeStreamingRequestFilters(
            stream,
            table.filters,
            fieldNames
        );
        const hash = hashWindow(table.window, optSort, filters);

        return {
            optSort,
            filters,
            hash,
            window: table.window,
            totalCount: stream.totalCount,
            storage: stream.storage,
            remotes: stream.remotes,
        };
    };

    const makeStreamData = (
        _url: string,
        fields: StreamingField[],
        initialCount: number
    ) => {
        let lastKnownCount = initialCount;

        return (window: TableWindow): TableData => {
            const { storage, totalCount } = getStreamInfo();
            const { offset, size } = window;
            const total = totalCount >= 0 ? totalCount : lastKnownCount;
            const adjustedSize = Math.min(size, total - offset);
            const emptyRows = makeEmptyTable(fields.length, adjustedSize);
            lastKnownCount = total;

            const dataOpt = getSparse(storage, offset, adjustedSize);

            const rows: TableDataRow[] = dataOpt
                .map(data =>
                    data.map<TableDataRow>(feature => ({
                        from: feature.id,
                        cells: fields.map(field =>
                            getFeatureProp(feature, streamFieldName(field), '-')
                        ),
                    }))
                )
                .getOrElse(emptyRows);

            return { rows, total, filteredCount: totalCount };
        };
    };

    // const STREAM_CACHE: { [k: string]: TableSource } = {};

    const makeStreamingSource = (
        url: string,
        featureView: FeatureViewOptions
    ) => {
        // if (url in STREAM_CACHE) { return STREAM_CACHE[url]; }
        return foldRemote(
            emptySource,
            emptySource,
            emptySource,
            ({ fields, count }) => {
                if (
                    featureView.type === 'config' &&
                    featureView.rows.find(r => r.lang === getLang())
                ) {
                    const configFields: StreamingField[] = getLayer()
                        .map(l => getLayerFields(l))
                        .getOrElse([]);
                    // const configFields: StreamingField[] =
                    // fields
                    //     .filter((f: StreamingField) => featureView.rows
                    //         .filter(r => r.lang === getLang())
                    //         .find(r => r.propName === streamFieldName(f)));
                    return {
                        data: makeStreamData(url, configFields, count),
                        fields: configFields,
                    };
                }
                // STREAM_CACHE[url] = source;
                return { data: makeStreamData(url, fields, count), fields };
            }
        )(getStream().meta);
    };

    // const makeLocalSource = subscribe(
    //     'app/current-layer',
    //     makeLayerSource,
    //     'app/current-map',
    //     'data/layers',
    //     'data/maps',
    //     'port/map/interaction',
    //     'app/lang'
    // );

    const getLayerSource = (): TableSource =>
        getCurrentLayerInfo().fold(emptySource(), ({ metadata, info }) => {
            if (metadata.dataStreamUrl) {
                return makeStreamingSource(
                    metadata.dataStreamUrl,
                    info.featureViewOptions
                );
            }
            return makeLayerSource();
        });

    type StreamLoadingStatus = 'no-stream' | 'loading' | 'loaded' | 'error';

    const streamLoadingStatus = (): StreamLoadingStatus =>
        getCurrentLayerInfo()
            .map<StreamLoadingStatus>(({ metadata }) => {
                const { remotes, hash } = getStreamInfo();
                if (metadata.dataStreamUrl && hash in remotes) {
                    return foldRemote<number, string, StreamLoadingStatus>(
                        () => 'no-stream',
                        () => 'loading',
                        () => 'error',
                        () => 'loaded'
                    )(remotes[hash]);
                }
                return 'no-stream';
            })
            .getOrElse('no-stream');

    const layerObserver = (layerId: string | null) => {
        if (layerId === null) {
            return;
        }

        const inner = () => {
            getSyntheticLayerInfo(layerId).foldL(
                () => {
                    window.setTimeout(inner, 60);
                },
                ({ metadata }) => {
                    if (metadata.dataStreamUrl) {
                        initStreamingResource(metadata.dataStreamUrl);
                    }
                }
            );
        };

        inner();
    };

    // TODO - break down this function into manageable pieces - pm
    const tableObserver = ({ window }: TableState) =>
        getCurrentLayerInfo()
            .chain(({ metadata }) =>
                getStreamingUrl(metadata).map(url => ({ url, metadata }))
            )
            .map(({ url, metadata }) => {
                // const observeId = uniqId();
                const { storage, optSort, filters, remotes } = getStreamInfo();
                const missingRanges = getRanges(
                    storage,
                    window.offset,
                    window.size
                );
                const hash = hashWindow(window, optSort, filters);

                if (
                    isNotNullNorUndefined(url) &&
                    window.size > 0 &&
                    missingRanges.length > 0 &&
                    !(hash in remotes)
                ) {
                    dispatchStreaming(ss => ({
                        ...ss,
                        remotes: updateCollection(remotes, hash, remoteLoading),
                    }));

                    const requests = missingRanges.map(([offset, size]) => {
                        const req: StreamingRequest = {
                            offset,
                            limit: size,
                        };

                        optSort.map(sort => (req.sort = sort));
                        getGeometryContrainst().map(
                            geometry => (req.geometry = geometry)
                        );
                        req.filters = filters;
                        return postUnrelatedIO(StreamingDataIO, url, req);
                    });

                    Promise.all(requests)
                        .then(responses => {
                            const streamInfo = getStreamInfo();

                            if (hash === streamInfo.hash) {
                                const oldStorage = streamInfo.storage;
                                let newStorage = oldStorage;

                                let totalCount = -1;
                                for (
                                    let i = 0;
                                    i < missingRanges.length;
                                    i += 1
                                ) {
                                    const [offset /* size */] =
                                        missingRanges[i];
                                    const response = responses[i];
                                    newStorage = pushSparse(
                                        newStorage,
                                        offset,
                                        response.data
                                    );
                                    totalCount = response.totalCount;
                                }

                                dispatchStreaming(ss => ({
                                    ...ss,
                                    storage: newStorage,
                                    totalCount,
                                    remotes: updateCollection(
                                        streamInfo.remotes,
                                        hash,
                                        remoteSuccess(
                                            responses.reduce(
                                                (acc, v) => v.data.length + acc,
                                                0
                                            )
                                        )
                                    ),
                                }));

                                responses.map(({ data }) =>
                                    updateLayer(
                                        metadata.uniqueResourceIdentifier,
                                        data
                                    )
                                );
                            }
                        })
                        .catch(err => {
                            dispatchStreaming(ss => ({
                                ...ss,
                                remotes: updateCollection(
                                    getStreamInfo().remotes,
                                    hash,
                                    remoteError(`${err}`)
                                ),
                            }));
                        });
                }
            });

    // It might have been of some use, but it breaks easily
    // const computeTotalCount = (ss: StreamingState) => {
    //     return foldRemote<StreamingMeta, string, number>(
    //         () => ss.totalCount,
    //         () => ss.totalCount,
    //         () => ss.totalCount,
    //         ({ count }) => count
    //     )(ss.meta);
    // };

    const clearStreamData = () =>
        dispatchStreaming(ss => ({
            ...ss,
            storage: newSparseArray(),
            totalCount: 0,
            // totalCount: computeTotalCount(ss),
            remotes: {},
        }));

    const clearCurrentStreamData = () =>
        getCurrentLayerInfo()
            .chain(({ metadata }) => getStreamingUrl(metadata))
            .map(clearStreamData);

    const initStreamingResource = (url: string) => {
        const streams = getStream();
        if (streams.url === url) {
            return;
        }

        dispatchStreaming(() => ({
            url,
            totalCount: -1,
            meta: remoteLoading,
            storage: newSparseArray(),
            remotes: {},
        }));

        fetchIO(StreamingMetaIO, url)
            .then(data =>
                dispatchStreaming(() => ({
                    url,
                    totalCount: data.count,
                    meta: remoteSuccess(data),
                    storage: newSparseArray(),
                    remotes: {},
                }))
            )
            .catch(err =>
                dispatchStreaming(() => ({
                    url,
                    totalCount: -1,
                    meta: remoteError(err.toString()),
                    storage: newSparseArray(),
                    remotes: {},
                }))
            );
    };

    return {
        // queries
        getFeatureData,
        getLayerSource,
        streamLoadingStatus,
        // events
        layerObserver,
        tableObserver,
        clearCurrentStreamData,
    };
};
