
import { Option, fromNullable } from 'fp-ts/lib/Option';


export type Case<R> = [() => boolean, R];

export function cond<R>(...cases: Case<R>[]): Option<R> {
    return fromNullable(cases.find(([predicate, _]) => predicate())).map(([_, consequent]) => consequent);
}

export type CaseL<R> = [() => boolean, () => R];

export function condL<R>(...cases: CaseL<R>[]): Option<R> {
    return fromNullable(cases.find(([predicate, _]) => predicate())).map(([_, consequent]) => consequent());
}


export type CaseOn<V, R> = [(v: V) => boolean, R];

export function condOn<V, R>(value: V, ...cases: CaseOn<V, R>[]): Option<R> {
    return fromNullable(cases.find(([predicate, _]) => predicate(value))).map(([_, consequent]) => consequent);
}
