import buttonFactory from 'sdi/components/button';
import tr from 'sdi/locale';
import { queryK, dispatchK } from 'sdi/shape';

export const { makeIcon, makeLabel, makeLabelAndIcon } = buttonFactory(
    queryK('component/button'),
    dispatchK('component/button')
);

export const renderSwitchToTableButton = makeLabelAndIcon(
    'switch',
    2,
    'list',
    () => tr.view('buttonTable')
);
export const renderSwitchToTileButton = makeLabelAndIcon(
    'switch',
    2,
    'list',
    () => tr.view('buttonTile')
);
