/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';

// import { fontSizeExtractRegexp, fontSizeReplaceRegexp } from '../style';
import { withInteraction, SingleSelectOptions, InteractionEnter } from '..';
import { tryString } from '../../util';
import RenderFeature from 'ol/render/Feature';
import Geometry from 'ol/geom/Geometry';
import { VectorLayer } from '../map';

const logger = debug('sdi:map/enter');

// const selectionStyle =
//     (f: Feature | render.Feature, _res: number) => {
//         const geometryType = f.getGeometry().getType();
//         if (geometryType === 'Point' || geometryType === 'MultiPoint') {
//             return [
//                 new olStyle.Style({
//                     image: new olStyle.Circle({
//                         radius: 12,
//                         fill: new olStyle.Fill({
//                             color: '#3FB2FF',
//                         }),
//                         stroke: new olStyle.Stroke({
//                             width: 2,
//                             color: 'white',
//                         }),
//                     }),
//                 }),
//             ];
//         }
//         else if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
//             return [
//                 new olStyle.Style({
//                     stroke: new olStyle.Stroke({
//                         width: 4,
//                         color: 'white',
//                     }),
//                 }),
//                 new olStyle.Style({
//                     stroke: new olStyle.Stroke({
//                         width: 2,
//                         color: '#3FB2FF',
//                     }),
//                 }),
//             ];
//         }

//         return [new olStyle.Style({
//             fill: new olStyle.Fill({
//                 color: '#3FB2FF',
//             }),
//             stroke: new olStyle.Stroke({
//                 width: 2,
//                 color: 'white',
//             }),
//         })];
//     };

const EnterInteraction = (
    { getSelected, selectFeature, clearSelection, filter }: SingleSelectOptions,
    selectLayer: Collection<Feature<Geometry> | RenderFeature>
) => {
    let active = false;
    const filterFn = filter ?? (() => true);

    const init = (map: Map) => {
        map.on('pointermove', event => {
            if (active) {
                let gotOne = false;
                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    if (filterFn(feature, layer)) {
                        gotOne = true;
                        tryString(layer.get('id')).map(lid => {
                            const { featureId, layerId } = getSelected();
                            const fid = feature.get('id') as string | number;
                            if (lid !== layerId || fid !== featureId) {
                                selectLayer.clear();
                                selectLayer.push(feature);
                                selectFeature(lid, fid);
                            }
                        });
                        return true;
                    }
                    return false;
                });
                if (!gotOne && selectLayer.getLength() > 0) {
                    clearSelection();
                }
            }
        });

        active = true;
    };

    const setActive = (a: boolean) => {
        if (!a) {
            selectLayer.clear();
        }
        active = a;
    };

    return {
        init,
        setActive,
    };
};

const initSingle = (
    options: SingleSelectOptions,
    selectedFeature: Collection<Feature<Geometry> | RenderFeature>
) => {
    const enterInteraction = EnterInteraction(options, selectedFeature);

    const syncSelection = () => {
        const s = options.getSelected();
        if (s.featureId === null && selectedFeature.getLength() > 0) {
            selectedFeature.clear();
        }
    };

    return { enterInteraction, syncSelection };
};

export const enter = (
    options: SingleSelectOptions,
    _layers: Collection<VectorLayer>
) => {
    const selectedFeature = new Collection<Feature<Geometry> | RenderFeature>();

    const { enterInteraction, syncSelection } = initSingle(
        options,
        selectedFeature
    );

    const init = (map: Map) => {
        enterInteraction.init(map);
    };

    const update = withInteraction<InteractionEnter>(
        'enter',
        () => {
            syncSelection();
            enterInteraction.setActive(true);
        },
        () => {
            syncSelection();
            enterInteraction.setActive(false);
        }
    );

    return { init, update };
};

logger('loaded');
