import { INPUT, SPAN, DIV, LABEL } from '../elements';
import {
    Filter,
    FilterString,
    FilterNumber,
    filterString,
    filterNumber,
    FilterDate,
    FilterOp,
    filterDate,
    FilterDateTime,
    filterDateTime,
} from '.';
import { buttonTooltipTop } from '../tooltip';
import { date8601, datetime8601, parseDate } from '../../util';
import tr from '../../locale';
import { getAlias } from '../../app/queries';

type FilterDataFn = (filter: Filter, index: number) => void;

export const renderFilter = (
    filter: Filter,
    idx: number,
    getKeys: () => string[],
    filterData: FilterDataFn
) => {
    switch (filter.tag) {
        case 'string':
            return renderStringFilter(filter, idx, getKeys, filterData);
        case 'number':
            return renderNumberFilter(filter, idx, getKeys, filterData);
        case 'date':
            return renderDateFilter(filter, idx, getKeys, filterData);
        case 'datetime':
            return renderDateTimeFilter(filter, idx, getKeys, filterData);
    }
};

const renderStringFilter = (
    filter: FilterString,
    idx: number,
    getKeys: () => string[],
    filterData: FilterDataFn
) => {
    const { column, pattern } = filter;
    const colName = getKeys()[column];

    const fieldName = LABEL(
        {
            className: 'search-field',
            htmlFor: `filter-string-${idx}`,
        },
        getAlias(colName)
    );

    const searchField = INPUT({
        key: `filter-string-${idx}`,
        id: `filter-string-${idx}`,
        autoFocus: true,
        type: 'search',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: pattern,
        onChange: e =>
            filterData(filterString(column, e.currentTarget.value), idx),
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        fieldName,
        searchField
    );
};

const opString = (op: FilterOp) => {
    switch (op) {
        case 'eq':
            return '=';
        case 'gt':
            return '≥';
        case 'lt':
            return '≤';
    }
};

const opFieldName = (op: FilterOp, colName: string, idx: number) =>
    LABEL(
        {
            className: 'search-field',
            htmlFor: `filter-${idx}`,
        },
        `${getAlias(colName)} ${opString(op)}`
    );

const renderOp = (
    op: FilterOp,
    filter: FilterNumber | FilterDate | FilterDateTime,
    idx: number,
    filterData: FilterDataFn
) =>
    op === filter.op
        ? DIV(
              {
                  className: `picto filter-op filter-op--${op} selected`,
              },
              opString(op)
          )
        : DIV(
              {
                  className: `picto filter-op filter-op--${op} interactive`,
                  onClick: () =>
                      filterData(Object.assign({}, filter, { op }), idx),
              },
              opString(op)
          );

const renderOps = (
    filter: FilterNumber | FilterDate | FilterDateTime,
    idx: number,
    filterData: FilterDataFn
) =>
    DIV(
        { className: 'filter-op__wrapper' },
        SPAN({ className: 'filter-op__label' }, `${tr.core('operator')} : `),
        buttonTooltipTop(
            tr.core('equal'),
            {},
            renderOp('eq', filter, idx, filterData)
        ),
        buttonTooltipTop(
            tr.core('greaterThanOrEqual'),
            {},
            renderOp('gt', filter, idx, filterData)
        ),
        buttonTooltipTop(
            tr.core('lessThanOrEqual'),
            {},
            renderOp('lt', filter, idx, filterData)
        )
    );

const renderNumberFilter = (
    filter: FilterNumber,
    idx: number,
    getKeys: () => string[],
    filterData: FilterDataFn
) => {
    const { column, value, op } = filter;
    const colName = getKeys()[column];

    const searchField = INPUT({
        key: `filter-number-${idx}`,
        id: `filter-${idx}`,
        autoFocus: true,
        type: 'number',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: value.toString(10),
        onChange: e =>
            filterData(
                filterNumber(column, parseFloat(e.currentTarget.value), op),
                idx
            ),
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        opFieldName(op, colName, idx),
        searchField,
        renderOps(filter, idx, filterData)
    );
};

const renderDateFilter = (
    filter: FilterDate,
    idx: number,
    getKeys: () => string[],
    filterData: FilterDataFn
) => {
    const { column, date, op } = filter;
    const colName = getKeys()[column];

    const searchField = INPUT({
        key: `filter-date-${idx}`,
        id: `filter-${idx}`,
        autoFocus: true,
        type: 'date',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: date8601(parseDate(date).getOrElse(new Date())),
        onChange: e =>
            filterData(filterDate(column, e.currentTarget.value, op), idx),
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        opFieldName(op, colName, idx),
        searchField,
        renderOps(filter, idx, filterData)
    );
};

const renderDateTimeFilter = (
    filter: FilterDateTime,
    idx: number,
    getKeys: () => string[],
    filterData: FilterDataFn
) => {
    const { column, datetime, op } = filter;
    const colName = getKeys()[column];

    const searchField = INPUT({
        key: `filter-time-${idx}`,
        id: `filter-${idx}`,
        autoFocus: true,
        type: 'datetime-local',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: datetime8601(parseDate(datetime).getOrElse(new Date())),
        onChange: e =>
            filterData(filterDateTime(column, e.currentTarget.value, op), idx),
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        opFieldName(op, colName, idx),
        searchField,
        renderOps(filter, idx, filterData)
    );
};
