import * as debug from 'debug';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { ILayerInfo, getMessageRecord, getFeatureTitle } from 'sdi/source';
import {
    DETAILS,
    DIV,
    H1,
    H2,
    H4,
    H5,
    NodeOrOptional,
    SPAN,
    SUMMARY,
} from 'sdi/components/elements';
import tr, { fromRecord } from 'sdi/locale';
import { renderConfig } from 'sdi/components/feature-view';
import timeserie from 'sdi/components/timeserie';
import { FeaturePathInstance } from 'sdi/map';

import {
    getCurrentLayer,
    getDatasetMetadataOption,
    getMapInfoOption,
    getSelectedFeatures,
    pathsSortedByLayers,
} from 'view/src/queries/app';
import { dispatchTimeserie, loadData } from 'view/src/events/timeserie';
import { getData, queryTimeserie } from 'view/src/queries/timeserie';
import { zoomToFeature } from 'view/src/events/map';
import { makeIcon } from '../button';
import { renderLegendItem } from './legend';
import { markdown } from 'sdi/ports/marked';
import { deselectFeature } from 'view/src/events/app';

const logger = debug('view');

const zoomBtn = makeIcon('zoomOnFeature', 3, 'search', {
    text: () => tr.view('zoomOnFeature'),
    position: 'left',
});

const tsPlotter = timeserie(
    queryTimeserie,
    getData,
    getCurrentLayer,
    dispatchTimeserie,
    loadData
);

const mapHeader = () =>
    DIV(
        'sidebar-header feature-header',
        H1(
            {},
            getMapInfoOption().fold(
                '',
                mapInfo => fromRecord(mapInfo.title) as string
            )
        )
    );

const deselectBtn = makeIcon('close', 3, 'times', {
    text: () => tr.core('unselect'),
    position: 'left',
});

const selectionHeader = (info: ILayerInfo) =>
    getDatasetMetadataOption(info.metadataId).map(md =>
        H4(
            'kv layer-name',
            SPAN('value', fromRecord(getMessageRecord(md.resourceTitle)))
        )
    );

const selectionDetails = (
    summary: NodeOrOptional,
    details: NodeOrOptional,
    open = false
) =>
    DETAILS(
        {
            className: 'collapsible-wrapper',
            open,
        },
        SUMMARY('collapsible-wrapper__summary', summary),
        details
    );

const renderSummary = ({ feature, layer }: FeaturePathInstance) =>
    DIV(
        'feature-summary',
        renderLegendItem(layer, feature),
        getFeatureTitle(feature, layer.featureViewOptions)
            .map(title => H5('feature-title', title))
            .getOrElse(H5('feature-title', feature.id)),
        DIV(
            'actions',
            deselectBtn(() => deselectFeature(layer.id, feature.id)),
            zoomBtn(() => zoomToFeature(feature))
        )
    );

const renderFeatureInfoGroup = (
    key: string,
    paths: FeaturePathInstance[],
    groupIndex: number
) =>
    DIV(
        { className: 'group', key },
        fromNullable(paths.find(({ layer }) => layer.id === key)).map(
            ({ layer }) => selectionHeader(layer)
        ),
        ...paths.map(({ feature, layer }, pathIndex) =>
            selectionDetails(
                renderSummary({ feature, layer }),
                DIV(
                    'feature-info-wrapper',
                    renderConfig(layer.featureViewOptions, feature, tsPlotter)
                ),
                groupIndex === 0 && pathIndex === 0
            )
        )
    );

const withMoreThanOne = fromPredicate<unknown[]>(xs => xs.length > 1);

const renderFeatureInfoMulti = (paths: FeaturePathInstance[]) =>
    DIV(
        '',
        H2(
            '',
            SPAN('', tr.view('selectedTitle')),
            withMoreThanOne(paths).map(({ length }) =>
                SPAN('', ` : ${length} ${tr.view('selectedAmount')}`)
            )
        ),
        DIV('tip-desktop', markdown(tr.view('multiSelectionHelptext'))),
        pathsSortedByLayers(paths).map(([key, paths], index) =>
            renderFeatureInfoGroup(key, paths, index)
        )
    );

const render = () =>
    // DIV(
    // 'feature-list',
    DIV(
        'sidebar__wrapper sidebar-right feature-info-page',
        mapHeader(),
        getSelectedFeatures().map(renderFeatureInfoMulti)
    );
// );
export default render;

logger('loaded');
