import { TableDataRow } from '.';
import { INPUT, SPAN, DIV, LABEL } from '../elements';
import { date8601, parseDate, datetime8601, tryNumber } from '../../util';
import tr, { fromRecord } from '../../locale';
import { buttonTooltipTop, tooltipConfig } from '../tooltip';
import { PropertyTypeDescriptor } from '../../source';
import { renderSelectFilter } from '../input';
import { setoidNumber } from 'fp-ts/lib/Setoid';
import { findTerm, getAlias, getTermList } from '../../app';
import { fromNullable, fromPredicate, some } from 'fp-ts/lib/Option';
import { isENTER } from '../keycodes';
import { makeIcon } from '../button';

export type FilterOp = 'eq' | 'gt' | 'lt';

export interface FilterString {
    readonly tag: 'string';
    column: number;
    pattern: string;
}

export const filterString = (
    column: number,
    pattern: string
): FilterString => ({ tag: 'string', column, pattern });

export interface FilterNumber {
    readonly tag: 'number';
    column: number;
    value: number;
    op: FilterOp;
}

export const filterNumber = (
    column: number,
    value: number,
    op: FilterOp
): FilterNumber => ({
    tag: 'number',
    column,
    value,
    op,
});

export interface FilterDate {
    readonly tag: 'date';
    column: number;
    date: string;
    op: FilterOp;
}

export const filterDate = (
    column: number,
    date: string,
    op: FilterOp
): FilterDate => ({
    tag: 'date',
    column,
    date,
    op,
});

export interface FilterDateTime {
    readonly tag: 'datetime';
    column: number;
    datetime: string;
    op: FilterOp;
}

export const filterDateTime = (
    column: number,
    datetime: string,
    op: FilterOp
): FilterDateTime => ({
    tag: 'datetime',
    column,
    datetime,
    op,
});

export interface FilterTerm {
    readonly tag: 'term';
    column: number;
    term: number;
}

export const filterTerm = (column: number, term: number): FilterTerm => ({
    tag: 'term',
    column,
    term,
});

export type Filter =
    | FilterDate
    | FilterNumber
    | FilterString
    | FilterDateTime
    | FilterTerm;

export const makeInitialFilter = (
    column: number,
    dataType: PropertyTypeDescriptor
) => {
    switch (dataType) {
        case 'number':
            return filterNumber(column, 0, 'gt');
        case 'date':
            return filterDate(column, date8601(new Date(0)), 'gt');
        case 'datetime':
            return filterDateTime(column, datetime8601(new Date(0)), 'gt');
        case 'term':
            return filterTerm(column, -1);
        default:
            return filterString(column, '');
    }
};

const condArray =
    <T>(f: (a: T) => boolean) =>
    (a: T | T[]) => {
        if (Array.isArray(a)) {
            return a.findIndex(f) >= 0;
        } else {
            return f(a);
        }
    };

const makeStringFilter = (f: FilterString) => {
    const pat = new RegExp(`.*${f.pattern}.*`, 'i');
    const col = f.column;
    return ({ cells }: TableDataRow) => pat.test(cells[col] as string);
};

const makeNumberFilter = (f: FilterNumber) => {
    const b = f.value;
    const op =
        f.op === 'eq'
            ? (a: number) => a === b
            : f.op === 'gt'
            ? (a: number) => a >= b
            : (a: number) => a <= b;
    const cond = (cell: number) => op(cell);
    const col = f.column;
    return ({ cells }: TableDataRow) => cond(cells[col] as number);
};

const makeDateFilter = (f: FilterDate) => {
    const b = Date.parse(f.date);
    const op =
        f.op === 'eq'
            ? (a: number) => a === b
            : f.op === 'gt'
            ? (a: number) => a >= b
            : (a: number) => a <= b;
    const cond = (cell: string) => op(Date.parse(cell));
    const col = f.column;

    return ({ cells }: TableDataRow) => cond(cells[col] as string);
};

const makeDateTimeFilter = (f: FilterDateTime) => {
    const b = Date.parse(f.datetime);
    const op =
        f.op === 'eq'
            ? (a: number) => a === b
            : f.op === 'gt'
            ? (a: number) => a >= b
            : (a: number) => a <= b;
    const cond = (cell: number) => op(cell);
    const col = f.column;

    return ({ cells }: TableDataRow) => {
        const n = cells[col];
        return tryNumber(n)
            .map(cond)
            .getOrElse(cond(Date.parse(n as string)));
    };
};

const makeTermFilter = ({ column, term }: FilterTerm) => {
    const cond = condArray(
        term < 0 ? () => true : (cell: number) => cell === term
    );
    const col = column;
    return ({ cells }: TableDataRow) => cond(cells[col] as number);
};

const makeFilterFunction = (fs: Filter[]) => {
    const filters = fs.map(f => {
        switch (f.tag) {
            case 'string':
                return makeStringFilter(f);
            case 'number':
                return makeNumberFilter(f);
            case 'date':
                return makeDateFilter(f);
            case 'datetime':
                return makeDateTimeFilter(f);
            case 'term':
                return makeTermFilter(f);
        }
    });

    return (row: TableDataRow) =>
        filters.reduce((acc, f) => (acc === false ? acc : f(row)), true);
};

export const filterRows = (filters: Filter[]) => (data: TableDataRow[]) =>
    data.filter(makeFilterFunction(filters));

/// < render

export type SetFilterFn = (filter: Filter, index: number) => void;

export const renderFilter = (
    filter: Filter,
    idx: number,
    keys: string[],
    filterData: SetFilterFn
) => {
    switch (filter.tag) {
        case 'string':
            return renderStringFilter(filter, idx, keys, filterData);
        case 'number':
            return renderNumberFilter(filter, idx, keys, filterData);
        case 'date':
            return renderDateFilter(filter, idx, keys, filterData);
        case 'datetime':
            return renderDateTimeFilter(filter, idx, keys, filterData);
        case 'term':
            return renderTermFilter(filter, idx, keys, filterData);
    }
};

const renderStringFilter = (
    filter: FilterString,
    idx: number,
    keys: string[],
    filterData: SetFilterFn
) => {
    const { column, pattern } = filter;
    const colName = keys[column];

    const fieldName = LABEL(
        {
            className: 'search-field',
            htmlFor: `filter-string-${idx}`,
        },
        getAlias(colName)
    );

    const searchField = INPUT({
        key: `filter-string-${idx}`,
        id: `filter-string-${idx}`,
        autoFocus: true,
        type: 'search',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: pattern,
        onChange: e =>
            filterData(filterString(column, e.currentTarget.value), idx),
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        fieldName,
        searchField
    );
};

const renderTermFilter = (
    filter: FilterTerm,
    idx: number,
    keys: string[],
    filterData: SetFilterFn
) => {
    const { column, term } = filter;
    const colName = keys[column];

    const fieldName = SPAN({ className: 'search-field' }, getAlias(colName));

    const toString = (tid: number) =>
        findTerm(tid)
            .map<string>(t => fromRecord(t.name))
            .getOrElse(tr.core('selectTerm'));

    const termSelectFilter = renderSelectFilter<number>(
        setoidNumber,
        tid => DIV({}, toString(tid)),
        tid => filterData(filterTerm(column, tid), idx),
        toString
    );

    const terms = getTermList().map(t => t.id);
    const selected = fromPredicate<number>(id => id >= 0);

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        fieldName,
        termSelectFilter(terms, selected(term).alt(some(-1)))
    );
};

const opString = (op: FilterOp) => {
    switch (op) {
        case 'eq':
            return '=';
        case 'gt':
            return '≥';
        case 'lt':
            return '≤';
    }
};

const opLabel = (op: FilterOp) => {
    switch (op) {
        case 'eq':
            return tr.core('equal');
        case 'gt':
            return tr.core('greaterThanOrEqual');
        case 'lt':
            return tr.core('lessThanOrEqual');
    }
};

const opFieldName = (op: FilterOp, colName: string, idx: number) =>
    LABEL(
        {
            className: 'search-field',
            htmlFor: `filter-${idx}`,
        },
        `${getAlias(colName)} `,
        SPAN({ 'aria-label': opLabel(op) }, `${opString(op)}`)
    );

const renderOp = (
    op: FilterOp,
    filter: FilterNumber | FilterDate | FilterDateTime,
    idx: number,
    filterData: SetFilterFn
) =>
    op === filter.op
        ? DIV(
              {
                  className: `picto filter-op filter-op--${op} selected`,
              },
              opString(op)
          )
        : DIV(
              {
                  className: `picto filter-op filter-op--${op} interactive`,
                  onClick: () =>
                      filterData(Object.assign({}, filter, { op }), idx),
              },
              opString(op)
          );

const renderOps = (
    filter: FilterNumber | FilterDate | FilterDateTime,
    idx: number,
    filterData: SetFilterFn
) =>
    DIV(
        { className: 'filter-op__wrapper' },
        SPAN({ className: 'filter-op__label' }, `${tr.core('operator')} : `),
        buttonTooltipTop(
            tr.core('equal'),
            {},
            renderOp('eq', filter, idx, filterData)
        ),
        buttonTooltipTop(
            tr.core('greaterThanOrEqual'),
            {},
            renderOp('gt', filter, idx, filterData)
        ),
        buttonTooltipTop(
            tr.core('lessThanOrEqual'),
            {},
            renderOp('lt', filter, idx, filterData)
        )
    );

const renderNumberFilter = (
    filter: FilterNumber,
    idx: number,
    keys: string[],
    filterData: SetFilterFn
) => {
    const { column, value, op } = filter;
    const colName = keys[column];

    const searchField = INPUT({
        key: `filter-number-${idx}`,
        id: `filter-${idx}`,
        autoFocus: true,
        type: 'number',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: value.toString(10),
        onChange: e =>
            filterData(
                filterNumber(column, parseFloat(e.currentTarget.value), op),
                idx
            ),
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        opFieldName(op, colName, idx),
        searchField,
        renderOps(filter, idx, filterData)
    );
};

const buttonOk = makeIcon(
    'validate',
    3,
    'check',
    tooltipConfig(() => tr.core('validate'))
);

const renderDateFilter = (
    filter: FilterDate,
    idx: number,
    keys: string[],
    filterData: SetFilterFn
) => {
    const { column, date, op } = filter;
    const colName = keys[column];
    let currentValue: string | null = null;
    const searchField = INPUT({
        key: `filter-date-${idx}`,
        id: `filter-${idx}`,
        autoFocus: true,
        type: 'date',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: date8601(parseDate(date).getOrElse(new Date())),
        onChange: e => {
            currentValue = e.currentTarget.value;
        },
        onKeyDown: e => {
            if (isENTER(e)) {
                filterData(filterDate(column, e.currentTarget.value, op), idx);
            }
        },
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        opFieldName(op, colName, idx),
        searchField,
        buttonOk(() =>
            fromNullable<string>(currentValue).map(v =>
                filterData(filterDate(column, v, op), idx)
            )
        ),
        renderOps(filter, idx, filterData)
    );
};

const renderDateTimeFilter = (
    filter: FilterDateTime,
    idx: number,
    keys: string[],
    filterData: SetFilterFn
) => {
    const { column, datetime, op } = filter;
    const colName = keys[column];
    let currentValue: string | null = null;

    const searchField = INPUT({
        key: `filter-time-${idx}`,
        id: `filter-${idx}`,
        autoFocus: true,
        type: 'datetime-local',
        name: 'search',
        className: 'table-header-search-field',
        defaultValue: datetime8601(parseDate(datetime).getOrElse(new Date())),
        onChange: e => {
            currentValue = e.currentTarget.value;
        },
        onKeyDown: e => {
            if (isENTER(e)) {
                filterData(filterDate(column, e.currentTarget.value, op), idx);
            }
        },
    });

    return DIV(
        {
            className: `table-search-item`,
            // key: uniqId(),
        },
        opFieldName(op, colName, idx),
        searchField,
        buttonOk(() =>
            fromNullable<string>(currentValue).map(v =>
                filterData(filterDate(column, v, op), idx)
            )
        ),
        renderOps(filter, idx, filterData)
    );
};

/// render >
