/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import {
    Option,
    none,
    fromNullable,
    some,
    fromPredicate,
} from 'fp-ts/lib/Option';

import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';

import {
    ILayerInfo,
    LayerGroup,
    Inspire,
    isSimple,
    getMessageRecord,
    isContinuous,
    isDiscrete,
    DiscreteGroup,
    ContinuousInterval,
} from '../../source';
import tr, { fromRecord } from '../../locale';
import { DIV, NodeOrOptional } from '../elements';

import legendPoint from './legend-point';
import legendLinestring from './legend-linestring';
import legendPolygon from './legend-polygon';
import { Getter } from '../../shape';
import { IMapViewData } from '../../map';
import { scopeOption } from '../../lib';
import { makeIcon } from '../button';
import { StyleFn } from '../../map/style';
import {
    layerOpacitySelector,
    renderInputAlphaColor,
    Setter,
    updateAlpha,
} from '../input';
import { isNotNullNorUndefined } from '../../util';

const logger = debug('sdi:legend-item');

export const applyResolutionStyle = (fn: StyleFn, f: Feature<Geometry>) =>
    fn(f, 12);

const withLabel =
    (
        layerInfo: ILayerInfo,
        decoration: LegendDecoration,
        getView: Getter<IMapViewData>,
        setZoom: (z: number) => void
    ) =>
    (nodes: React.ReactNode[]) => {
        const classVisible = isLayerVisible(layerInfo, getView)
            ? 'visible'
            : 'invisible';
        if (layerInfo.legend !== null) {
            const label = fromRecord(layerInfo.legend).trim();
            if (label.length > 0) {
                return DIV(
                    `legend-decorated ${classVisible}`,
                    DIV(
                        'decorated',
                        isSimple(layerInfo.style)
                            ? none
                            : DIV('legend-label', label),
                        zoomToVisibleZoomBtn(layerInfo, getView, setZoom),

                        ...nodes
                    ),
                    DIV('decoration', decoration(layerInfo))
                );
            }
        }
        return DIV(
            `legend-decorated ${classVisible}`,
            DIV(
                'decorated',
                zoomToVisibleZoomBtn(layerInfo, getView, setZoom),
                ...nodes
            ),
            DIV('decoration', decoration(layerInfo))
        );
    };

const minZoom = (layerInfo: ILayerInfo) =>
    layerInfo.minZoom ? layerInfo.minZoom : 0;
const maxZoom = (layerInfo: ILayerInfo) =>
    layerInfo.maxZoom ? layerInfo.maxZoom : 30;

const visibleLegendPredicate = (layerInfo: ILayerInfo, zoom: number) => {
    logger(layerInfo.visibleLegend);
    const visible = fromPredicate(
        (li: ILayerInfo) =>
            li.visibleLegend || isInZoomRange(zoom, minZoom(li), maxZoom(li))
    )(layerInfo);
    return visible;
};

export const renderOpacitySelector = (
    opacitySelector: OpacitySelector,
    layerInfo: ILayerInfo,
    id?: number
) =>
    fromPredicate(
        (os: OpacitySelector) => os.isVisible && layerInfo.opacitySelector
    )(opacitySelector).map(() =>
        renderOpacityInput(layerInfo, opacitySelector.saveStyle, id)
    );

export const renderSimpleItemLabel = (
    layerInfo: ILayerInfo,
    md: Option<Inspire>
) => {
    const defaultLabel = md.fold(
        '',
        m => fromRecord(getMessageRecord(m.resourceTitle)) as string
    );
    return fromNullable(layerInfo.legend).fold(defaultLabel, l =>
        fromRecord(l) === '' ? defaultLabel : fromRecord(l)
    );
};

export const renderLegendItem =
    (
        layerInfo: ILayerInfo,
        md: Option<Inspire>,
        opacitySelector: OpacitySelector,
        decoration: LegendDecoration
    ) =>
    (getView: Getter<IMapViewData>, setZoom: (z: number) => void) =>
        visibleLegendPredicate(layerInfo, getView().zoom).map(() => {
            const label = withLabel(layerInfo, decoration, getView, setZoom);
            switch (layerInfo.style.kind) {
                case 'polygon-continuous':
                case 'polygon-discrete':
                case 'polygon-simple':
                    return label(
                        legendPolygon(
                            layerInfo.style,
                            layerInfo,
                            md,
                            opacitySelector
                        )
                    );
                case 'point-discrete':
                case 'point-simple':
                case 'point-continuous':
                    return label(
                        legendPoint(
                            layerInfo.style,
                            layerInfo,
                            md,
                            opacitySelector
                        )
                    );
                case 'line-simple':
                case 'line-discrete':
                case 'line-continuous':
                    return label(
                        legendLinestring(
                            layerInfo.style,
                            layerInfo,
                            md,
                            opacitySelector
                        )
                    );
                default:
                    throw new Error('UnknownStyleKind');
            }
        });

export interface Group {
    g: LayerGroup | null;
    layers: ILayerInfo[];
}

export const groupItems = (layers: ILayerInfo[]) =>
    layers
        .slice()
        .reverse()
        .reduce<Group[]>((acc, info) => {
            const ln = acc.length;
            if (ln === 0) {
                return [
                    {
                        g: info.group,
                        layers: [info],
                    },
                ];
            }
            const prevGroup = acc[ln - 1];
            const cg = info.group;
            const pg = prevGroup.g;
            // Cases:
            // info.group == null && prevGroup.g == null => append
            // info.group != null && prevGroup.g != null && info.group.id == prevGroup.id => append
            if (
                (cg === null && pg === null) ||
                (cg !== null && pg !== null && cg.id === pg.id)
            ) {
                prevGroup.layers.push(info);
                return acc;
            }
            // info.group == null && prevGroup.g != null => new
            // info.group != null && prevGroup.g == null => new
            // info.group != null && prevGroup.g != null && info.group.id != prevGroup.id => new

            return acc.concat({
                g: cg,
                layers: [info],
            });
        }, []);

export type MetadataGetter = (id: string) => Option<Inspire>;
export const renderGroups =
    (
        groups: Group[],
        getDatasetMetadata: MetadataGetter,
        decoration: LegendDecoration,
        opacitySelector: OpacitySelector
    ) =>
    (getView: Getter<IMapViewData>, setZoom: (z: number) => void) =>
        groups.map(group => {
            const layers = group.layers.filter(l => l.visible === true);
            if (layers.length === 0) {
                return DIV({}); // FIXME - we can do better than that
            }
            const items = layers.map(layer =>
                renderLegendItem(
                    layer,
                    getDatasetMetadata(layer.metadataId),
                    opacitySelector,
                    decoration
                )(getView, setZoom)
            );
            if (group.g !== null) {
                return DIV(
                    { className: 'legend-group named' },
                    DIV(
                        { className: 'legend-group-title' },
                        fromRecord(group.g.name)
                    ),
                    DIV({ className: 'legend-group-items' }, items)
                );
            }
            return DIV({ className: 'legend-group anonymous' }, items);
        });

export type LegendDecoration = (info: ILayerInfo) => NodeOrOptional;

export interface OpacitySelector {
    isVisible: boolean;
    saveStyle: Setter<ILayerInfo>;
}

export const defaultDecoration: LegendDecoration = () => none;
export const defaultOpacitySelector: OpacitySelector = {
    isVisible: false,
    saveStyle: () => void 0,
};

export const legendRenderer =
    (
        getDatasetMetadata: MetadataGetter,
        getView: Getter<IMapViewData>,
        setZoom: (n: number) => void,
        decoration = defaultDecoration,
        opacitySelector = defaultOpacitySelector
    ) =>
    (layers: ILayerInfo[]) =>
        renderGroups(
            groupItems(layers),
            getDatasetMetadata,
            decoration,
            opacitySelector
        )(getView, setZoom);

export const isInZoomRange = (zoom: number, minZoom: number, maxZoom: number) =>
    zoom > minZoom && zoom <= maxZoom;

export const isLayerVisible = (
    layerInfo: ILayerInfo,
    getView: Getter<IMapViewData>
) => {
    const { zoom } = getView();
    return scopeOption()
        .let('min', fromNullable(layerInfo.minZoom))
        .let('max', fromNullable(layerInfo.maxZoom))
        .map(({ min, max }) => isInZoomRange(zoom, min, max))
        .getOrElse(true);
};

const findNearestVisibleZoom = (
    layerInfo: ILayerInfo,
    getView: Getter<IMapViewData>
) => {
    const { zoom } = getView();
    return scopeOption()
        .let('min', fromNullable(layerInfo.minZoom))
        .let('max', fromNullable(layerInfo.maxZoom))
        .chain<[number, 'up' | 'down']>(({ min, max }) => {
            if (zoom <= min) {
                return some([min + 1, 'up']);
            } else if (zoom > max) {
                return some([max, 'down']);
            } else {
                return none;
            }
        });
};
export const zoomToVisibleZoomBtn = (
    layerInfo: ILayerInfo,
    getView: Getter<IMapViewData>,
    updateZoom: (z: number) => void
) =>
    findNearestVisibleZoom(layerInfo, getView).map(([nextZoom, direction]) =>
        DIV(
            { className: 'btn-zoomOnLayer' },
            makeIcon(
                'zoomOnLayer',
                3,
                direction === 'up' ? 'search-plus' : 'search-minus',
                { position: 'top-left', text: tr.core('layerZoomInvisible') }
            )(() => updateZoom(nextZoom))
        )
    );

const getColorOpt = (info: ILayerInfo, gid?: number) => {
    const style = info.style;
    const idxOpt = fromNullable(gid);
    switch (style.kind) {
        case 'point-simple':
            return fromNullable(style.marker).map(m => m.color);
        case 'line-simple':
            return some(style.strokeColor);
        case 'polygon-simple':
            return some(style.fillColor);
        case 'point-discrete':
            return idxOpt.map(idx => style.groups[idx].marker.color);
        case 'line-discrete':
            return idxOpt.map(idx => style.groups[idx].strokeColor);
        case 'polygon-discrete':
            return idxOpt.map(idx => style.groups[idx].fillColor);
        case 'point-continuous':
            return idxOpt.map(idx => style.intervals[idx].marker.color);
        case 'line-continuous':
            return idxOpt.map(idx => style.intervals[idx].strokeColor);
        case 'polygon-continuous':
            return idxOpt.map(idx => style.intervals[idx].fillColor);
    }
};
const getColor = (info: ILayerInfo, gid?: number) => () =>
    getColorOpt(info, gid).getOrElse('black');

const setColor =
    (info: ILayerInfo, saveStyle: Setter<ILayerInfo>, gid?: number) =>
    (color: string) => {
        logger(`COLOR: ${color}`);
        const style = info.style;
        const idxOpt = fromNullable(gid);
        switch (style.kind) {
            case 'point-simple':
                fromNullable(style.marker).map(m => (m.color = color));
                break;
            case 'line-simple':
                style.strokeColor = color;
                break;
            case 'polygon-simple':
                style.fillColor = color;
                break;
            case 'point-discrete':
                idxOpt.map(idx => (style.groups[idx].marker.color = color));
                break;
            case 'line-discrete':
                idxOpt.map(idx => (style.groups[idx].strokeColor = color));
                break;
            case 'polygon-discrete':
                idxOpt.map(idx => (style.groups[idx].fillColor = color));
                break;
            case 'point-continuous':
                idxOpt.map(idx => (style.intervals[idx].marker.color = color));
                break;
            case 'line-continuous':
                idxOpt.map(idx => (style.intervals[idx].strokeColor = color));
                break;
            case 'polygon-continuous':
                idxOpt.map(idx => (style.intervals[idx].fillColor = color));
                break;
        }
        saveStyle(info);
    };

// export const renderOpacityInputSimple = (
//     info: ILayerInfo,
//     saveStyle: Setter<ILayerInfo>
// ) => renderInputAlphaColor(getColor(info), setColor(info, saveStyle));

export const renderOpacityInput = (
    info: ILayerInfo,
    saveStyle: Setter<ILayerInfo>,
    gid?: number
) => {
    if (isNotNullNorUndefined(gid)) {
        return renderInputAlphaColor(
            getColor(info, gid),
            setColor(info, saveStyle, gid)
        );
    } else if (isDiscrete(info.style) && info.style.groups.length > 0) {
        const setMultipleAlpha = (groups: DiscreteGroup[]) => (alpha: number) =>
            groups.forEach((_, i) => {
                setColor(
                    info,
                    saveStyle,
                    i
                )(updateAlpha(getColor(info, i)(), alpha));
            });
        return layerOpacitySelector(
            getColor(info, 0),
            setMultipleAlpha(info.style.groups)
        );
    } else if (isContinuous(info.style) && info.style.intervals.length > 0) {
        const setMultipleColors =
            (intervals: ContinuousInterval[]) => (alpha: number) =>
                intervals.forEach((_, i) =>
                    setColor(
                        info,
                        saveStyle,
                        i
                    )(updateAlpha(getColor(info, i)(), alpha))
                );
        return layerOpacitySelector(
            getColor(info, 0),
            setMultipleColors(info.style.intervals)
        );
    }
    return renderInputAlphaColor(getColor(info), setColor(info, saveStyle));
};

logger('loaded');
