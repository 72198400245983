import { fromNullable } from 'fp-ts/lib/Option';
import { Translated } from '../../locale';
import { DIV, INPUT, LABEL, NodeOrOptional } from '../elements';

export const renderCheckbox =
    (
        name: string,
        title: () => Translated,
        select: (a: boolean) => void,
        renderItem?: NodeOrOptional
    ) =>
    (isChecked: boolean, runtimeClassName = '') =>
        DIV(
            'checkbox__wrapper',
            LABEL(
                { title: title() },
                fromNullable(renderItem).getOrElse(title()),
                INPUT({
                    name,
                    className: runtimeClassName,
                    type: 'checkbox',
                    defaultChecked: isChecked,
                    onChange: () => select(!isChecked),
                })
            )
        );
