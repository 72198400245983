import { Coordinate } from 'ol/coordinate';

import { Setter, Getter } from '../shape';
import {
    TrackerCoordinate,
    IViewEvent,
    IMapViewData,
    IMapScale,
    Interaction,
    IGeoTracker,
    InteractionTrack,
    IGeoMeasure,
    InteractionMeasure,
    defaultInteraction,
    withInteraction,
} from '.';
import { Setoid } from 'fp-ts/lib/Setoid';

// 'port/map/measure': IGeoMeasure;
const measureEvent = (state: IGeoMeasure): InteractionMeasure => ({
    state,
    label: 'measure',
});
export const measureEventsFactory = (
    dispatch: Setter<Interaction>,
    get: Getter<Interaction>
) => ({
    startMeasureLength() {
        dispatch(() =>
            measureEvent({
                geometryType: 'LineString',
                coordinates: [],
            })
        );
    },

    startMeasureArea() {
        dispatch(() =>
            measureEvent({
                geometryType: 'Polygon',
                coordinates: [],
            })
        );
    },

    stopMeasure() {
        dispatch(defaultInteraction);
    },

    updateMeasureCoordinates(coordinates: Coordinate[]) {
        withInteraction<InteractionMeasure>('measure', it =>
            dispatch(() =>
                measureEvent({
                    coordinates,
                    geometryType: it.state.geometryType,
                })
            )
        )(get());
    },
});

// 'port/map/tracker': IGeoTracker;
const trackerEvent = (state: IGeoTracker): InteractionTrack => ({
    state,
    label: 'track',
});

const TRACKER_THRESHOLD = 0.000001;

export const setoidTrackerCoordinate: Setoid<TrackerCoordinate> = {
    equals: (x: TrackerCoordinate, y: TrackerCoordinate) =>
        Math.abs(x.coord[0] - y.coord[0]) < TRACKER_THRESHOLD &&
        Math.abs(x.coord[1] - y.coord[1]) < TRACKER_THRESHOLD &&
        Math.abs(x.accuracy - y.accuracy) < TRACKER_THRESHOLD,
};

export const trackerEventsFactory = (
    dispatch: Setter<Interaction>,
    getter: Getter<Interaction>
) => ({
    startTrack() {
        dispatch(() =>
            trackerEvent({
                track: [],
            })
        );
    },

    stopTrack() {
        dispatch(defaultInteraction);
    },

    resetTrack() {
        dispatch(() =>
            trackerEvent({
                track: [],
            })
        );
    },

    updateTrack(coords: TrackerCoordinate) {
        withInteraction<InteractionTrack>('track', interaction =>
            dispatch(() =>
                trackerEvent({
                    track: interaction.state.track.concat([coords]),
                })
            )
        )(getter());
    },
});

// 'port/map/view': IMapViewData;
export const viewEventsFactory = (dispatch: Setter<IMapViewData>) => ({
    updateMapView(data: IViewEvent): void {
        dispatch(viewState => ({
            dirty: data.dirty !== undefined ? data.dirty : viewState.dirty,
            center: data.center || viewState.center,
            rotation: data.rotation || viewState.rotation,
            zoom: data.zoom || viewState.zoom,
            srs: viewState.srs,
            feature: data.feature || null,
            extent: data.extent || null,
        }));
    },
});

// 'port/map/scale': IMapScale;
export const scaleEventsFactory = (dispatch: Setter<IMapScale>) => ({
    setScaleLine(count: number, unit: string, width: number) {
        dispatch(() => ({ count, unit, width }));
    },
});

export const zoomIn = (dispatch: Setter<IMapViewData>) =>
    dispatch(v => ({
        ...v,
        dirty: 'geo',
        zoom: v.zoom + 1,
    }));
export const zoomOut = (dispatch: Setter<IMapViewData>) =>
    dispatch(v => ({
        ...v,
        dirty: 'geo',
        zoom: v.zoom - 1,
    }));
export const zoomRegio = (dispatch: Setter<IMapViewData>) =>
    dispatch(v => ({
        ...v,
        dirty: 'geo',
        zoom: 5,
    }));
export const resetRotate = (dispatch: Setter<IMapViewData>) =>
    dispatch(v => ({
        ...v,
        dirty: 'geo',
        rotation: 0,
    }));
// export const setFullScreen = (dispatch: Setter<IMapViewData>) =>
//     dispatch((v) => ({
//         ...v,
//         dirty: 'geo/extent',
//     }))
