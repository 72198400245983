/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { NodeOrOptional } from '../components/elements';
import { buttonTooltipLeft } from '../components/tooltip';
import * as debug from 'debug';
import tr from '../locale/index';
import { getHighlightedBaseLayers } from './queries';
import { Getter, Setter } from '../components/input/input';
import { none } from 'fp-ts/lib/Option';

const logger = debug('sdi:map-info/base-layer-switch');

const render = (
    getMapBaseLayer: Getter<string | null>,
    setMapBaseLayer: Setter<string>
): NodeOrOptional => {
    const baseLayers = getHighlightedBaseLayers();
    const current = getMapBaseLayer();
    const len = baseLayers.length;
    if (current) {
        if (len === 2) {
            const bl1 = baseLayers[0];
            const bl2 = baseLayers[1];

            if (bl1.codename === current) {
                return buttonTooltipLeft(tr.core('changeBackgroundMap'), {
                    className: 'switch-background',
                    style: {
                        backgroundImage: `url(${bl2.img})`,
                    },
                    onClick: () => setMapBaseLayer(bl2.codename),
                }) as NodeOrOptional;
            }

            return buttonTooltipLeft(tr.core('changeBackgroundMap'), {
                className: 'switch-background',
                style: {
                    backgroundImage: `url(${bl1.img})`,
                },
                onClick: () => setMapBaseLayer(bl1.codename),
            }) as NodeOrOptional;
        }
    }
    return none;
};

export default render;

logger('loaded');
