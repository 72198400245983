import { none, some } from "fp-ts/lib/Option";
import { tryNumber } from "../../util";


type GroupValue = {
    values: (string | number)[];
}

type Stringer = { toString(): string };

const isStringer = (a: unknown): a is Stringer =>
    (a as any).toString !== undefined
    && typeof (a as any).toString === 'function'
    && typeof (a as any).toString() === 'string';


type Term = null | undefined | string | number | string[] | number[] | Stringer;

const compMixed = (
    a: string | number,
) => (
    b: string | number,
    ) => {
        if (typeof a === typeof b) {
            return a === b
        }
        else if (typeof a === 'string' && typeof b === 'number') {
            return a === b.toString()
        }
        else {
            return a.toString() === b
        }
    }

const findStringIndex = (
    term: string,
    groups: GroupValue[],
) => {
    for (let i = 0; i < groups.length; i += 1) {
        const group = groups[i];
        const idx = group.values.findIndex(compMixed(term));
        if (idx >= 0) {
            return i;
        }
    }
    return -1;
};


const findNumberIndex = (
    term: number,
    groups: GroupValue[],
) => {
    for (let i = 0; i < groups.length; i += 1) {
        const group = groups[i];
        const idx = group.values.findIndex(compMixed(term));
        if (idx >= 0) {
            return i;
        }
    }
    return -1;
};



const findIndex = (
    term: Term,
    groups: GroupValue[],
): number => {
    if (term === undefined || term === null || groups.length === 0) {
        return -1
    }
    else if (typeof term === 'number') {
        return findNumberIndex(term, groups)
    }
    else if (typeof term === 'string') {
        return findStringIndex(term, groups)
    }
    else if (Array.isArray(term)) {
        if (term.length > 0) {
            for (const t of term) {
                const idx = findIndex(t, groups)
                if (idx >= 0) {
                    return idx
                }
            }
        }
    }
    else if (isStringer(term)) {
        return findStringIndex(term.toString(), groups)
    }
    return -1
};

export const withIndex = (
    term: Term,
    groups: GroupValue[],
) => {
    const idx = findIndex(term, groups)
    return idx >= 0 ? some(idx) : none;
}



type Interval = {
    low: number;
    high: number;
}



export const findLow = (
    value: unknown,
    intervals: Interval[],
) => tryNumber(value)
    .chain((n) => {
        for (let i = 0; i < intervals.length; i += 1) {
            if (n >= intervals[i].low
                && n < intervals[i].high) {
                return some(intervals[i].low);
            }
        }
        return none;
    })