import * as debug from 'debug';

import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import { fromNullable } from 'fp-ts/lib/Option';

import {
    MarkOptions,
    withInteraction,
    InteractionMark,
} from '..';

const logger = debug('sdi:map/mark');

export const mark =
    ({ endMark, startMark }: MarkOptions) => {
        let mapRef: Map;
        const marker = document.createElement('div');
        marker.setAttribute('class', 'map-marker');
        const overlay = new Overlay({
            positioning: 'center-center',
            element: marker,
        });

        const update = withInteraction<InteractionMark>('mark',
            ({ state }) => fromNullable(mapRef).map(
                (m) => {
                    logger(`update`, state.started, state.coordinates);
                    if (state.started) {
                        if (state.endTime <= Date.now()) {
                            m.removeOverlay(overlay);
                            endMark();
                        }
                        else {
                            overlay.setPosition(state.coordinates);
                        }
                    }
                    else {
                        overlay.setPosition(state.coordinates);
                        m.addOverlay(overlay);
                        startMark();
                    }
                }),
            () => fromNullable(mapRef).map((m) => { m.removeOverlay(overlay); }),
        );

        const init =
            (map: Map) => {
                mapRef = map;
            };

        return { init, update };
    };


logger('loaded');
