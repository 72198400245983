import { DIV } from '../elements';
import { Setoid } from 'fp-ts/lib/Setoid';
import { ReactNode } from 'react';


const renderSelectItem =
    <T>(
        renderItem: (a: T) => ReactNode,
        add: (a: T) => void,
    ) =>
        (v: T) =>
            DIV({ className: 'checkbox__item', onClick: () => add(v) },
                DIV({ className: 'checkbox__box' }),
                renderItem(v));

const renderSelectItemSelected =
    <T>(
        renderItem: (a: T) => ReactNode,
        remove: (a: T) => void,
    ) =>
        (v: T) =>
            DIV({ className: 'checkbox__item selected', onClick: () => remove(v) },
                DIV({ className: 'checkbox__box' }),
                renderItem(v));




export const renderMultiSelect =
    <T>(
        S: Setoid<T>,
        renderItem: (a: T) => ReactNode,
        add: (a: T) => void,
        remove: (a: T) => void,
    ) =>
        (
            list: Readonly<T[]>,
            selected: T[],
        ) => {
            const mkItem = renderSelectItem<T>(renderItem, add);
            const mkSelected = renderSelectItemSelected<T>(renderItem, remove);
            const items = list.map((i) => {
                if (selected.findIndex(item => S.equals(i, item)) >= 0) {
                    return mkSelected(i);
                }
                return mkItem(i);
            });

            return (
                DIV({ className: 'select-multi' }, ...items));
        };
