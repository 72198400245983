/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { SPAN, NODISPLAY, DIV, BUTTON, NODISPLAYBUTTON } from './elements';
import { getLang, setLang } from '../app';
import { MessageRecordLang } from '../source';
import { tr, Translated } from '../locale';
import { activity, langAction } from '../activity';
import { nameToString } from './button/names';

const logger = debug('sdi:lang-switch');

const logLang = (namespace: string, lang: MessageRecordLang) =>
    activity(namespace)(langAction(lang));

const sl = (lc: MessageRecordLang) => setLang(lc);

// ns is the app name
export const switcher = (ns: string) => {
    const lc = getLang();
    switch (lc) {
        case 'fr':
            return BUTTON(
                {
                    className: 'lang-switch',
                    onClick: () => {
                        sl('nl');
                        logLang(ns, 'nl');
                    },
                },

                SPAN('icon fa', nameToString('comment')),
                SPAN('lang-switch-label', `${tr.core('switchLang')}`)
            );

        case 'nl':
            return BUTTON(
                {
                    className: 'lang-switch',
                    onClick: () => {
                        sl('fr');
                        logLang(ns, 'fr');
                    },
                },

                SPAN('icon fa', nameToString('comment')),
                SPAN('lang-switch-label', `${tr.core('switchLang')}`)
            );

        default:
            return NODISPLAYBUTTON();
    }
};

export const switcherLarge = (
    label: () => Translated,
    lc: MessageRecordLang
) => {
    switch (lc) {
        case 'fr':
            return DIV(
                'lang-switch-large',
                DIV('lang-switch-lang', `${tr.core('switchLang')}`),
                DIV('lang-switch-label', label())
            );

        case 'nl':
            return DIV(
                'lang-switch-large',
                DIV('lang-switch-label', label()),
                DIV('lang-switch-lang', `${tr.core('switchLang')}`)
            );

        default:
            return NODISPLAY();
    }
};

// export const switcherLargeNoLabel =
//     () => {
//         const lc = getLang();
//         switch (lc) {
//             case 'fr':
//                 return (DIV({
//                     className: 'lang-switch-large',
//                     onClick: sl('nl'),
//                 },
//                     DIV({ className: 'lang-switch-lang' }, `${tr.core('switchLang')}`),
//                 ));

//             case 'nl':
//                 return (DIV({
//                     className: 'lang-switch-large',
//                     onClick: sl('fr'),
//                 },
//                     DIV({ className: 'lang-switch-lang' }, `${tr.core('switchLang')}`),
//                 ));

//             default:
//                 return NODISPLAY();
//         }
//     };

export default switcher;

logger('loaded');
