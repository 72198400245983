import {
    postUnrelatedIO,
    remoteError,
    remoteLoading,
    RemoteResource,
    remoteSuccess,
} from '../source';
import * as debug from 'debug';
import {
    ServiceDescription,
    ServiceRequest,
    ServiceResponse,
    ServiceResponseIO,
} from './io';
import { Nullable } from '../util';
import { dispatchGeocoderState } from './events';

const logger = debug('sdi:locale');

export interface GeocoderState {
    serviceList: ServiceDescription[];
    response: Nullable<RemoteResource<ServiceResponse>>;
    selected: Nullable<string>;
}
export const geocoderDefaultState: GeocoderState = {
    serviceList: [],
    response: null,
    selected: null,
};

export const queryService = (serviceRequest: ServiceRequest) => {
    const q = {
        // spatialReference: serviceRequest.proj,
        service: serviceRequest.service,
        lang: serviceRequest.lang,
        input: serviceRequest.input,
    };
    dispatchGeocoderState(remoteLoading);
    return postUnrelatedIO(ServiceResponseIO, `/lookup/service`, q)
        .then(data => {
            if (data.error) {
                dispatchGeocoderState(remoteError('geocoder ERROR'));
            } else {
                dispatchGeocoderState(remoteSuccess(data));
            }
        })
        .catch(err => {
            logger(`geocoder error: ${err.toString()}`);
            dispatchGeocoderState(remoteError(err));
        });
};

logger('loaded');
