/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable } from 'fp-ts/lib/Option';

import { DIV, H2, NODISPLAY, SPAN, H3 } from 'sdi/components/elements';
import tr, { fromRecord } from 'sdi/locale';
import { translateMapBaseLayer } from 'sdi/util';
import { IMapBaseLayer } from 'sdi/source';

import {
    getBaseLayer as getBaseLayer,
    getBaseLayersForService,
    getCurrentBaseLayerName,
    getBaseLayerServices,
} from '../../queries/app';
import { setMapBaseLayer } from '../../events/app';
import { renderRadioIn } from 'sdi/components/input';

const logger = debug('sdi:webservices');

const renderCurrentBaseLayer = (bl: IMapBaseLayer) => {
    const tl = translateMapBaseLayer(bl);
    // const lyrs = tl.params.LAYERS.split(',');
    // const legends = lyrs.map(lyr => IMG({
    //     key: `legend-image-${tl.url}-${lyr}`,
    //     src: `${tl.url}?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=${tl.params.VERSION}&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${lyr}`,
    // }));

    return DIV(
        { className: 'base-layer active', key: `renderBaseLayer-${bl.url}` },
        SPAN({}, tl.name)
    );
};

const renderBaseLayer = (id: string) => {
    const current = getCurrentBaseLayerName();
    return fromNullable(getBaseLayer(id)).fold(
        NODISPLAY({ key: `renderBaseLayer-${id}` }),
        bl => {
            if (id === current) {
                return renderCurrentBaseLayer(bl);
            }
            return DIV(
                {
                    key: `renderBaseLayer-${id}`,
                    className: `base-layer`,
                    onClick: () => setMapBaseLayer(id),
                },
                fromRecord(bl.name)
            );
        }
    );
};

// const getAndSortBaseLayers = (service: string) =>
//     getBaseLayersForService(service)
//         .map(id => fromNullable(gteBaseLayer(id))
//             .map(baselayer => pair(id, fromRecord(baselayer.name).toString()))
//             .getOrElse(pair(id, '')))
//         .sort((a, b) => { return (snd(a) > snd(b)) ? -1 : 1; })
//         .map(p => fst(p));

const baseLayersForServiceStrings = (service: string) =>
    getBaseLayersForService(service).map(
        layer => `${service}/${layer.codename}`
    );
const baseLayersRadio = renderRadioIn(
    'base-layer-list',
    renderBaseLayer,
    setMapBaseLayer,
    'radio'
);

const renderService = (service: string) => {
    return DIV(
        { className: 'webservice', key: `renderService-${service}` },
        H3({ className: 'webservice-name' }, service),
        // getBaseLayersForService(service)
        //     .map(layer => {
        //         logger(layer);
        //         return renderBaseLayer(`${service}/${layer.codename}`, getCurrentBaseLayerName());
        //     })
        baseLayersRadio(
            baseLayersForServiceStrings(service),
            fromNullable(getCurrentBaseLayerName()).getOrElse('')
        )
    );
};

const webservices = () => {
    return DIV(
        { className: 'sidebar-main tool wms-picker' },
        H2({}, tr.view('wmsSwitch')),
        DIV({}, tr.view('helptext:wmsSwitchTool')),
        DIV(
            { className: 'tool-body' },
            getBaseLayerServices().map(renderService)
        )
    );
};

export default webservices;

logger('loaded');
