const firstKey = Symbol();
const secondKey = Symbol();

export interface Pair<A, B> {
    readonly [firstKey]: A;
    readonly [secondKey]: B;
}

export function pair<A, B>(a: A, b: B): Pair<A, B> {
    return { [firstKey]: a, [secondKey]: b };
}

export function fst<A>(p: Pair<A, unknown>) {
    return p[firstKey];
}

export function snd<B>(p: Pair<unknown, B>) {
    return p[secondKey];
}

export function pmap<A, B, R>(f: (a: A, b: B) => R) {
    return (p: Pair<A, B>) => f(fst(p), snd(p));
}
