import * as debug from 'debug';

const logger = debug('sdi:map/singleclick');

import Map from 'ol/Map';

import {
    SingleClickOptions,
    withInteraction,
} from '..';


export const singleclick =
    ({ setPosition }: SingleClickOptions) => {
        let isActive = false;

        const update = withInteraction('singleclick',
            () => {
                isActive = true;
            },
            () => {
                isActive = false;
            });

        const init =
            (map: Map) => {
                map.on('singleclick', (event) => {
                    logger(`hit ${isActive}`);
                    setPosition(event.coordinate, isActive);
                    // if (isActive) {
                    // }
                });
            };

        return { init, update };
    };


logger('loaded');
