/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { dispatch } from 'sdi/shape';
import { getSelectedCategories } from '../queries/mapnavigator';
import {
    defaultFilter,
    defaultHomeConfigTable,
    defaultHomeConfigTile,
    filterCat,
    filterPat,
    HomeTableSort,
} from '../shape/types';

export const filterMapsWithPat = (pattern: string) =>
    dispatch('component/home', s => ({ ...s, filter: filterPat(pattern) }));

export const addFilterMapsCat = (cid: string) =>
    dispatch('component/home', s => ({
        ...s,
        filter: filterCat(
            getSelectedCategories()
                .filter(c => c !== cid)
                .concat(cid)
        ),
    }));

export const removeFilterMapsCat = (cid: string) => {
    const cats = getSelectedCategories().filter(c => c !== cid);
    dispatch('component/home', s => ({
        ...s,
        filter: { ...s.filter, categories: cats },
    }));
};

export const resetFilter = () =>
    dispatch('component/home', s => ({ ...s, filter: defaultFilter() }));

export const toggleHomeMode = () =>
    dispatch('component/home', s => {
        switch (s.mode) {
            case 'table':
                return defaultHomeConfigTile(s.filter);
            case 'tile':
                return defaultHomeConfigTable(s.filter);
        }
    });

export const setHomeTableSort = (tableSort: HomeTableSort) =>
    dispatch('component/home', s => ({ ...s, sort: tableSort }));
