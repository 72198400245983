import * as debug from 'debug';
import { init } from 'sdi/components/modal';

const logger = debug('sdi:view/modal');

export const { render, register } = init();
export default render;

export { render as renderBookmark } from './bookmark';

logger('loaded');
