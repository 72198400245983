import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { query, queryK } from 'sdi/shape';
import { HarvestedLayer } from '../components/query';

export const getGeometry = () =>
    fromNullable(query('component/harvest/geometry'));

export const getGeometryType = () =>
    fromNullable(query('component/harvest/geometry-type'));

export const getHarvests = queryK('component/harvest/results');

export const findHarvest = (
    mapId: string,
    mid: string // TODO
) =>
    fromNullable(
        getHarvests()
            .filter(h => h.mapId === mapId)
            .find(h => h.metadataId === mid)
    );

export const isHarvesting = () =>
    fromPredicate((arr: readonly HarvestedLayer[]) => arr.length > 0)(
        getHarvests()
    );

export const getMiniMap = (k: string) =>
    fromNullable(query('component/harvest/minimap')[k]);
