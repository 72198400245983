/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    LineStyleConfigSimple,
    LineStyleConfigDiscrete,
    LineStyleConfig,
    LineStyleConfigContinuous,
    LineDiscreteGroup,
    LineInterval,
} from '../../source';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { StyleFn } from '.';
import { getLabelStyleFn } from './label';
import { findLow, withIndex } from './select';
import Geometry from 'ol/geom/Geometry';

const strokeStyle = (
    config: LineStyleConfigSimple | LineDiscreteGroup | LineInterval
) =>
    new Stroke({
        lineCap: config.dash.length > 0 ? 'butt' : 'round',
        color: config.strokeColor,
        width: config.strokeWidth,
        lineDash: config.dash.map(v => v * config.strokeWidth),
    });

const lineStyleSimple = (config: LineStyleConfigSimple) => {
    // needed if we want to change the x offset with line... (nw)
    // const textPlacement =
    //     config.label && config.label.xOffset ? 'point' : 'line';
    const labelStyle = getLabelStyleFn(config, 'line');

    const styles = [new Style({ stroke: strokeStyle(config) })];

    return (feature: Feature<Geometry>, resolution: number) =>
        labelStyle(feature, resolution, styles);
};

const lineStyleDiscrete = (config: LineStyleConfigDiscrete) => {
    const labelStyle = getLabelStyleFn(config, 'line');
    const groups = config.groups;
    const groupStyles = groups.reduce<Style[]>((acc, group) => {
        acc.push(
            new Style({
                stroke: strokeStyle(group),
            })
        );
        return acc;
    }, []);

    return (feature: Feature<Geometry>, resolution: number) => {
        const styles: Style[] = [];

        const props = feature.getProperties();
        const value = props[config.propName];
        withIndex(value, groups).map(idx => styles.push(groupStyles[idx]));

        return labelStyle(feature, resolution, styles);
    };
};

type StyleReg = { [k: number]: Style };

const lineStyleContinuous = (config: LineStyleConfigContinuous) => {
    const labelStyle = getLabelStyleFn(config, 'line');
    const intervals = config.intervals;
    const styles = intervals.reduce<StyleReg>((acc, itv) => {
        acc[itv.low] = new Style({
            stroke: strokeStyle(itv),
        });
        return acc;
    }, {});

    return (feature: Feature<Geometry>, resolution: number) => {
        const props = feature.getProperties();

        return findLow(props[config.propName], intervals).fold(
            labelStyle(feature, resolution, []),
            low => labelStyle(feature, resolution, [styles[low]])
        );
    };
};

const lineStyle = (config: LineStyleConfig): StyleFn => {
    switch (config.kind) {
        case 'line-simple':
            return lineStyleSimple(config);
        case 'line-discrete':
            return lineStyleDiscrete(config);
        case 'line-continuous':
            return lineStyleContinuous(config);
    }
};

export default lineStyle;
