/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

import { fromNullable, Option } from 'fp-ts/lib/Option';

import { pointStyle, getContext, IOLContext } from '../../map/style';
import { DIV, SPAN, IMG, NodeOrOptional } from '../elements';
import { fromRecord } from '../../locale';
import {
    ILayerInfo,
    PointStyleConfig,
    PointStyleConfigDiscrete,
    PointStyleConfigSimple,
    PointStyleConfigContinuous,
    Inspire,
} from '../../source';

import {
    applyResolutionStyle,
    defaultOpacitySelector,
    OpacitySelector,
    renderOpacitySelector,
    renderSimpleItemLabel,
} from '.';

const logger = debug('sdi:legend-point');

const pointGeometry = (width: number) => new Point([width / 2, width / 2]);

const getMaxSize = (config: PointStyleConfig) => {
    switch (config.kind) {
        case 'point-simple':
            return fromNullable(config.marker)
                .map(m => m.size)
                .getOrElse(0);
        case 'point-discrete':
            return config.groups.reduce(
                (acc, g) => Math.max(acc, g.marker.size),
                0
            );
        case 'point-continuous':
            return config.intervals.reduce(
                (acc, i) => Math.max(acc, i.marker.size),
                0
            );
    }
};

const item = (geomType: string, dataUrl: string, label: string) => {
    return DIV(
        { className: `legend-item ${geomType}` },
        DIV({ className: 'item-style' }, IMG({ src: dataUrl, alt: '' })),
        DIV({ className: 'item-label' }, SPAN({}, label))
    );
};

const renderSimple = (
    config: PointStyleConfigSimple,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, olContext } = ctx;
    const styleFn = pointStyle(config);
    const styles = applyResolutionStyle(
        styleFn,
        new Feature(pointGeometry(canvas.width))
    );
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    styles.forEach(style => {
        olContext.setStyle(style);
        olContext.drawGeometry(pointGeometry(canvas.width));
    });
    const label = renderSimpleItemLabel(layerInfo, md);
    return [
        DIV(
            `item ${opacityClass}`,
            item('point', canvas.toDataURL(), label),
            renderOpacitySelector(opacitySelector, layerInfo)
        ),
    ];
};

const renderDiscrete = (
    config: PointStyleConfigDiscrete,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = pointStyle(config);
    const items: NodeOrOptional[] = [];
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    config.groups.forEach((group, id) => {
        if (group.values.length > 0) {
            canvasContext.clearRect(0, 0, 100, 100);
            const f = new Feature(pointGeometry(canvas.width));
            f.set(config.propName, group.values[0]);
            const styles = applyResolutionStyle(styleFn, f);
            styles.forEach(style => {
                olContext.drawFeature(f, style);
            });
            items.push(
                DIV(
                    `item ${opacityClass}`,
                    item('point', canvas.toDataURL(), fromRecord(group.label)),
                    renderOpacitySelector(opacitySelector, layerInfo, id)
                )
            );
        }
    });

    return items;
};

const renderContinuous = (
    config: PointStyleConfigContinuous,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = pointStyle(config);
    const items: NodeOrOptional[] = [];
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    config.intervals.forEach(interval => {
        canvasContext.clearRect(0, 0, 100, 100);
        const f = new Feature(pointGeometry(canvas.width));
        const v = interval.low + (interval.high - interval.low) / 2;
        f.set(config.propName, v);
        const styles = applyResolutionStyle(styleFn, f);
        styles.forEach(style => {
            olContext.drawFeature(f, style);
        });
        items.push(
            DIV(
                `item ${opacityClass}`,
                item('point', canvas.toDataURL(), fromRecord(interval.label)),
                renderOpacitySelector(opacitySelector, layerInfo)
            )
        );
    });

    return items;
};

const render = (
    config: PointStyleConfig,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    opacitySelector = defaultOpacitySelector
) => {
    const ms = getMaxSize(config) + 2;
    const ctx = getContext(ms, ms);
    if (ctx) {
        switch (config.kind) {
            case 'point-simple':
                return renderSimple(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
            case 'point-discrete':
                return renderDiscrete(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
            case 'point-continuous':
                return renderContinuous(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
        }
    }
    return [];
};

export default render;

logger('loaded');
