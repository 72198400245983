/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import * as debug from 'debug';
import { query, queryK } from 'sdi/shape';
import {
    Category,
    IMapInfo,
    IUser,
    MapStatus,
    remoteToOption,
} from 'sdi/source';
import { fromRecord } from 'sdi/locale';
import { fromNullable, none, some } from 'fp-ts/lib/Option';
import {
    HomeConfigTable,
    HomeConfigTile,
    HomeFilter,
    HomeTableKey,
    HomeTableSort,
} from '../shape/types';
import { getLang, getUserData } from 'sdi/app';
import { scopeOption } from 'sdi/lib';
import { isEmpty } from 'fp-ts/lib/Array';
import { getMapInfo } from './app';
import { identity } from 'io-ts';
import { userName } from './app';
import { mapStatus } from 'sdi/map';

const logger = debug('sdi:queries/navigator');

const userIsAuthor = (user: IUser, mapInfo: IMapInfo) =>
    user.maps.indexOf(mapInfo.id) >= 0;

export const getMaps = (): IMapInfo[] =>
    remoteToOption(query('data/maps')).getOrElse([]);

export const getVersionOf = (map: IMapInfo, status: MapStatus) => {
    if (map.status === status) {
        return some(map);
    }
    const mapVersion = getMaps().find(
        info => info.status == status && info.id_origin == map.id_origin
    );
    return fromNullable(mapVersion);
};

export const getVersion = (status: MapStatus) =>
    fromNullable(getMapInfo()).chain(current => getVersionOf(current, status));

export const hasPublishedVersion = (maps: IMapInfo[], info: IMapInfo) =>
    maps.some(m => m.id_origin == info.id_origin && m.status === 'published');

export const hasSavedVersion = (info: IMapInfo) =>
    getMaps().some(m => m.id_origin == info.id_origin && m.status === 'saved');

// in a list of maps (here of same id_origin), get the one we want to show in atlas (published if there is one, draft otherwise)
const getVisibleMap = (mapsVersions: IMapInfo[]) =>
    fromNullable(mapsVersions.find(m => m.status === 'published')).fold(
        mapsVersions.find(m => m.status === 'draft'),
        identity
    );

const uniqueMaps = () => {
    //get a dictionnary of maps by id_origin
    const uniqueMapsDict = getMaps().reduce(
        (acc: { [key: string]: IMapInfo[] }, m: IMapInfo) => {
            const tmp = acc[m.id_origin];
            if (tmp != undefined) {
                acc[m.id_origin] = tmp.concat(m);
            } else {
                acc[m.id_origin] = [m];
            }
            return acc;
        },
        {}
    );
    // get the list of maps, unique by id_origin
    return Object.values(uniqueMapsDict).reduce(
        (acc: IMapInfo[], value) =>
            fromNullable(getVisibleMap(value))
                .map(m => acc.concat(m))
                .getOrElse(acc),
        []
    );
};

const getVisibleMaps = (maps: IMapInfo[]) =>
    getUserData().foldL(
        () => maps.filter(m => m.status === 'published'),
        user =>
            uniqueMaps().filter(
                m => m.status === 'published' || userIsAuthor(user, m)
            )
    );

// export const getCategories = () => {
//     const maps = getVisibleMaps();
//     const cats = query('data/categories');
//     const ret = cats.map(cat => {
//         const cid = cat.id;
//         const catMaps = maps.reduce<IMapInfo[]>((acc, m) => {
//             if (m.categories.indexOf(cid) >= 0) {
//                 return acc.concat(m);
//             }
//             return acc;
//         }, []);
//         return [cat, catMaps];
//     });
//     const uncategorized = maps.reduce<IMapInfo[]>((acc, m) => {
//         if (m.categories.length === 0) {
//             return acc.concat(m);
//         }
//         return acc;
//     }, []);
//     ret.push([{ id: '', name: makeRecord() }, uncategorized]);
//     return ret;
// };

export const getCategories = queryK('data/categories');

export const findCategory = (cid: string) =>
    fromNullable(getCategories().find(({ id }) => id === cid));

export const getVisibleCategories = (): Category[] =>
    getCategories().filter(({ id }) => getMapsInCategory(id).length > 0);

const getMapsInCategories = (maps: IMapInfo[], selectCategories: string[]) =>
    isEmpty(selectCategories)
        ? getVisibleMaps(maps)
        : getVisibleMaps(maps).filter(map =>
              map.categories.some(cat => selectCategories.includes(cat))
          );
export const getMapsInCategory = (cid: string) =>
    getVisibleMaps(getMaps()).filter(
        ({ categories }) => categories.indexOf(cid) >= 0
    );

const getHomeConfig = () => query('component/home');

export const getMode = () => getHomeConfig().mode;
export const getFilter = () => getHomeConfig().filter;
export const getSelectedCategories = () => getFilter().categories;
export const getPatternFilter = () => fromNullable(getFilter().pattern);

const filterMap = (pat: RegExp) => (m: IMapInfo) => {
    const title = fromRecord(m.title);
    const description = fromRecord(m.description);
    return pat.test(title) || pat.test(description);
};

// TODO: use Intl.Collator and LCS
// const getFilteredMapsPat = ({ pattern }: HomeFilterPat) =>
//     getVisibleMaps().filter(filterMap(new RegExp(`.*${pattern}.*`, 'i')));

// const getFilteredMapsCat = ({ categories }: HomeFilterCat) =>
//     getMapsInCategories(categories);

const filterWithPattern = (pattern: string | null) =>
    fromNullable(pattern).foldL(
        () => () => true,
        pat => filterMap(new RegExp(`.*${pat}.*`, 'i'))
    );

const getFilteredMaps = (maps: IMapInfo[], filter: HomeFilter) =>
    getMapsInCategories(maps, filter.categories).filter(
        filterWithPattern(filter.pattern)
    );

// const getFilteredMaps = (filter: HomeFilter) =>
//     fromNullable(filter)
//         .map(filter => {
//             switch (filter.tag) {
//                 case 'pat':
//                     return getFilteredMapsPat(filter);
//                 case 'cat':
//                     return getFilteredMapsCat(filter);
//             }
//         })
//         .getOrElseL(getVisibleMaps);

const getMapsTile = (maps: IMapInfo[], { filter }: HomeConfigTile) =>
    getFilteredMaps(maps, filter);

const sortFn = ([key, dir]: HomeTableSort) => {
    // const op = dir === 'asc' | (a,b) => a - b
    switch (key) {
        case 'colTitle': {
            const collator = new Intl.Collator(getLang(), {
                sensitivity: 'base',
                usage: 'sort',
            });
            if (dir === 'asc') {
                return (a: IMapInfo, b: IMapInfo) =>
                    collator.compare(fromRecord(a.title), fromRecord(b.title));
            }
            return (a: IMapInfo, b: IMapInfo) =>
                collator.compare(fromRecord(b.title), fromRecord(a.title));
        }
        case 'colDescription': {
            const collator = new Intl.Collator(getLang(), {
                sensitivity: 'base',
                usage: 'sort',
            });
            if (dir === 'asc') {
                return (a: IMapInfo, b: IMapInfo) =>
                    collator.compare(
                        fromRecord(a.description),
                        fromRecord(b.description)
                    );
            }
            return (a: IMapInfo, b: IMapInfo) =>
                collator.compare(
                    fromRecord(b.description),
                    fromRecord(a.description)
                );
        }
        case 'colCategory': {
            const collator = new Intl.Collator(getLang(), {
                sensitivity: 'base',
                usage: 'sort',
            });
            const cats = getCategories();
            const catName = (cid: string) =>
                fromNullable(cats.find(({ id }) => cid === id))
                    .map<string>(({ name }) => fromRecord(name))
                    .getOrElse('-');
            if (dir === 'asc') {
                return (a: IMapInfo, b: IMapInfo) => {
                    const left = a.categories
                        .map(catName)
                        .sort(collator.compare)
                        .join(', ');
                    const right = b.categories
                        .map(catName)
                        .sort(collator.compare)
                        .join(', ');
                    return collator.compare(left, right);
                };
            }
            return (a: IMapInfo, b: IMapInfo) => {
                const left = a.categories
                    .map(catName)
                    .sort(collator.compare)
                    .join(', ');
                const right = b.categories
                    .map(catName)
                    .sort(collator.compare)
                    .join(', ');
                return collator.compare(right, left);
            };
        }
        case 'colDate': {
            if (dir === 'asc') {
                return (a: IMapInfo, b: IMapInfo) =>
                    a.lastModified - b.lastModified;
            }
            return (a: IMapInfo, b: IMapInfo) =>
                b.lastModified - a.lastModified;
        }
        case 'colStatus': {
            if (dir === 'asc') {
                return (a: IMapInfo, b: IMapInfo) =>
                    mapStatus(a).localeCompare(mapStatus(b));
            }
            return (a: IMapInfo, b: IMapInfo) =>
                mapStatus(b).localeCompare(mapStatus(a));
        }
        case 'colAuthor': {
            if (dir === 'asc') {
                return (a: IMapInfo, b: IMapInfo) =>
                    scopeOption()
                        .let(
                            'userA',
                            fromNullable(a.user).chain(uid => userName(uid))
                        )
                        .let(
                            'userB',
                            fromNullable(b.user).chain(uid => userName(uid))
                        )
                        .map(({ userA, userB }) => userA.localeCompare(userB))
                        .getOrElse(-1);
            }
            return (a: IMapInfo, b: IMapInfo) =>
                scopeOption()
                    .let(
                        'userA',
                        fromNullable(a.user).chain(uid => userName(uid))
                    )
                    .let(
                        'userB',
                        fromNullable(b.user).chain(uid => userName(uid))
                    )
                    .map(({ userA, userB }) => userB.localeCompare(userA))
                    .getOrElse(-1);
        }
    }
};

const getMapsTable = (maps: IMapInfo[], { filter, sort }: HomeConfigTable) =>
    getFilteredMaps(maps, filter).sort(sortFn(sort));

export const getMapList = (maps: IMapInfo[]) => {
    const config = getHomeConfig();
    switch (config.mode) {
        case 'tile':
            return getMapsTile(maps, config);
        case 'table':
            return getMapsTable(maps, config);
    }
};

export const getHomeSort = () => {
    const config = getHomeConfig();
    if (config.mode === 'table') {
        return some(config.sort);
    }
    return none;
};

export const getHomeSortDirection = () => getHomeSort().map(s => s[1]);
export const getHomeSortKey = () => getHomeSort().map(s => s[0]);

export const isSortedColumn = (colName: HomeTableKey) =>
    getHomeSortKey()
        .map(k => k === colName)
        .getOrElse(false);

logger('loaded');
