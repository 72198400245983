/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import { Option } from 'fp-ts/lib/Option';

import { getContext, IOLContext, lineStyle } from '../../map/style';
import { DIV, SPAN, IMG, NodeOrOptional } from '../elements';
import { fromRecord } from '../../locale';
import {
    Inspire,
    ILayerInfo,
    LineStyleConfig,
    LineStyleConfigSimple,
    LineStyleConfigDiscrete,
    LineStyleConfigContinuous,
} from '../../source';
import {
    applyResolutionStyle,
    OpacitySelector,
    renderSimpleItemLabel,
    renderOpacitySelector,
    defaultOpacitySelector,
} from '.';

const logger = debug('sdi:legend-linestring');

const lineGeometry = new LineString([
    [0, 15],
    [32, 15],
]);

const item = (geomType: string, dataUrl: string, label: string) => {
    return DIV(
        { className: `legend-item ${geomType}` },
        DIV({ className: 'item-style' }, IMG({ src: dataUrl, alt: '' })),
        DIV({ className: 'item-label' }, SPAN({}, label))
    );
};

const renderSimple = (
    config: LineStyleConfigSimple,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, olContext } = ctx;
    const styles = applyResolutionStyle(
        lineStyle(config),
        new Feature(lineGeometry)
    );
    styles.forEach(style => {
        olContext.setStyle(style);
        olContext.drawGeometry(lineGeometry);
    });
    const label = renderSimpleItemLabel(layerInfo, md);
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    return [
        DIV(
            `item ${opacityClass}`,
            item('line', canvas.toDataURL(), label),
            renderOpacitySelector(opacitySelector, layerInfo)
        ),
    ];
};

const renderDiscrete = (
    config: LineStyleConfigDiscrete,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = lineStyle(config);
    const items: NodeOrOptional[] = [];
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    config.groups.forEach(group => {
        if (group.values.length > 0) {
            canvasContext.clearRect(0, 0, 100, 100);
            const f = new Feature(lineGeometry);
            f.set(config.propName, group.values[0]);
            const styles = applyResolutionStyle(styleFn, f);
            styles.forEach(style => {
                olContext.drawFeature(f, style);
            });
            items.push(
                DIV(
                    `item ${opacityClass}`,
                    item('line', canvas.toDataURL(), fromRecord(group.label)),
                    renderOpacitySelector(opacitySelector, layerInfo)
                )
            );
        }
    });

    return items;
};

const renderContinuous = (
    config: LineStyleConfigContinuous,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    opacitySelector: OpacitySelector
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = lineStyle(config);
    const items: NodeOrOptional[] = [];
    const opacityClass = layerInfo.opacitySelector ? 'with-opacity' : '';

    config.intervals.forEach(interval => {
        canvasContext.clearRect(0, 0, 100, 100);
        const f = new Feature(lineGeometry);
        const v = interval.low + (interval.high - interval.low) / 2;
        f.set(config.propName, v);
        const styles = applyResolutionStyle(styleFn, f);
        styles.forEach(style => {
            olContext.drawFeature(f, style);
        });
        items.push(
            DIV(
                `item ${opacityClass}`,
                item('line', canvas.toDataURL(), fromRecord(interval.label)),
                renderOpacitySelector(opacitySelector, layerInfo)
            )
        );
    });

    return items;
};

const render = (
    config: LineStyleConfig,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    opacitySelector = defaultOpacitySelector
) => {
    const ctx = getContext(20, 32);
    if (ctx) {
        switch (config.kind) {
            case 'line-simple':
                return renderSimple(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
            case 'line-discrete':
                return renderDiscrete(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
            case 'line-continuous':
                return renderContinuous(
                    config,
                    layerInfo,
                    md,
                    ctx,
                    opacitySelector
                );
        }
    }

    return [];
};

export default render;

logger('loaded');
