import * as debug from 'debug';

import {
    DIV,
    H1,
    H2,
    NODISPLAY,
    H3,
    SUMMARY,
    DETAILS,
} from 'sdi/components/elements';
import tr, { fromRecord, formatDate } from 'sdi/locale';
import {
    getMessageRecord,
    FeatureCollection,
    IMapInfo,
    ILayerInfo,
} from 'sdi/source';

import {
    request,
    endHarvest,
    printHarvest,
    removeHarvest,
} from 'view/src/events/harvest';
import {
    getMapInfoOption,
    getDatasetMetadataOption,
} from 'view/src/queries/app';
import { makeLabelAndIcon, makeLabel } from '../button';
import {
    findHarvest,
    getGeometry,
    getHarvests,
} from 'view/src/queries/harvest';
import { renderResults } from './results';
import { withoutBookmarksOrHarvest } from '../../queries/map';
import { getLinks } from 'view/src/queries/map';
import minimap from './geometry';
import { scopeOption } from 'sdi/lib';
import { Group } from 'sdi/components/legend';
import { groupItems } from 'sdi/components/legend/index';
import { setLayout } from 'view/src/events/app';
import { AppLayout } from 'view/src/shape/types';

const logger = debug('sdi:query');

export interface HarvestedLayerInitial {
    tag: 'initial';
    mapId: string;
    metadataId: string;
    id: number;
    groupId: number;
}

export interface HarvestedLayerLoading {
    tag: 'loading';
    start: number;
    mapId: string;
    metadataId: string;
    id: number;
    groupId: number;
}

export interface HarvestedLayerLoaded {
    tag: 'loaded';
    start: number;
    end: number;
    mapId: string;
    metadataId: string;
    data: FeatureCollection;
    id: number;
    groupId: number;
}

export interface HarvestedLayerError {
    tag: 'error';
    start: number;
    end: number;
    mapId: string;
    metadataId: string;
    error: string;
    id: number;
    groupId: number;
}

export type HarvestedLayer =
    | HarvestedLayerInitial
    | HarvestedLayerLoading
    | HarvestedLayerError
    | HarvestedLayerLoaded;

const btnCancel = makeLabelAndIcon('close', 2, 'times', () =>
    tr.core('cancel')
);
const btnPrint = makeLabelAndIcon('confirm', 1, 'print', () =>
    tr.core('print')
);
const btnSelectAll = makeLabel('select', 2, () => tr.view('querySelectAll'));
const btnDeselectAll = makeLabel('select', 2, () =>
    tr.view('queryDeselectAll')
);

const queryToolTitle = () => H1({}, tr.view('queryTitle'));

const actions = () =>
    DIV(
        { className: 'query-actions' },
        btnCancel(() => {
            endHarvest();
            setLayout(AppLayout.MapAndInfo);
        }),
        btnPrint(printHarvest)
    );

const helptxtSelect = () =>
    DIV({ className: 'helptext' }, tr.view('queryHelpText2'));

const renderTitle = (t: string) => DIV({ className: 'map-card__title' }, t);

const renderUpdated = (u: string) =>
    DIV({ className: 'map-card__updated' }, tr.view('lastModified') + u);

const foldSelected =
    <T>(no: () => T, yes: () => T) =>
    (mapId: string, mid: string) =>
        findHarvest(mapId, mid).foldL(no, yes);

const classSelected = foldSelected(
    () => 'map-card__layer unselected',
    () => 'map-card__layer selected'
);

// render layer list (to select tables to show)
const renderLayer = (
    mapId: string,
    l: ILayerInfo,
    id: number,
    groupId: number
) => {
    const key = `query-select-layer-${l.id}`;
    const className = classSelected(mapId, l.metadataId);
    const onClick = foldSelected(
        () => () => {
            logger(`request ${l.metadataId}`);
            request(mapId, l.metadataId, id, groupId);
        },
        () => () => {
            logger(`removeHarvest ${l.metadataId}`);
            removeHarvest(l.metadataId);
        }
    )(mapId, l.metadataId);
    const name = getDatasetMetadataOption(l.metadataId).fold(l.id, m =>
        fromRecord(getMessageRecord(m.resourceTitle))
    );

    return DIV({ key, onClick, className }, name);
};

const renderGroups = (mapId: string, groups: Group[]) =>
    groups.map((group, groupId) => {
        const items = group.layers.map((layer, id) =>
            renderLayer(mapId, layer, id, groupId + 1)
        );
        if (group.g !== null) {
            return DIV(
                { className: 'query-group named' },
                DIV(
                    { className: 'query-group-title' },
                    fromRecord(group.g.name)
                ),
                DIV({ className: 'query-group-items' }, items)
            );
        }
        return DIV({ className: 'query-group anonymous' }, items);
    });

const renderMapInfo = (info: IMapInfo, gid: number) => {
    const key = `select-map-info-${info.id}`;
    const title = renderTitle(fromRecord(info.title));
    const updated = renderUpdated(formatDate(new Date(info.lastModified)));
    const selectAll = btnSelectAll(() =>
        withoutBookmarksOrHarvest(info.layers).map((l, id) =>
            request(info.id, l.metadataId, id, gid)
        )
    );
    const deselectAll = btnDeselectAll(() =>
        withoutBookmarksOrHarvest(info.layers).map(l =>
            removeHarvest(l.metadataId)
        )
    );
    const layers = renderGroups(
        info.id,
        groupItems(withoutBookmarksOrHarvest(info.layers))
    );
    return DIV(
        { className: 'map-card map-card--select', key },
        title,
        updated,
        selectAll,
        deselectAll,
        layers
    );
};

const renderCurrentMap = () =>
    getMapInfoOption().map(mInfo => renderMapInfo(mInfo, 0));

const renderLinkedMaps = () =>
    scopeOption()
        .let('forward', getLinks('forward'))
        .let('backward', getLinks('backward'))
        .map(({ forward, backward }) =>
            DIV(
                {},
                forward
                    .concat(backward)
                    .map((mInfo, gid) => renderMapInfo(mInfo, gid + 1)) // +1 because first group is current layer
            )
        );

const layerSelector = () =>
    DIV(
        { className: 'layer-selector' },
        H2({}, tr.view('queryiedData')),
        helptxtSelect(),
        H3({}, tr.view('currentMap')),
        renderCurrentMap(),
        DETAILS(
            { className: 'legend-block-tools' },
            SUMMARY(
                '',
                H3({ className: 'wrapper-head' }, tr.view('relatedMapsLabel'))
            ),
            renderLinkedMaps()
        )
    );

const renderLayerFromHarvest = (h: HarvestedLayer) => {
    const key = `renderLayerFromHarvest-${h.metadataId}`;
    const name = getDatasetMetadataOption(h.metadataId).fold(h.metadataId, m =>
        fromRecord(getMessageRecord(m.resourceTitle))
    );
    switch (h.tag) {
        case 'loaded':
            return DIV({ key, className: 'selected-layer--print' }, name);
        default:
            return NODISPLAY({ key });
    }
};

const layerSelectorPrint = () =>
    DIV(
        { className: 'layer-selector' },
        H2({}, tr.view('queryiedData')),
        getHarvests().map(renderLayerFromHarvest)
    );

const resultMapIllu = () =>
    DIV(
        { className: 'result__map-illu-wrapper' },
        H2({}, tr.view('queryZone')),
        DIV({ className: 'result__map-illu' }, getGeometry().map(minimap))
    );

export const render = (print = false) => {
    if (print) {
        return DIV(
            {},
            queryToolTitle(),
            DIV(
                { className: 'selector-wrapper' },
                resultMapIllu(),
                layerSelectorPrint()
            ),
            H2({}, tr.view('harvestResults')),
            renderResults()
        );
    }

    return DIV(
        {},
        queryToolTitle(),
        actions(),
        DIV(
            { className: 'selector-wrapper' },
            resultMapIllu(),
            layerSelector()
        ),
        H2({}, tr.view('harvestResults')),
        renderResults()
    );
};

export default render;

logger('loaded');
